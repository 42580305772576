<template>
  <div id="app"> 
    <div id="main" class="p-2 mb-2">
      <div id="time" class="row">
        <span id="hours">{{hours}}</span><span id="min">{{minutes}}</span><span id="sec">{{sec}}</span> <span id="ampm">{{ampm}}</span>
      </div>
      <div id='days' class="row">
        <div class="days" v-for="(day, index) in days" v-bind:class="{active:day.active}" :key="index">{{day.text}}</div>
      </div>
      <div id="fullDate" class="row">
        <span id="month" class="mr-1">{{month}}</span>&nbsp;<span id="date" class="mr-1">{{date}},</span>&nbsp;<span id="year">{{year}}</span>
      </div>
    </div> 
    <CRow>
        <CCol>
          <CCard>
            <CCardHeader>
              <CIcon name="cil-justify-center"/>
              <strong>Biometric</strong>
              <div class="card-header-actions">
                <a 
                  href="#" 
                  class="card-header-action" 
                  rel="noreferrer noopener" 
                  target="_blank"
                >
                  <small class="text-muted">Biometric</small>
                </a>
              </div>
            </CCardHeader>
            <CCardBody>
                <div class="row">
                    <div class="col-md-6">
                      <form @submit.prevent="biometricCheckInOut">
                          <div class="row">
                            <div class="col-md-9">
                                  <!-- COMPONENT START -->
                                <div class="custom-file">
                                  <select class="custom-select" v-model="inOutStatus" >
                                    <option 
                                    :value="timeS.value"
                                    v-for="(timeS, index) in timeStatus"
                                    :key="index"
                                    >{{timeS.text}}</option>
                                  </select>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <!-- COMPONENT END -->
                                <div v-if="inOutStatus == 'Select Status'">
                                  <button type="submit" class="btn btn-primary" disabled>{{inOutStatus}}</button>
                                </div>
                                <div v-else>
                                    <button v-if="!loading" type="submit" class="btn btn-primary">{{inOutStatus}}</button>
                                    <button v-else class="btn btn-primary" type="button" disabled>
                                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                      Submitting...
                                    </button>
                                </div>
                            </div>
                          </div>
                      </form>
                    </div>
                    <div class="col-md-6">
                        <div class="row">
                              <div class="col-md-10">
                                  <div class="row">
                                    <div class="col-md-5">
                                      <div class="row">
                                          <div class="col-md-3">
                                              <span class="mt-4">From: </span>
                                          </div>
                                          <div class="col-md-9">
                                              <input class="form-control" v-model="from" type="date" />
                                          </div>
                                      </div>
                                    </div>
                                    <div class="col-md-5">
                                      <div class="row">
                                          <div class="col-md-3">
                                              <span class="mt-4">To: </span>
                                          </div>
                                          <div class="col-md-9">
                                              <input class="form-control" v-model="to" type="date" />
                                          </div>
                                      </div>
                                    </div>
                                    <div class="col-md-2">
                                      <div class="col-auto">
                                          <button class="ml--4 btn btn-primary" @click="searchLogs(from, to)">Search</button>
                                      </div>
                                    </div>
                                  </div>
                              </div>
                              <div class="col-md-2">
                                <div class="col-auto">
                                    <button class="mr-auto btn btn-success" @click="printForm">Print</button>
                                </div>
                              </div>
                        </div>
                    </div>
                </div>

        
                    <div class="mt-4">
                      <div id="dtr">
                        <div class="text-right">
                            <h6>Ref. No. {{reference()}}</h6>
                        </div>
                          <div class="text-center">
                            <h6 style="font-style: italic">Civil Service Form No. 48</h6>
                            <h4>DAILY TIME RECORD</h4>
                            <h4><span style="border-bottom: 1px solid">{{fullname}}</span></h4>
                            <p style="font-size: 18px">(Name)</p>
                          </div>
                          <div class="d-flex justify-content-center">
                            <div class="text-left">
                              <p style="font-size: 18px">For the Month of <span style="border-bottom: 1px solid">{{getDate()}}</span></p>
                              <p style="font-size: 18px; margin-top: -15px">Official hours for arrival  <span style="margin-left: 100px">Regular days __________</span></p>
                              <p style="font-size: 18px; margin-top: -15px">and departure  <span style="margin-left: 173px">Saturdays __________</span></p>
                            </div>
                          </div>
                          <div>
                            <div class="row">
                              <div class="col-md-1"></div>
                              <div class="col-md-1" style="border: 1px solid;border-bottom:none">
                                Day
                              </div>
                              <div class="col-md-3 text-center" style="border: 1px solid; border-left: none">
                                A.M.
                              </div>
                              <div class="col-md-3 text-center" style="border: 1px solid; border-left:none">
                                P.M.
                              </div>
                              <div class="col-md-3 text-center" style="border: 1px solid; border-left:none">
                                Undertime
                              </div>
                              <div class="col-md-1"></div>
                            </div>
                            <div class="row">
                              <div class="col-md-1"></div>
                              <div class="col-md-1" style="border: 1px solid;border-top:none">
                              </div>
                              <div class="col-md-3 text-center" style="border: 1px solid; border-left: none; border-top:none">
                                <div class="row">
                                    <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">Arrival</div>
                                    <div class="col-md-6">Departure</div>
                                </div>
                              </div>
                              <div class="col-md-3 text-center" style="border: 1px solid; border-left:none; border-top: none">
                                  <div class="row">
                                    <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">Arrival</div>
                                    <div class="col-md-6">Departure</div>
                                </div>
                              </div>
                              <div class="col-md-3 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                  <div class="row">
                                    <div class="col-md-12">Hours | Minutes | Seconds</div>
                                </div>
                              </div>
                              <div class="col-md-1"></div>
                              </div>
                              <!--1-->
                              <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none">
                                  1
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left: none; border-top:none">
                                  <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{getCheckIN(years + '-' + months + '-' + '01')}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUT(years + '-' + months + '-' + '01'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left:none; border-top: none">
                                    <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{
                                        tConvert(getCheckInArrival(years + '-' + months + '-' + '01'))}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUTHome(years + '-' + months + '-' + '01'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12">{{convertToWords(checkUnderTime1(years + '-' + months + '-' + '01'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <!--2-->
                              <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none">
                                  2
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left: none; border-top:none">
                                  <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{getCheckIN(years + '-' + months + '-' + '02')}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUT(years + '-' + months + '-' + '02'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left:none; border-top: none">
                                    <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{tConvert(getCheckInArrival(years + '-' + months + '-' + '02'))}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUTHome(years + '-' + months + '-' + '02'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12">{{convertToWords(checkUnderTime2(years + '-' + months + '-' + '02'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <!--3-->
                              <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none">
                                  3
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left: none; border-top:none">
                                  <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{getCheckIN(years + '-' + months + '-' + '03')}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUT(years + '-' + months + '-' + '03'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left:none; border-top: none">
                                    <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{tConvert(getCheckInArrival(years + '-' + months + '-' + '03'))}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUTHome(years + '-' + months + '-' + '03'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12">{{convertToWords(checkUnderTime3(years + '-' + months + '-' + '03'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <!--4-->
                              <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none">
                                  4
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left: none; border-top:none">
                                  <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{getCheckIN(years + '-' + months + '-' + '04')}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUT(years + '-' + months + '-' + '04'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left:none; border-top: none">
                                    <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{tConvert(getCheckInArrival(years + '-' + months + '-' + '04'))}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUTHome(years + '-' + months + '-' + '04'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12">{{convertToWords(checkUnderTime4(years + '-' + months + '-' + '04'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <!--5-->
                              <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none">
                                  5
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left: none; border-top:none">
                                  <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{getCheckIN(years + '-' + months + '-' + '05')}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUT(years + '-' + months + '-' + '05'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left:none; border-top: none">
                                    <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{tConvert(getCheckInArrival(years + '-' + months + '-' + '05'))}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUTHome(years + '-' + months + '-' + '05'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12">{{convertToWords(checkUnderTime5(years + '-' + months + '-' + '05'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <!--6-->
                              <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none">
                                  6
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left: none; border-top:none">
                                  <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{getCheckIN(years + '-' + months + '-' + '06')}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUT(years + '-' + months + '-' + '06'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left:none; border-top: none">
                                    <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{tConvert(getCheckInArrival(years + '-' + months + '-' + '06'))}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUTHome(years + '-' + months + '-' + '06'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12">{{convertToWords(checkUnderTime6(years + '-' + months + '-' + '06'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <!--7-->
                              <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none">
                                  7
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left: none; border-top:none">
                                  <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{getCheckIN(years + '-' + months + '-' + '07')}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUT(years + '-' + months + '-' + '07'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left:none; border-top: none">
                                    <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{tConvert(getCheckInArrival(years + '-' + months + '-' + '07'))}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUTHome(years + '-' + months + '-' + '07'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12">{{convertToWords(checkUnderTime7(years + '-' + months + '-' + '07'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <!--8-->
                              <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none">
                                  8
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left: none; border-top:none">
                                  <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{getCheckIN(years + '-' + months + '-' + '08')}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUT(years + '-' + months + '-' + '08'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left:none; border-top: none">
                                    <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{tConvert(getCheckInArrival(years + '-' + months + '-' + '08'))}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUTHome(years + '-' + months + '-' + '08'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12">{{convertToWords(checkUnderTime8(years + '-' + months + '-' + '08'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <!--9-->
                              <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none">
                                  9
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left: none; border-top:none">
                                  <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{getCheckIN(years + '-' + months + '-' + '09')}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUT(years + '-' + months + '-' + '09'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left:none; border-top: none">
                                    <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{tConvert(getCheckInArrival(years + '-' + months + '-' + '09'))}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUTHome(years + '-' + months + '-' + '09'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12">{{convertToWords(checkUnderTime9(years + '-' + months + '-' + '09'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <!--10-->
                              <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none">
                                  10
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left: none; border-top:none">
                                  <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{getCheckIN(years + '-' + months + '-' + '10')}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUT(years + '-' + months + '-' + '10'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left:none; border-top: none">
                                    <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{tConvert(getCheckInArrival(years + '-' + months + '-' + '10'))}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUTHome(years + '-' + months + '-' + '10'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12">{{convertToWords(checkUnderTime10(years + '-' + months + '-' + '10'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <!--11-->
                              <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none">
                                  11
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left: none; border-top:none">
                                  <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{getCheckIN(years + '-' + months + '-' + '11')}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUT(years + '-' + months + '-' + '11'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left:none; border-top: none">
                                    <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{tConvert(getCheckInArrival(years + '-' + months + '-' + '11'))}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUTHome(years + '-' + months + '-' + '11'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12">{{convertToWords(checkUnderTime11(years + '-' + months + '-' + '11'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <!--12-->
                              <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none">
                                  12
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left: none; border-top:none">
                                  <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{getCheckIN(years + '-' + months + '-' + '12')}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUT(years + '-' + months + '-' + '12'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left:none; border-top: none">
                                    <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{tConvert(getCheckInArrival(years + '-' + months + '-' + '12'))}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUTHome(years + '-' + months + '-' + '12'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12">{{convertToWords(checkUnderTime12(years + '-' + months + '-' + '12'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <!--13-->
                              <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none">
                                  13
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left: none; border-top:none">
                                  <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{getCheckIN(years + '-' + months + '-' + '13')}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUT(years + '-' + months + '-' + '13'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left:none; border-top: none">
                                    <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{tConvert(getCheckInArrival(years + '-' + months + '-' + '13'))}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUTHome(years + '-' + months + '-' + '13'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12">{{convertToWords(checkUnderTime13(years + '-' + months + '-' + '13'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <!--14-->
                              <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none">
                                  14
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left: none; border-top:none">
                                  <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{getCheckIN(years + '-' + months + '-' + '14')}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUT(years + '-' + months + '-' + '14'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left:none; border-top: none">
                                    <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{tConvert(getCheckInArrival(years + '-' + months + '-' + '14'))}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUTHome(years + '-' + months + '-' + '14'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12">{{convertToWords(checkUnderTime14(years + '-' + months + '-' + '14'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <!--15-->
                              <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none">
                                  15
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left: none; border-top:none">
                                  <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{getCheckIN(years + '-' + months + '-' + '15')}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUT(years + '-' + months + '-' + '15'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left:none; border-top: none">
                                    <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{tConvert(getCheckInArrival(years + '-' + months + '-' + '15'))}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUTHome(years + '-' + months + '-' + '15'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                      <div class="col-md-12">{{convertToWords(checkUnderTime15(years + '-' + months + '-' + '15'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <!--16-->
                              <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none">
                                  16
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left: none; border-top:none">
                                  <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{getCheckIN(years + '-' + months + '-' + '16')}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUT(years + '-' + months + '-' + '16'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left:none; border-top: none">
                                    <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{tConvert(getCheckInArrival(years + '-' + months + '-' + '16'))}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUTHome(years + '-' + months + '-' + '16'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12">{{convertToWords(checkUnderTime16(years + '-' + months + '-' + '16'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <!--17-->
                              <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none">
                                  17
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left: none; border-top:none">
                                  <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{getCheckIN(years + '-' + months + '-' + '17')}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUT(years + '-' + months + '-' + '17'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left:none; border-top: none">
                                    <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{tConvert(getCheckInArrival(years + '-' + months + '-' + '17'))}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUTHome(years + '-' + months + '-' + '17'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12">{{convertToWords(checkUnderTime17(years + '-' + months + '-' + '17'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <!--18-->
                              <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none">
                                  18
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left: none; border-top:none">
                                  <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{getCheckIN(years + '-' + months + '-' + '18')}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUT(years + '-' + months + '-' + '18'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left:none; border-top: none">
                                    <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{tConvert(getCheckInArrival(years + '-' + months + '-' + '18'))}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUTHome(years + '-' + months + '-' + '18'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12">{{convertToWords(checkUnderTime18(years + '-' + months + '-' + '18'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <!--19-->
                              <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none">
                                  19
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left: none; border-top:none">
                                  <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{getCheckIN(years + '-' + months + '-' + '19')}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUT(years + '-' + months + '-' + '19'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left:none; border-top: none">
                                    <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{tConvert(getCheckInArrival(years + '-' + months + '-' + '19'))}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUTHome(years + '-' + months + '-' + '19'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12">{{convertToWords(checkUnderTime19(years + '-' + months + '-' + '19'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <!--20-->
                              <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none">
                                  20
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left: none; border-top:none">
                                  <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{getCheckIN(years + '-' + months + '-' + '20')}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUT(years + '-' + months + '-' + '20'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left:none; border-top: none">
                                    <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{tConvert(getCheckInArrival(years + '-' + months + '-' + '20'))}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUTHome(years + '-' + months + '-' + '20'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12">{{convertToWords(checkUnderTime20(years + '-' + months + '-' + '20'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <!--21-->
                              <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none">
                                  21
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left: none; border-top:none">
                                  <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{getCheckIN(years + '-' + months + '-' + '21')}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUT(years + '-' + months + '-' + '21'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left:none; border-top: none">
                                    <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{tConvert(getCheckInArrival(years + '-' + months + '-' + '21'))}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUTHome(years + '-' + months + '-' + '21'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12">{{convertToWords(checkUnderTime21(years + '-' + months + '-' + '21'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <!--22-->
                              <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none">
                                  22
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left: none; border-top:none">
                                  <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{getCheckIN(years + '-' + months + '-' + '22')}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUT(years + '-' + months + '-' + '22'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left:none; border-top: none">
                                    <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{tConvert(getCheckInArrival(years + '-' + months + '-' + '22'))}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUTHome(years + '-' + months + '-' + '22'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12">{{convertToWords(checkUnderTime22(years + '-' + months + '-' + '22'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <!--23-->
                              <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none">
                                  23
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left: none; border-top:none">
                                  <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{getCheckIN(years + '-' + months + '-' + '23')}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUT(years + '-' + months + '-' + '23'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left:none; border-top: none">
                                    <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{tConvert(getCheckInArrival(years + '-' + months + '-' + '23'))}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUTHome(years + '-' + months + '-' + '23'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12">{{convertToWords(checkUnderTime23(years + '-' + months + '-' + '23'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <!--24-->
                              <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none">
                                  24
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left: none; border-top:none">
                                  <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{getCheckIN(years + '-' + months + '-' + '24')}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUT(years + '-' + months + '-' + '24'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left:none; border-top: none">
                                    <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{tConvert(getCheckInArrival(years + '-' + months + '-' + '24'))}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUTHome(years + '-' + months + '-' + '24'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12">{{convertToWords(checkUnderTime24(years + '-' + months + '-' + '24'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <!--25-->
                              <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none">
                                  25
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left: none; border-top:none">
                                  <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{getCheckIN(years + '-' + months + '-' + '25')}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUT(years + '-' + months + '-' + '25'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left:none; border-top: none">
                                    <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{tConvert(getCheckInArrival(years + '-' + months + '-' + '25'))}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUTHome(years + '-' + months + '-' + '25'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12">{{convertToWords(checkUnderTime25(years + '-' + months + '-' + '25'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <!--26-->
                              <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none">
                                  26
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left: none; border-top:none">
                                  <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{getCheckIN(years + '-' + months + '-' + '26')}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUT(years + '-' + months + '-' + '26'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left:none; border-top: none">
                                    <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{tConvert(getCheckInArrival(years + '-' + months + '-' + '26'))}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUTHome(years + '-' + months + '-' + '26'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12">{{convertToWords(checkUnderTime26(years + '-' + months + '-' + '26'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <!--27-->
                              <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none">
                                  27
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left: none; border-top:none">
                                  <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{getCheckIN(years + '-' + months + '-' + '27')}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUT(years + '-' + months + '-' + '27'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left:none; border-top: none">
                                    <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{tConvert(getCheckInArrival(years + '-' + months + '-' + '27'))}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUTHome(years + '-' + months + '-' + '27'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12">{{convertToWords(checkUnderTime27(years + '-' + months + '-' + '27'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <!--28-->
                              <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none">
                                  28
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left: none; border-top:none">
                                  <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{getCheckIN(years + '-' + months + '-' + '28')}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUT(years + '-' + months + '-' + '28'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left:none; border-top: none">
                                    <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{tConvert(getCheckInArrival(years + '-' + months + '-' + '28'))}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUTHome(years + '-' + months + '-' + '28'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12">{{convertToWords(checkUnderTime28(years + '-' + months + '-' + '28'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <!--29-->
                              <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none">
                                  29
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left: none; border-top:none">
                                  <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{getCheckIN(years + '-' + months + '-' + '29')}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUT(years + '-' + months + '-' + '29'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left:none; border-top: none">
                                    <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{tConvert(getCheckInArrival(years + '-' + months + '-' + '29'))}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUTHome(years + '-' + months + '-' + '29'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12">{{convertToWords(checkUnderTime29(years + '-' + months + '-' + '29'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <!--30-->
                              <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none">
                                  30
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left: none; border-top:none">
                                  <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{getCheckIN(years + '-' + months + '-' + '30')}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUT(years + '-' + months + '-' + '30'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left:none; border-top: none">
                                    <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{tConvert(getCheckInArrival(years + '-' + months + '-' + '30'))}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUTHome(years + '-' + months + '-' + '30'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12">{{convertToWords(checkUnderTime30(years + '-' + months + '-' + '30'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <!--31-->
                              <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-1" style="border: 1px solid;border-top:none">
                                  31
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left: none; border-top:none">
                                  <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{getCheckIN(years + '-' + months + '-' + '31')}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUT(years + '-' + months + '-' + '31'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left:none; border-top: none">
                                    <div class="row">
                                      <div class="col-md-6" style="border: 1px solid; border-left: none;border-bottom:none;border-top:none">{{tConvert(getCheckInArrival(years + '-' + months + '-' + '31'))}}</div>
                                      <div class="col-md-6">{{tConvert(getCheckOUTHome(years + '-' + months + '-' + '31'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-3 text-center" style="border: 1px solid; border-left:none;border-top:none">
                                    <div class="row">
                                      <div class="col-md-12">{{convertToWords(checkUnderTime31(years + '-' + months + '-' + '31'))}}</div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <!--total-->
                              <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-1">
                                </div>
                                <div class="col-md-3 text-center">
                                  <div class="row">
                                      <div class="col-md-6"></div>
                                      <div class="col-md-6"></div>
                                  </div>
                                </div>
                                <div class="col-md-3 text-center">
                                    <div class="row">
                                      <div class="col-md-6"></div>
                                      <div class="col-md-6"></div>
                                  </div>
                                </div>
                                <div class="col-md-3 text-right">
                                    <div class="row">
                                      <div class="col-md-12"><span style="font-weight:bold">Total:</span> <span class="text-danger">{{ convertToWords(totalUndertime()) }}</span></div>
                                  </div>
                                </div>
                                <div class="col-md-1"></div>
                              </div>
                              <div class="d-flex justify-content-center mt-2">
                                <p class="text-left mt-2" style="max-width: 330px">I CERTIFY on my honor that the above is a true and correct report of the hours of work performed record of which was made daily at the time of arrival at departure from office.</p>             
                              </div>
                              <div class="d-flex justify-content-center mt-2">
                                <p style="margin-left:-250px">Prepared by: </p> 
                              </div>
                              <div class="d-flex justify-content-center mt-2">
                                <p style="font-weight: bold; border-bottom: 1px solid">{{fullname}}</p>
                              </div>
                              <div class="d-flex justify-content-center" style="margin-top: -10px">
                                <p>Printed Name & Signature</p>
                              </div>
                              <div class="d-flex justify-content-center mt-2">
                                <p style="margin-left:-70px">VERIFIED as to the prescribed office hours </p>
                              </div>
                              <div class="d-flex justify-content-center mt-2">
                                <p style="font-weight: bold; border-bottom: 1px solid" v-if="this.getUser.user.personnel_id == 14 || this.getUser.user.personnel_id == 30 || this.getUser.user.personnel_id == 38">AMY C. SAAVEDRA, MSCA</p>
                                <p style="font-weight: bold; border-bottom: 1px solid" v-if="this.getUser.user.personnel_id == 15 || this.getUser.user.personnel_id == 16 || this.getUser.user.personnel_id == 1 || this.getUser.user.personnel_id == 13 || this.getUser.user.personnel_id == 52">LEONIDA S. CALAGUI, PhD, CESO III</p>
                                <p style="font-weight: bold; border-bottom: 1px solid" v-if="this.getUser.user.personnel_id == 19 || this.getUser.user.personnel_id == 31 || this.getUser.user.personnel_id == 32 || this.getUser.user.personnel_id == 34 || this.getUser.user.personnel_id == 36 || this.getUser.user.personnel_id == 37">JULIA FELISA C. MARTINEZ, LPT, PhD</p>
                                <p style="font-weight: bold; border-bottom: 1px solid" v-if="this.getUser.user.personnel_id == 17 || this.getUser.user.personnel_id == 21 || this.getUser.user.personnel_id == 27 || this.getUser.user.personnel_id == 39 || this.getUser.user.personnel_id == 40 || this.getUser.user.personnel_id == 41 || this.getUser.user.personnel_id == 47 || this.getUser.user.personnel_id == 49">ENGR. CRISELDO C. CALINAWAN, PhD </p>
                                <p style="font-weight: bold; border-bottom: 1px solid" v-if="this.getUser.user.personnel_id == 44">HAEMACCEL I. PALIMA</p>
                                <p style="font-weight: bold; border-bottom: 1px solid" v-if="this.getUser.user.personnel_id == 6 || this.getUser.user.personnel_id == 7 || this.getUser.user.personnel_id == 9 || this.getUser.user.personnel_id == 18 || this.getUser.user.personnel_id == 50">GESSELE M. FRANCISCO, CPA</p>
                                <p style="font-weight: bold; border-bottom: 1px solid" v-if="this.getUser.user.personnel_id == 22">ANGEL A. ASIS</p>
                                <p style="font-weight: bold; border-bottom: 1px solid" v-if="this.getUser.user.personnel_id == 8 || this.getUser.user.personnel_id == 51 || this.getUser.user.personnel_id == 2 || this.getUser.user.personnel_id == 3 || this.getUser.user.personnel_id == 4 || this.getUser.user.personnel_id == 5 || this.getUser.user.personnel_id == 26 || this.getUser.user.personnel_id == 28">ALMA S. PATRON, PhD, RSW</p>
                                <p style="font-weight: bold; border-bottom: 1px solid" v-if="this.getUser.user.personnel_id == 11 || this.getUser.user.personnel_id == 42 || this.getUser.user.personnel_id == 43 || this.getUser.user.personnel_id == 46 || this.getUser.user.personnel_id == 48">REVELYN P. BRINA, MAED</p>
                                <p style="font-weight: bold; border-bottom: 1px solid" v-if="this.getUser.user.personnel_id == 23">MISAEL S. CABANG</p>
                              </div>
                              <div class="d-flex justify-content-center" style="margin-top: -10px">
                                <p>Printed Name & Signature</p>
                              </div>
                      </div>
                    </div>
            </CCardBody>
          </CCard>
        </CCol>
    </CRow>
  </div>
  
</template>
<style lang="scss" scoped>
$glow:0px 2px 25px rgba(144, 244, 253, .6);
$paleturquoise:#afeeee;
$backgroundDark: rgba(8, 29, 66, 0.52);
$grey:#444444;

@import url(https://fonts.googleapis.com/css?family=Orbitron:700,900,500,400);
html,
body {
  width: 100%;
  height: 100%
}

#main {
  margin: 0;
  padding: 0;
  font-family: 'Orbitron', sans-serif;
  font-weight: 700;
  background: $backgroundDark;
  color: $paleturquoise;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

#app {
  width: 100%;
  height: auto;
  #time {
    width: 40%;
    margin: 0 auto;
    text-align: center;
    font-size: 2.5em;
    text-shadow: $glow;
    #ampm {
      font-size: .5em;
    }
  }
  #days, #fullDate {
    width: 25%;
    margin: 0 auto;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  .days {
    flex: 1;
    color: $grey;
    text-align: center;
  }

  .active {
    color: paleturquoise;
    text-shadow: $glow;
  }
  #fullDate {
    margin-top:.25em;
    text-shadow: $glow;
  }
  #sec{
    display:inline-block;
    width:70px;
  }
}
</style>
<script>
import { mapGetters, mapMutations } from 'vuex';
// Import VueHTMLPaper
import VueHtmlToPaper from 'vue-html-to-paper';
import Vue from 'vue'

const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
  ]
}

var tt="", tt_flexi="", tt_flexi_kuyaDon="", d1="",d2="",d3="",d4="",d5="",d6="",d7="",d8="",d9="",d10="",d11="",d12="",d13="",d14="",d15="",
d16="",d17="",d18="",d19="",d20="",d21="",d22="",d23="",d24="",d25="",d26="",d27="",d28="",d29="",d30="",d31="", 
d1_flexi="",d2_flexi="",d3_flexi="",d4_flexi="",d5_flexi="",d6_flexi="",d7_flexi="",d8_flexi="",d9_flexi="",d10_flexi="",d11_flexi="",d12_flexi="",d13_flexi="",d14_flexi="",d15_flexi="",
d16_flexi="",d17_flexi="",d18_flexi="",d19_flexi="",d20_flexi="",d21_flexi="",d22_flexi="",d23_flexi="",d24_flexi="",d25_flexi="",d26_flexi="",d27_flexi="",d28_flexi="",d29_flexi="",d30_flexi="",d31_flexi="",
d1_flexi_kuyaDon="",d2_flexi_kuyaDon="",d3_flexi_kuyaDon="",d4_flexi_kuyaDon="",d5_flexi_kuyaDon="",d6_flexi_kuyaDon="",d7_flexi_kuyaDon="",d8_flexi_kuyaDon="",d9_flexi_kuyaDon="",d10_flexi_kuyaDon="",d11_flexi_kuyaDon="",d12_flexi_kuyaDon="",d13_flexi_kuyaDon="",d14_flexi_kuyaDon="",d15_flexi_kuyaDon="",
d16_flexi_kuyaDon="",d17_flexi_kuyaDon="",d18_flexi_kuyaDon="",d19_flexi_kuyaDon="",d20_flexi_kuyaDon="",d21_flexi_kuyaDon="",d22_flexi_kuyaDon="",d23_flexi_kuyaDon="",d24_flexi_kuyaDon="",d25_flexi_kuyaDon="",d26_flexi_kuyaDon="",d27_flexi_kuyaDon="",d28_flexi_kuyaDon="",d29_flexi_kuyaDon="",d30_flexi_kuyaDon="",d31_flexi_kuyaDon="";

 
Vue.use(VueHtmlToPaper, options);
export default {
  data() {
    return {
      days: [
        {text:"SUN", active: false},
        {text:"MON", active: false},
        {text:"TUE", active: false},
        {text:"WED", active: false},
        {text:"THU", active: false},
        {text:"FRI", active: false},
        {text:"SAT", active: false}
      ],
      monthss: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      ampm:'',
      hours: '',
      minutes:'',
      sec: '' ,
      month:'',
      date: '',
      year: '',
      inOutStatus: 'Select Status',
      timeStatus: [
        {
          text: 'Open this select status',
          value: 'Select Status'
        },
        {
          text: 'Check-In',
          value: 'Check-In'
        },
        {
          text: 'Check-Out',
          value: 'Check-Out'
        }
      ],
      loading: false,
      from: '',
      to: '',
      individual_logs: [],
      months: "",
      years: "",      
      fullname: "",
    }
  },
  computed: {
    ...mapGetters(['getUser']),
  },
  mounted() {
    this.getUserEmp();
  },
  methods: {
    reset() {
       tt = "",d1="",d2="",d3="",d4="",d5="",d6="",d7="",d8="",d9="",d10="",d11="",d12="",d13="",d14="",d15="",
        d16="",d17="",d18="",d19="",d20="",d21="",d22="",d23="",d24="",d25="",d26="",d27="",d28="",d29="",d30="",d31="";
    },
    reset_flex() {
        tt_flexi = "",d1_flexi="",d2_flexi="",d3_flexi="",d4_flexi="",d5_flexi="",d6_flexi="",d7_flexi="",d8_flexi="",d9_flexi="",d10_flexi="",d11_flexi="",d12_flexi="",d13_flexi="",d14_flexi="",d15_flexi="",
        d16_flexi="",d17_flexi="",d18_flexi="",d19_flexi="",d20_flexi="",d21_flexi="",d22_flexi="",d23_flexi="",d24_flexi="",d25_flexi="",d26_flexi="",d27_flexi="",d28_flexi="",d29_flexi="",d30_flexi="",d31_flexi="",
        d1_flexi_kuyaDon="",d2_flexi_kuyaDon="",d3_flexi_kuyaDon="",d4_flexi_kuyaDon="",d5_flexi_kuyaDon="",d6_flexi_kuyaDon="",d7_flexi_kuyaDon="",d8_flexi_kuyaDon="",d9_flexi_kuyaDon="",d10_flexi_kuyaDon="",d11_flexi_kuyaDon="",d12_flexi_kuyaDon="",d13_flexi_kuyaDon="",d14_flexi_kuyaDon="",d15_flexi_kuyaDon="",
        d16_flexi_kuyaDon="",d17_flexi_kuyaDon="",d18_flexi_kuyaDon="",d19_flexi_kuyaDon="",d20_flexi_kuyaDon="",d21_flexi_kuyaDon="",d22_flexi_kuyaDon="",d23_flexi_kuyaDon="",d24_flexi_kuyaDon="",d25_flexi_kuyaDon="",d26_flexi_kuyaDon="",d27_flexi_kuyaDon="",d28_flexi_kuyaDon="",d29_flexi_kuyaDon="",d30_flexi_kuyaDon="",d31_flexi_kuyaDon="";
    },
    getUserEmp() {
      axios
        .get(
          'api/get-user/' + this.getUser.user.personnel_id
        )
        .then((response) => {
          console.log("users=>", response.data);
          console.log("response", response);

          let fname = response.data['first_name'];
          let lname = response.data['last_name'];
          let mname = response.data['middle_name'];
          let m = mname.charAt(0);
          let ffname = fname + ' ' + m + '.' + ' ' + lname;
          this.fullname = ffname.toUpperCase();

        })
        .catch((error) => {
          alert(error);
        });
    },
    reference() {

      let d = moment().format('L');
      let t = moment().format('LTS');

      return d + ' ' + t; 
    },
    formatDate(date) {
      return moment
        .utc(date, "YYYY-MM-D hh:mm:ss")
        .locale("en-us")
        .local()
        .format("MMM D YYYY hh:mm A");
    },
    getDate() {
      let m = this.from; // get the month
      let f = this.from; // get date from
      let t = this.to; // get date to

      let month =  String(m[5]) + String(m[6]);
      let year = String(m[0]) + String(m[1]) + String(m[2]) + String(m[3]);
      let dayTo = "";
      let dayFrom = "";
      
      this.months = month;
      this.years = year;

      if(this.to) {
        dayTo = String(t[8]) + String(t[9]);
      }
      if(this.from) {
        dayFrom = String(f[8]) + String(f[9]);
      }

      let d = new Date();

      if(month === '01') {
        return "January" + " " + dayFrom + "-" + dayTo + "," + " " + year;
      } else if(month === '02') {
        return "February" + " " + dayFrom + "-" + dayTo + "," + " " + year;
      } else if(month === '03') {
        return "March" + " " + dayFrom + "-" + dayTo + "," + " " + year;
      } else if(month === '04') {
        return "April" + " " + dayFrom + "-" + dayTo + "," + " " + year;
      } else if(month === '05') {
        return "May" + " " + dayFrom + "-" + dayTo + "," + " " + year;
      } else if(month === '06') {
        return "June" + " " + dayFrom + "-" + dayTo + "," + " " + year;
      } else if(month === '07') {
        return "July" + " " + dayFrom + "-" + dayTo + "," + " " + year;
      } else if(month === '08') {
        return "August" + " " + dayFrom + "-" + dayTo + "," + " " + year;
      } else if(month === '09') {
        return "September" + " " + dayFrom + "-" + dayTo + "," + " " + year;
      } else if(month === '10') {
        return "October" + " " + dayFrom + "-" + dayTo + "," + " " + year;
      } else if(month === '11') {
        return "November" + " " + dayFrom + "-" + dayTo + "," + " " + year;
      } else if(month === '12') {
        return "December" + " " + dayFrom + "-" + dayTo + "," + " " + year;
      } else {
        return "";
      } 

    },

    getCheckIN(date1) {

      var dt = new Date(date1);
      let t = "";
     
      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }

      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 



      this.individual_logs.forEach(individual_log => {
        if(individual_log.date === date1 && individual_log.inOutStatus === 'Check-In') {
          if(individual_log.time > "5:00" || individual_log.time < "11:59") {
            t = individual_log.time + "AM";
          } else {
            t = "";
          }
        }
      });
      return t;
    },
    checkUnderTime1(date1) {
      var dt = new Date(date1), d = moment().format('L'), i = 0, j = -1, x = "", x_flexi = "", x_flexi_kuyaDon = "";
      let checkIn = "00:00:00", checkIn1 = "00:00:00", checkOut = "00:00:00", checkOut1 = "00:00:00", dif = "", dif1 = "", dif2 = "", dif3 = "", s = "", e = "", s1 = "", s2 = "", s3 = "", e1 = "", e2 = "", e3 = "", bs = "", bs1 = "", bs2 = "", bs3 = "", r1 = "00:00:00", r1_flexi = "00:00:00", r1_flexi_kuyaDon = "00:00:00", r2 = "00:00:00", r3 = "00:00:00", r4 = "00:00:00", r3_undertime = "00:00:00", r3_undertime_kuyaDon = "00:00:00";


      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }

      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 
      
      this.individual_logs.forEach(individual_log => {
        if(individual_log.date === date1) {
      
            if(individual_log.inOutStatus === 'Check-In') {
              if(this.$route.params.pID == 15) {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "9:00:00" || checkIn > "09:00:00") {
                        bs = "9:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var seconds = Math.floor((dif / 1000) % 60),
                        minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;
                        seconds = (seconds < 10) ? "0" + seconds : seconds;

                        r1_flexi = hours + ":" + minutes + ":" + seconds;
                      } 

                    }
              }

              if(this.$route.params.pID == 23) {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "7:00:00" || checkIn > "07:00:00") {
                        bs = "7:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var seconds = Math.floor((dif / 1000) % 60),
                        minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;
                        seconds = (seconds < 10) ? "0" + seconds : seconds;

                        r1_flexi_kuyaDon = hours + ":" + minutes + ":" + seconds;
                      } 

                    }
              }
                if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                  checkIn = individual_log.time;  
                  if(checkIn > "8:00:00" || checkIn > "08:00:00") {
                    bs = "8:00:00"
                    e = new Date(d + " " + checkIn);
                    s = new Date(d + " " + bs);
                    dif = Math.abs(e - s);

                    
                  var seconds = Math.floor((dif / 1000) % 60),
                    minutes = Math.floor((dif / (1000 * 60)) % 60),
                    hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                    hours = (hours < 10) ? "0" + hours : hours;
                    minutes = (minutes < 10) ? "0" + minutes : minutes;
                    seconds = (seconds < 10) ? "0" + seconds : seconds;

                    r1 = hours + ":" + minutes + ":" + seconds;
                  } 

                }
  
            }

          if(i !== 2) {

            if(individual_log.inOutStatus === 'Check-In') {
                if((individual_log.time > "12:00:00" && individual_log.time <= "13:00:00") || individual_log.time < "17:00:00") {
                checkIn1 = individual_log.time;
                if(checkIn1 > "13:00:00" || (checkIn1 > "13:00:00" && checkIn1 <= "17:00:00")) {
                  bs1 = "13:00:00"
                  e1 = new Date(d + " " + checkIn1);
                  s1 = new Date(d + " " + bs1);
                  dif1 = Math.abs(e1 - s1);

                  var seconds = Math.floor((dif1 / 1000) % 60),
                  minutes = Math.floor((dif1 / (1000 * 60)) % 60),
                  hours = Math.floor((dif1 / (1000 * 60 * 60)) % 24);

                  hours = (hours < 10) ? "0" + hours : hours;
                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                  r2 = hours + ":" + minutes + ":" + seconds;
                } 

              }
            }
            i++;
          }



      
          if(individual_log.inOutStatus === 'Check-Out') {
                  var temp_time = "00:00:00";
                  if(individual_log.time > '13:00:00' || (individual_log.time > '13:00:00' && individual_log.time <= '24:00:00')) {
                                checkOut1 = individual_log.time;
                                checkOut1 = checkOut1.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [checkOut1];

                      if(checkOut1.length > 1) { // If time format correct
                        checkOut1 = checkOut1.slice (1); // Remove full string match value

                              if(checkOut1[0] > 13 && checkOut1[0] <= 17) {
                                  temp_time = individual_log.time;

                                  bs2 = "18:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3_undertime = hours + ":" + minutes + ":" + seconds;
                              }
                              if(checkOut1[0] > 13 && checkOut1[0] <= 16) {
                                  temp_time = individual_log.time;

                                  bs2 = "17:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3 = hours + ":" + minutes + ":" + seconds;
                              }

                              if(checkOut1[0] > 13 && checkOut1[0] <= 15) {
                                  temp_time = individual_log.time;

                                  bs2 = "16:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3_undertime_kuyaDon = hours + ":" + minutes + ":" + seconds;
                              }
                          }
                   }
          }


          if(individual_log.inOutStatus === 'Check-Out') {
                var temp_time = "00:00:00";
                checkOut = individual_log.time;
                checkOut = checkOut.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [checkOut];
                if(checkOut.length > 1) { // If time format correct
                      checkOut = checkOut.slice (1); // Remove full string match value

                      if(checkOut[0] < 12) {
                          if(checkOut[0] > 8 && checkOut[0] <= 11) {
                              temp_time = individual_log.time;
                              bs3 = "12:00:00"
                              e3 = new Date(d + " " + temp_time);
                              s3 = new Date(d + " " + bs3);
                              dif3 = Math.abs(s3 - e3);

                              var seconds = Math.floor((dif3 / 1000) % 60),
                              minutes = Math.floor((dif3 / (1000 * 60)) % 60),
                              hours = Math.floor((dif3 / (1000 * 60 * 60)) % 24);

                              hours = (hours < 10) ? "0" + hours : hours;
                              minutes = (minutes < 10) ? "0" + minutes : minutes;
                              seconds = (seconds < 10) ? "0" + seconds : seconds;

                              r4 = hours + ":" + minutes + ":" + seconds;

                          }

                      }
                }
               
                                     
          }
    
          x_flexi = moment.duration(r1_flexi).add(moment.duration(r2)).add(moment.duration(r3_undertime)).add(moment.duration(r4))
              moment.utc(x_flexi.as('milliseconds')).format("HH:mm:ss")

          x_flexi_kuyaDon = moment.duration(r1_flexi_kuyaDon).add(moment.duration(r2)).add(moment.duration(r3_undertime_kuyaDon)).add(moment.duration(r4))
              moment.utc(x_flexi_kuyaDon.as('milliseconds')).format("HH:mm:ss")
           
          x = moment.duration(r1).add(moment.duration(r2)).add(moment.duration(r3)).add(moment.duration(r4))
              moment.utc(x.as('milliseconds')).format("HH:mm:ss")

          d1 = x;
          d1_flexi = x_flexi;  
          d1_flexi_kuyaDon = x_flexi_kuyaDon;     
        }
      });
      if(this.$route.params.pID == 15 || this.$route.params.pID == 37 || this.$route.params.pID == 13 || this.$route.params.pID == 1) {
         return r3_undertime;
      }
      if(this.$route.params.pID == 23) {
        return x_flexi_kuyaDon;
      }
      return x;
    },
    checkUnderTime2(date1) {
      var dt = new Date(date1), d = moment().format('L'), i = 0, j = -1, x = "", x_flexi = "", x_flexi_kuyaDon = "";
      let checkIn = "00:00:00", checkIn1 = "00:00:00", checkOut = "00:00:00", checkOut1 = "00:00:00", dif = "", dif1 = "", dif2 = "", dif3 = "", s = "", e = "", s1 = "", s2 = "", s3 = "", e1 = "", e2 = "", e3 = "", bs = "", bs1 = "", bs2 = "", bs3 = "", r1 = "00:00:00", r1_flexi = "00:00:00", r1_flexi_kuyaDon = "00:00:00", r2 = "00:00:00", r3 = "00:00:00", r4 = "00:00:00", r3_undertime = "00:00:00", r3_undertime_kuyaDon = "00:00:00";


      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }

      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 
      
      this.individual_logs.forEach(individual_log => {
        if(individual_log.date === date1) {
      
            if(individual_log.inOutStatus === 'Check-In') {
              if(this.$route.params.pID == 15) {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "9:00:00" || checkIn > "09:00:00") {
                        bs = "9:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var seconds = Math.floor((dif / 1000) % 60),
                        minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;
                        seconds = (seconds < 10) ? "0" + seconds : seconds;

                        r1_flexi = hours + ":" + minutes + ":" + seconds;
                      } 

                    }
              }

              if(this.$route.params.pID == 23) {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "7:00:00" || checkIn > "07:00:00") {
                        bs = "7:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var seconds = Math.floor((dif / 1000) % 60),
                        minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;
                        seconds = (seconds < 10) ? "0" + seconds : seconds;

                        r1_flexi_kuyaDon = hours + ":" + minutes + ":" + seconds;
                      } 

                    }
              }
                if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                  checkIn = individual_log.time;  
                  if(checkIn > "8:00:00" || checkIn > "08:00:00") {
                    bs = "8:00:00"
                    e = new Date(d + " " + checkIn);
                    s = new Date(d + " " + bs);
                    dif = Math.abs(e - s);

                    
                  var seconds = Math.floor((dif / 1000) % 60),
                    minutes = Math.floor((dif / (1000 * 60)) % 60),
                    hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                    hours = (hours < 10) ? "0" + hours : hours;
                    minutes = (minutes < 10) ? "0" + minutes : minutes;
                    seconds = (seconds < 10) ? "0" + seconds : seconds;

                    r1 = hours + ":" + minutes + ":" + seconds;
                  } 

                }
  
            }

          if(i !== 2) {

            if(individual_log.inOutStatus === 'Check-In') {
                if((individual_log.time > "12:00:00" && individual_log.time <= "13:00:00") || individual_log.time < "17:00:00") {
                checkIn1 = individual_log.time;
                if(checkIn1 > "13:00:00" || (checkIn1 > "13:00:00" && checkIn1 <= "17:00:00")) {
                  bs1 = "13:00:00"
                  e1 = new Date(d + " " + checkIn1);
                  s1 = new Date(d + " " + bs1);
                  dif1 = Math.abs(e1 - s1);

                  var seconds = Math.floor((dif1 / 1000) % 60),
                  minutes = Math.floor((dif1 / (1000 * 60)) % 60),
                  hours = Math.floor((dif1 / (1000 * 60 * 60)) % 24);

                  hours = (hours < 10) ? "0" + hours : hours;
                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                  r2 = hours + ":" + minutes + ":" + seconds;
                } 

              }
            }
            i++;
          }



      
          if(individual_log.inOutStatus === 'Check-Out') {
                  var temp_time = "00:00:00";
                  if(individual_log.time > '13:00:00' || (individual_log.time > '13:00:00' && individual_log.time <= '24:00:00')) {
                                checkOut1 = individual_log.time;
                                checkOut1 = checkOut1.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [checkOut1];

                      if(checkOut1.length > 1) { // If time format correct
                        checkOut1 = checkOut1.slice (1); // Remove full string match value

                              if(checkOut1[0] > 13 && checkOut1[0] <= 17) {
                                  temp_time = individual_log.time;

                                  bs2 = "18:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3_undertime = hours + ":" + minutes + ":" + seconds;
                              }
                              if(checkOut1[0] > 13 && checkOut1[0] <= 16) {
                                  temp_time = individual_log.time;

                                  bs2 = "17:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3 = hours + ":" + minutes + ":" + seconds;
                              }

                              if(checkOut1[0] > 13 && checkOut1[0] <= 15) {
                                  temp_time = individual_log.time;

                                  bs2 = "16:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3_undertime_kuyaDon = hours + ":" + minutes + ":" + seconds;
                              }
                          }
                   }
          }


          if(individual_log.inOutStatus === 'Check-Out') {
                var temp_time = "00:00:00";
                checkOut = individual_log.time;
                checkOut = checkOut.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [checkOut];
                if(checkOut.length > 1) { // If time format correct
                      checkOut = checkOut.slice (1); // Remove full string match value

                      if(checkOut[0] < 12) {
                          if(checkOut[0] > 8 && checkOut[0] <= 11) {
                              temp_time = individual_log.time;
                              bs3 = "12:00:00"
                              e3 = new Date(d + " " + temp_time);
                              s3 = new Date(d + " " + bs3);
                              dif3 = Math.abs(s3 - e3);

                              var seconds = Math.floor((dif3 / 1000) % 60),
                              minutes = Math.floor((dif3 / (1000 * 60)) % 60),
                              hours = Math.floor((dif3 / (1000 * 60 * 60)) % 24);

                              hours = (hours < 10) ? "0" + hours : hours;
                              minutes = (minutes < 10) ? "0" + minutes : minutes;
                              seconds = (seconds < 10) ? "0" + seconds : seconds;

                              r4 = hours + ":" + minutes + ":" + seconds;

                          }

                      }
                }
               
                                     
          }
    
          x_flexi = moment.duration(r1_flexi).add(moment.duration(r2)).add(moment.duration(r3_undertime)).add(moment.duration(r4))
              moment.utc(x_flexi.as('milliseconds')).format("HH:mm:ss")

          x_flexi_kuyaDon = moment.duration(r1_flexi_kuyaDon).add(moment.duration(r2)).add(moment.duration(r3_undertime_kuyaDon)).add(moment.duration(r4))
              moment.utc(x_flexi_kuyaDon.as('milliseconds')).format("HH:mm:ss")
           
          x = moment.duration(r1).add(moment.duration(r2)).add(moment.duration(r3)).add(moment.duration(r4))
              moment.utc(x.as('milliseconds')).format("HH:mm:ss")

          d2 = x;
          d2_flexi = x_flexi;  
          d2_flexi_kuyaDon = x_flexi_kuyaDon;     
        }
      });
      if(this.$route.params.pID == 15 || this.$route.params.pID == 37 || this.$route.params.pID == 13 || this.$route.params.pID == 1) {
         return r3_undertime;
      }
      if(this.$route.params.pID == 23) {
        return x_flexi_kuyaDon;
      }
      return x;
    },
    checkUnderTime3(date1) {
      var dt = new Date(date1), d = moment().format('L'), i = 0, j = -1, x = "", x_flexi = "", x_flexi_kuyaDon = "";
      let checkIn = "00:00:00", checkIn1 = "00:00:00", checkOut = "00:00:00", checkOut1 = "00:00:00", dif = "", dif1 = "", dif2 = "", dif3 = "", s = "", e = "", s1 = "", s2 = "", s3 = "", e1 = "", e2 = "", e3 = "", bs = "", bs1 = "", bs2 = "", bs3 = "", r1 = "00:00:00", r1_flexi = "00:00:00", r1_flexi_kuyaDon = "00:00:00", r2 = "00:00:00", r3 = "00:00:00", r4 = "00:00:00", r3_undertime = "00:00:00", r3_undertime_kuyaDon = "00:00:00";


      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }

      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 
      
      this.individual_logs.forEach(individual_log => {
        if(individual_log.date === date1) {
      
            if(individual_log.inOutStatus === 'Check-In') {
              if(this.$route.params.pID == 15) {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "9:00:00" || checkIn > "09:00:00") {
                        bs = "9:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var seconds = Math.floor((dif / 1000) % 60),
                        minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;
                        seconds = (seconds < 10) ? "0" + seconds : seconds;

                        r1_flexi = hours + ":" + minutes + ":" + seconds;
                      } 

                    }
              }

              if(this.$route.params.pID == 23) {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "7:00:00" || checkIn > "07:00:00") {
                        bs = "7:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var seconds = Math.floor((dif / 1000) % 60),
                        minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;
                        seconds = (seconds < 10) ? "0" + seconds : seconds;

                        r1_flexi_kuyaDon = hours + ":" + minutes + ":" + seconds;
                      } 

                    }
              }
                if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                  checkIn = individual_log.time;  
                  if(checkIn > "8:00:00" || checkIn > "08:00:00") {
                    bs = "8:00:00"
                    e = new Date(d + " " + checkIn);
                    s = new Date(d + " " + bs);
                    dif = Math.abs(e - s);

                    
                  var seconds = Math.floor((dif / 1000) % 60),
                    minutes = Math.floor((dif / (1000 * 60)) % 60),
                    hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                    hours = (hours < 10) ? "0" + hours : hours;
                    minutes = (minutes < 10) ? "0" + minutes : minutes;
                    seconds = (seconds < 10) ? "0" + seconds : seconds;

                    r1 = hours + ":" + minutes + ":" + seconds;
                  } 

                }
  
            }

          if(i !== 2) {

            if(individual_log.inOutStatus === 'Check-In') {
                if((individual_log.time > "12:00:00" && individual_log.time <= "13:00:00") || individual_log.time < "17:00:00") {
                checkIn1 = individual_log.time;
                if(checkIn1 > "13:00:00" || (checkIn1 > "13:00:00" && checkIn1 <= "17:00:00")) {
                  bs1 = "13:00:00"
                  e1 = new Date(d + " " + checkIn1);
                  s1 = new Date(d + " " + bs1);
                  dif1 = Math.abs(e1 - s1);

                  var seconds = Math.floor((dif1 / 1000) % 60),
                  minutes = Math.floor((dif1 / (1000 * 60)) % 60),
                  hours = Math.floor((dif1 / (1000 * 60 * 60)) % 24);

                  hours = (hours < 10) ? "0" + hours : hours;
                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                  r2 = hours + ":" + minutes + ":" + seconds;
                } 

              }
            }
            i++;
          }



      
          if(individual_log.inOutStatus === 'Check-Out') {
                  var temp_time = "00:00:00";
                  if(individual_log.time > '13:00:00' || (individual_log.time > '13:00:00' && individual_log.time <= '24:00:00')) {
                                checkOut1 = individual_log.time;
                                checkOut1 = checkOut1.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [checkOut1];

                      if(checkOut1.length > 1) { // If time format correct
                        checkOut1 = checkOut1.slice (1); // Remove full string match value

                              if(checkOut1[0] > 13 && checkOut1[0] <= 17) {
                                  temp_time = individual_log.time;

                                  bs2 = "18:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3_undertime = hours + ":" + minutes + ":" + seconds;
                              }
                              if(checkOut1[0] > 13 && checkOut1[0] <= 16) {
                                  temp_time = individual_log.time;

                                  bs2 = "17:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3 = hours + ":" + minutes + ":" + seconds;
                              }

                              if(checkOut1[0] > 13 && checkOut1[0] <= 15) {
                                  temp_time = individual_log.time;

                                  bs2 = "16:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3_undertime_kuyaDon = hours + ":" + minutes + ":" + seconds;
                              }
                          }
                   }
          }


          if(individual_log.inOutStatus === 'Check-Out') {
                var temp_time = "00:00:00";
                checkOut = individual_log.time;
                checkOut = checkOut.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [checkOut];
                if(checkOut.length > 1) { // If time format correct
                      checkOut = checkOut.slice (1); // Remove full string match value

                      if(checkOut[0] < 12) {
                          if(checkOut[0] > 8 && checkOut[0] <= 11) {
                              temp_time = individual_log.time;
                              bs3 = "12:00:00"
                              e3 = new Date(d + " " + temp_time);
                              s3 = new Date(d + " " + bs3);
                              dif3 = Math.abs(s3 - e3);

                              var seconds = Math.floor((dif3 / 1000) % 60),
                              minutes = Math.floor((dif3 / (1000 * 60)) % 60),
                              hours = Math.floor((dif3 / (1000 * 60 * 60)) % 24);

                              hours = (hours < 10) ? "0" + hours : hours;
                              minutes = (minutes < 10) ? "0" + minutes : minutes;
                              seconds = (seconds < 10) ? "0" + seconds : seconds;

                              r4 = hours + ":" + minutes + ":" + seconds;

                          }

                      }
                }
               
                                     
          }
    
          x_flexi = moment.duration(r1_flexi).add(moment.duration(r2)).add(moment.duration(r3_undertime)).add(moment.duration(r4))
              moment.utc(x_flexi.as('milliseconds')).format("HH:mm:ss")

          x_flexi_kuyaDon = moment.duration(r1_flexi_kuyaDon).add(moment.duration(r2)).add(moment.duration(r3_undertime_kuyaDon)).add(moment.duration(r4))
              moment.utc(x_flexi_kuyaDon.as('milliseconds')).format("HH:mm:ss")
           
          x = moment.duration(r1).add(moment.duration(r2)).add(moment.duration(r3)).add(moment.duration(r4))
              moment.utc(x.as('milliseconds')).format("HH:mm:ss")

          d3 = x;
          d3_flexi = x_flexi;  
          d3_flexi_kuyaDon = x_flexi_kuyaDon;     
        }
      });
      if(this.$route.params.pID == 15 || this.$route.params.pID == 37 || this.$route.params.pID == 13 || this.$route.params.pID == 1) {
         return r3_undertime;
      }
      if(this.$route.params.pID == 23) {
        return x_flexi_kuyaDon;
      }
      return x;
    },
    checkUnderTime4(date1) {
      var dt = new Date(date1), d = moment().format('L'), i = 0, j = -1, x = "", x_flexi = "", x_flexi_kuyaDon = "";
      let checkIn = "00:00:00", checkIn1 = "00:00:00", checkOut = "00:00:00", checkOut1 = "00:00:00", dif = "", dif1 = "", dif2 = "", dif3 = "", s = "", e = "", s1 = "", s2 = "", s3 = "", e1 = "", e2 = "", e3 = "", bs = "", bs1 = "", bs2 = "", bs3 = "", r1 = "00:00:00", r1_flexi = "00:00:00", r1_flexi_kuyaDon = "00:00:00", r2 = "00:00:00", r3 = "00:00:00", r4 = "00:00:00", r3_undertime = "00:00:00", r3_undertime_kuyaDon = "00:00:00";


      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }

      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 
      
      this.individual_logs.forEach(individual_log => {
        if(individual_log.date === date1) {
      
            if(individual_log.inOutStatus === 'Check-In') {
              if(this.$route.params.pID == 15) {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "9:00:00" || checkIn > "09:00:00") {
                        bs = "9:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var seconds = Math.floor((dif / 1000) % 60),
                        minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;
                        seconds = (seconds < 10) ? "0" + seconds : seconds;

                        r1_flexi = hours + ":" + minutes + ":" + seconds;
                      } 

                    }
              }

              if(this.$route.params.pID == 23) {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "7:00:00" || checkIn > "07:00:00") {
                        bs = "7:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var seconds = Math.floor((dif / 1000) % 60),
                        minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;
                        seconds = (seconds < 10) ? "0" + seconds : seconds;

                        r1_flexi_kuyaDon = hours + ":" + minutes + ":" + seconds;
                      } 

                    }
              }
                if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                  checkIn = individual_log.time;  
                  if(checkIn > "8:00:00" || checkIn > "08:00:00") {
                    bs = "8:00:00"
                    e = new Date(d + " " + checkIn);
                    s = new Date(d + " " + bs);
                    dif = Math.abs(e - s);

                    
                  var seconds = Math.floor((dif / 1000) % 60),
                    minutes = Math.floor((dif / (1000 * 60)) % 60),
                    hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                    hours = (hours < 10) ? "0" + hours : hours;
                    minutes = (minutes < 10) ? "0" + minutes : minutes;
                    seconds = (seconds < 10) ? "0" + seconds : seconds;

                    r1 = hours + ":" + minutes + ":" + seconds;
                  } 

                }
  
            }

          if(i !== 2) {

            if(individual_log.inOutStatus === 'Check-In') {
                if((individual_log.time > "12:00:00" && individual_log.time <= "13:00:00") || individual_log.time < "17:00:00") {
                checkIn1 = individual_log.time;
                if(checkIn1 > "13:00:00" || (checkIn1 > "13:00:00" && checkIn1 <= "17:00:00")) {
                  bs1 = "13:00:00"
                  e1 = new Date(d + " " + checkIn1);
                  s1 = new Date(d + " " + bs1);
                  dif1 = Math.abs(e1 - s1);

                  var seconds = Math.floor((dif1 / 1000) % 60),
                  minutes = Math.floor((dif1 / (1000 * 60)) % 60),
                  hours = Math.floor((dif1 / (1000 * 60 * 60)) % 24);

                  hours = (hours < 10) ? "0" + hours : hours;
                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                  r2 = hours + ":" + minutes + ":" + seconds;
                } 

              }
            }
            i++;
          }



      
          if(individual_log.inOutStatus === 'Check-Out') {
                  var temp_time = "00:00:00";
                  if(individual_log.time > '13:00:00' || (individual_log.time > '13:00:00' && individual_log.time <= '24:00:00')) {
                                checkOut1 = individual_log.time;
                                checkOut1 = checkOut1.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [checkOut1];

                      if(checkOut1.length > 1) { // If time format correct
                        checkOut1 = checkOut1.slice (1); // Remove full string match value

                              if(checkOut1[0] > 13 && checkOut1[0] <= 17) {
                                  temp_time = individual_log.time;

                                  bs2 = "18:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3_undertime = hours + ":" + minutes + ":" + seconds;
                              }
                              if(checkOut1[0] > 13 && checkOut1[0] <= 16) {
                                  temp_time = individual_log.time;

                                  bs2 = "17:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3 = hours + ":" + minutes + ":" + seconds;
                              }

                              if(checkOut1[0] > 13 && checkOut1[0] <= 15) {
                                  temp_time = individual_log.time;

                                  bs2 = "16:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3_undertime_kuyaDon = hours + ":" + minutes + ":" + seconds;
                              }
                          }
                   }
          }


          if(individual_log.inOutStatus === 'Check-Out') {
                var temp_time = "00:00:00";
                checkOut = individual_log.time;
                checkOut = checkOut.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [checkOut];
                if(checkOut.length > 1) { // If time format correct
                      checkOut = checkOut.slice (1); // Remove full string match value

                      if(checkOut[0] < 12) {
                          if(checkOut[0] > 8 && checkOut[0] <= 11) {
                              temp_time = individual_log.time;
                              bs3 = "12:00:00"
                              e3 = new Date(d + " " + temp_time);
                              s3 = new Date(d + " " + bs3);
                              dif3 = Math.abs(s3 - e3);

                              var seconds = Math.floor((dif3 / 1000) % 60),
                              minutes = Math.floor((dif3 / (1000 * 60)) % 60),
                              hours = Math.floor((dif3 / (1000 * 60 * 60)) % 24);

                              hours = (hours < 10) ? "0" + hours : hours;
                              minutes = (minutes < 10) ? "0" + minutes : minutes;
                              seconds = (seconds < 10) ? "0" + seconds : seconds;

                              r4 = hours + ":" + minutes + ":" + seconds;

                          }

                      }
                }
               
                                     
          }
    
          x_flexi = moment.duration(r1_flexi).add(moment.duration(r2)).add(moment.duration(r3_undertime)).add(moment.duration(r4))
              moment.utc(x_flexi.as('milliseconds')).format("HH:mm:ss")

          x_flexi_kuyaDon = moment.duration(r1_flexi_kuyaDon).add(moment.duration(r2)).add(moment.duration(r3_undertime_kuyaDon)).add(moment.duration(r4))
              moment.utc(x_flexi_kuyaDon.as('milliseconds')).format("HH:mm:ss")
           
          x = moment.duration(r1).add(moment.duration(r2)).add(moment.duration(r3)).add(moment.duration(r4))
              moment.utc(x.as('milliseconds')).format("HH:mm:ss")

          d4 = x;
          d4_flexi = x_flexi;  
          d4_flexi_kuyaDon = x_flexi_kuyaDon;     
        }
      });
      if(this.$route.params.pID == 15 || this.$route.params.pID == 37 || this.$route.params.pID == 13 || this.$route.params.pID == 1) {
         return r3_undertime;
      }
      if(this.$route.params.pID == 23) {
        return x_flexi_kuyaDon;
      }
      return x;
    },
    checkUnderTime5(date1) {
      var dt = new Date(date1), d = moment().format('L'), i = 0, j = -1, x = "", x_flexi = "", x_flexi_kuyaDon = "";
      let checkIn = "00:00:00", checkIn1 = "00:00:00", checkOut = "00:00:00", checkOut1 = "00:00:00", dif = "", dif1 = "", dif2 = "", dif3 = "", s = "", e = "", s1 = "", s2 = "", s3 = "", e1 = "", e2 = "", e3 = "", bs = "", bs1 = "", bs2 = "", bs3 = "", r1 = "00:00:00", r1_flexi = "00:00:00", r1_flexi_kuyaDon = "00:00:00", r2 = "00:00:00", r3 = "00:00:00", r4 = "00:00:00", r3_undertime = "00:00:00", r3_undertime_kuyaDon = "00:00:00";


      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }

      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 
      
      this.individual_logs.forEach(individual_log => {
        if(individual_log.date === date1) {
      
            if(individual_log.inOutStatus === 'Check-In') {
              if(this.$route.params.pID == 15) {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "9:00:00" || checkIn > "09:00:00") {
                        bs = "9:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var seconds = Math.floor((dif / 1000) % 60),
                        minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;
                        seconds = (seconds < 10) ? "0" + seconds : seconds;

                        r1_flexi = hours + ":" + minutes + ":" + seconds;
                      } 

                    }
              }

              if(this.$route.params.pID == 23) {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "7:00:00" || checkIn > "07:00:00") {
                        bs = "7:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var seconds = Math.floor((dif / 1000) % 60),
                        minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;
                        seconds = (seconds < 10) ? "0" + seconds : seconds;

                        r1_flexi_kuyaDon = hours + ":" + minutes + ":" + seconds;
                      } 

                    }
              }
                if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                  checkIn = individual_log.time;  
                  if(checkIn > "8:00:00" || checkIn > "08:00:00") {
                    bs = "8:00:00"
                    e = new Date(d + " " + checkIn);
                    s = new Date(d + " " + bs);
                    dif = Math.abs(e - s);

                    
                  var seconds = Math.floor((dif / 1000) % 60),
                    minutes = Math.floor((dif / (1000 * 60)) % 60),
                    hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                    hours = (hours < 10) ? "0" + hours : hours;
                    minutes = (minutes < 10) ? "0" + minutes : minutes;
                    seconds = (seconds < 10) ? "0" + seconds : seconds;

                    r1 = hours + ":" + minutes + ":" + seconds;
                  } 

                }
  
            }

          if(i !== 2) {

            if(individual_log.inOutStatus === 'Check-In') {
                if((individual_log.time > "12:00:00" && individual_log.time <= "13:00:00") || individual_log.time < "17:00:00") {
                checkIn1 = individual_log.time;
                if(checkIn1 > "13:00:00" || (checkIn1 > "13:00:00" && checkIn1 <= "17:00:00")) {
                  bs1 = "13:00:00"
                  e1 = new Date(d + " " + checkIn1);
                  s1 = new Date(d + " " + bs1);
                  dif1 = Math.abs(e1 - s1);

                  var seconds = Math.floor((dif1 / 1000) % 60),
                  minutes = Math.floor((dif1 / (1000 * 60)) % 60),
                  hours = Math.floor((dif1 / (1000 * 60 * 60)) % 24);

                  hours = (hours < 10) ? "0" + hours : hours;
                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                  r2 = hours + ":" + minutes + ":" + seconds;
                } 

              }
            }
            i++;
          }



      
          if(individual_log.inOutStatus === 'Check-Out') {
                  var temp_time = "00:00:00";
                  if(individual_log.time > '13:00:00' || (individual_log.time > '13:00:00' && individual_log.time <= '24:00:00')) {
                                checkOut1 = individual_log.time;
                                checkOut1 = checkOut1.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [checkOut1];

                      if(checkOut1.length > 1) { // If time format correct
                        checkOut1 = checkOut1.slice (1); // Remove full string match value

                              if(checkOut1[0] > 13 && checkOut1[0] <= 17) {
                                  temp_time = individual_log.time;

                                  bs2 = "18:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3_undertime = hours + ":" + minutes + ":" + seconds;
                              }
                              if(checkOut1[0] > 13 && checkOut1[0] <= 16) {
                                  temp_time = individual_log.time;

                                  bs2 = "17:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3 = hours + ":" + minutes + ":" + seconds;
                              }

                              if(checkOut1[0] > 13 && checkOut1[0] <= 15) {
                                  temp_time = individual_log.time;

                                  bs2 = "16:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3_undertime_kuyaDon = hours + ":" + minutes + ":" + seconds;
                              }
                          }
                   }
          }


          if(individual_log.inOutStatus === 'Check-Out') {
                var temp_time = "00:00:00";
                checkOut = individual_log.time;
                checkOut = checkOut.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [checkOut];
                if(checkOut.length > 1) { // If time format correct
                      checkOut = checkOut.slice (1); // Remove full string match value

                      if(checkOut[0] < 12) {
                          if(checkOut[0] > 8 && checkOut[0] <= 11) {
                              temp_time = individual_log.time;
                              bs3 = "12:00:00"
                              e3 = new Date(d + " " + temp_time);
                              s3 = new Date(d + " " + bs3);
                              dif3 = Math.abs(s3 - e3);

                              var seconds = Math.floor((dif3 / 1000) % 60),
                              minutes = Math.floor((dif3 / (1000 * 60)) % 60),
                              hours = Math.floor((dif3 / (1000 * 60 * 60)) % 24);

                              hours = (hours < 10) ? "0" + hours : hours;
                              minutes = (minutes < 10) ? "0" + minutes : minutes;
                              seconds = (seconds < 10) ? "0" + seconds : seconds;

                              r4 = hours + ":" + minutes + ":" + seconds;

                          }

                      }
                }
               
                                     
          }
    
          x_flexi = moment.duration(r1_flexi).add(moment.duration(r2)).add(moment.duration(r3_undertime)).add(moment.duration(r4))
              moment.utc(x_flexi.as('milliseconds')).format("HH:mm:ss")

          x_flexi_kuyaDon = moment.duration(r1_flexi_kuyaDon).add(moment.duration(r2)).add(moment.duration(r3_undertime_kuyaDon)).add(moment.duration(r4))
              moment.utc(x_flexi_kuyaDon.as('milliseconds')).format("HH:mm:ss")
           
          x = moment.duration(r1).add(moment.duration(r2)).add(moment.duration(r3)).add(moment.duration(r4))
              moment.utc(x.as('milliseconds')).format("HH:mm:ss")

          d5 = x;
          d5_flexi = x_flexi;  
          d5_flexi_kuyaDon = x_flexi_kuyaDon;     
        }
      });
      if(this.$route.params.pID == 15 || this.$route.params.pID == 37 || this.$route.params.pID == 13 || this.$route.params.pID == 1) {
         return r3_undertime;
      }
      if(this.$route.params.pID == 23) {
        return x_flexi_kuyaDon;
      }
      return x;
    },
    checkUnderTime6(date1) {
      var dt = new Date(date1), d = moment().format('L'), i = 0, j = -1, x = "", x_flexi = "", x_flexi_kuyaDon = "";
      let checkIn = "00:00:00", checkIn1 = "00:00:00", checkOut = "00:00:00", checkOut1 = "00:00:00", dif = "", dif1 = "", dif2 = "", dif3 = "", s = "", e = "", s1 = "", s2 = "", s3 = "", e1 = "", e2 = "", e3 = "", bs = "", bs1 = "", bs2 = "", bs3 = "", r1 = "00:00:00", r1_flexi = "00:00:00", r1_flexi_kuyaDon = "00:00:00", r2 = "00:00:00", r3 = "00:00:00", r4 = "00:00:00", r3_undertime = "00:00:00", r3_undertime_kuyaDon = "00:00:00";


      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }

      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 
      
      this.individual_logs.forEach(individual_log => {
        if(individual_log.date === date1) {
      
            if(individual_log.inOutStatus === 'Check-In') {
              if(this.$route.params.pID == 15) {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "9:00:00" || checkIn > "09:00:00") {
                        bs = "9:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var seconds = Math.floor((dif / 1000) % 60),
                        minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;
                        seconds = (seconds < 10) ? "0" + seconds : seconds;

                        r1_flexi = hours + ":" + minutes + ":" + seconds;
                      } 

                    }
              }

              if(this.$route.params.pID == 23) {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "7:00:00" || checkIn > "07:00:00") {
                        bs = "7:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var seconds = Math.floor((dif / 1000) % 60),
                        minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;
                        seconds = (seconds < 10) ? "0" + seconds : seconds;

                        r1_flexi_kuyaDon = hours + ":" + minutes + ":" + seconds;
                      } 

                    }
              }
                if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                  checkIn = individual_log.time;  
                  if(checkIn > "8:00:00" || checkIn > "08:00:00") {
                    bs = "8:00:00"
                    e = new Date(d + " " + checkIn);
                    s = new Date(d + " " + bs);
                    dif = Math.abs(e - s);

                    
                  var seconds = Math.floor((dif / 1000) % 60),
                    minutes = Math.floor((dif / (1000 * 60)) % 60),
                    hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                    hours = (hours < 10) ? "0" + hours : hours;
                    minutes = (minutes < 10) ? "0" + minutes : minutes;
                    seconds = (seconds < 10) ? "0" + seconds : seconds;

                    r1 = hours + ":" + minutes + ":" + seconds;
                  } 

                }
  
            }

          if(i !== 2) {

            if(individual_log.inOutStatus === 'Check-In') {
                if((individual_log.time > "12:00:00" && individual_log.time <= "13:00:00") || individual_log.time < "17:00:00") {
                checkIn1 = individual_log.time;
                if(checkIn1 > "13:00:00" || (checkIn1 > "13:00:00" && checkIn1 <= "17:00:00")) {
                  bs1 = "13:00:00"
                  e1 = new Date(d + " " + checkIn1);
                  s1 = new Date(d + " " + bs1);
                  dif1 = Math.abs(e1 - s1);

                  var seconds = Math.floor((dif1 / 1000) % 60),
                  minutes = Math.floor((dif1 / (1000 * 60)) % 60),
                  hours = Math.floor((dif1 / (1000 * 60 * 60)) % 24);

                  hours = (hours < 10) ? "0" + hours : hours;
                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                  r2 = hours + ":" + minutes + ":" + seconds;
                } 

              }
            }
            i++;
          }



      
          if(individual_log.inOutStatus === 'Check-Out') {
                  var temp_time = "00:00:00";
                  if(individual_log.time > '13:00:00' || (individual_log.time > '13:00:00' && individual_log.time <= '24:00:00')) {
                                checkOut1 = individual_log.time;
                                checkOut1 = checkOut1.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [checkOut1];

                      if(checkOut1.length > 1) { // If time format correct
                        checkOut1 = checkOut1.slice (1); // Remove full string match value

                              if(checkOut1[0] > 13 && checkOut1[0] <= 17) {
                                  temp_time = individual_log.time;

                                  bs2 = "18:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3_undertime = hours + ":" + minutes + ":" + seconds;
                              }
                              if(checkOut1[0] > 13 && checkOut1[0] <= 16) {
                                  temp_time = individual_log.time;

                                  bs2 = "17:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3 = hours + ":" + minutes + ":" + seconds;
                              }

                              if(checkOut1[0] > 13 && checkOut1[0] <= 15) {
                                  temp_time = individual_log.time;

                                  bs2 = "16:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3_undertime_kuyaDon = hours + ":" + minutes + ":" + seconds;
                              }
                          }
                   }
          }


          if(individual_log.inOutStatus === 'Check-Out') {
                var temp_time = "00:00:00";
                checkOut = individual_log.time;
                checkOut = checkOut.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [checkOut];
                if(checkOut.length > 1) { // If time format correct
                      checkOut = checkOut.slice (1); // Remove full string match value

                      if(checkOut[0] < 12) {
                          if(checkOut[0] > 8 && checkOut[0] <= 11) {
                              temp_time = individual_log.time;
                              bs3 = "12:00:00"
                              e3 = new Date(d + " " + temp_time);
                              s3 = new Date(d + " " + bs3);
                              dif3 = Math.abs(s3 - e3);

                              var seconds = Math.floor((dif3 / 1000) % 60),
                              minutes = Math.floor((dif3 / (1000 * 60)) % 60),
                              hours = Math.floor((dif3 / (1000 * 60 * 60)) % 24);

                              hours = (hours < 10) ? "0" + hours : hours;
                              minutes = (minutes < 10) ? "0" + minutes : minutes;
                              seconds = (seconds < 10) ? "0" + seconds : seconds;

                              r4 = hours + ":" + minutes + ":" + seconds;

                          }

                      }
                }
               
                                     
          }
    
          x_flexi = moment.duration(r1_flexi).add(moment.duration(r2)).add(moment.duration(r3_undertime)).add(moment.duration(r4))
              moment.utc(x_flexi.as('milliseconds')).format("HH:mm:ss")

          x_flexi_kuyaDon = moment.duration(r1_flexi_kuyaDon).add(moment.duration(r2)).add(moment.duration(r3_undertime_kuyaDon)).add(moment.duration(r4))
              moment.utc(x_flexi_kuyaDon.as('milliseconds')).format("HH:mm:ss")
           
          x = moment.duration(r1).add(moment.duration(r2)).add(moment.duration(r3)).add(moment.duration(r4))
              moment.utc(x.as('milliseconds')).format("HH:mm:ss")

          d6 = x;
          d6_flexi = x_flexi;  
          d6_flexi_kuyaDon = x_flexi_kuyaDon;     
        }
      });
      if(this.$route.params.pID == 15 || this.$route.params.pID == 37 || this.$route.params.pID == 13 || this.$route.params.pID == 1) {
         return r3_undertime;
      }
      if(this.$route.params.pID == 23) {
        return x_flexi_kuyaDon;
      }
      return x;
    },
    checkUnderTime7(date1) {
      var dt = new Date(date1), d = moment().format('L'), i = 0, j = -1, x = "", x_flexi = "", x_flexi_kuyaDon = "";
      let checkIn = "00:00:00", checkIn1 = "00:00:00", checkOut = "00:00:00", checkOut1 = "00:00:00", dif = "", dif1 = "", dif2 = "", dif3 = "", s = "", e = "", s1 = "", s2 = "", s3 = "", e1 = "", e2 = "", e3 = "", bs = "", bs1 = "", bs2 = "", bs3 = "", r1 = "00:00:00", r1_flexi = "00:00:00", r1_flexi_kuyaDon = "00:00:00", r2 = "00:00:00", r3 = "00:00:00", r4 = "00:00:00", r3_undertime = "00:00:00", r3_undertime_kuyaDon = "00:00:00";


      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }

      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 
      
      this.individual_logs.forEach(individual_log => {
        if(individual_log.date === date1) {
      
            if(individual_log.inOutStatus === 'Check-In') {
              if(this.$route.params.pID == 15) {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "9:00:00" || checkIn > "09:00:00") {
                        bs = "9:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var seconds = Math.floor((dif / 1000) % 60),
                        minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;
                        seconds = (seconds < 10) ? "0" + seconds : seconds;

                        r1_flexi = hours + ":" + minutes + ":" + seconds;
                      } 

                    }
              }

              if(this.$route.params.pID == 23) {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "7:00:00" || checkIn > "07:00:00") {
                        bs = "7:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var seconds = Math.floor((dif / 1000) % 60),
                        minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;
                        seconds = (seconds < 10) ? "0" + seconds : seconds;

                        r1_flexi_kuyaDon = hours + ":" + minutes + ":" + seconds;
                      } 

                    }
              }
                if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                  checkIn = individual_log.time;  
                  if(checkIn > "8:00:00" || checkIn > "08:00:00") {
                    bs = "8:00:00"
                    e = new Date(d + " " + checkIn);
                    s = new Date(d + " " + bs);
                    dif = Math.abs(e - s);

                    
                  var seconds = Math.floor((dif / 1000) % 60),
                    minutes = Math.floor((dif / (1000 * 60)) % 60),
                    hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                    hours = (hours < 10) ? "0" + hours : hours;
                    minutes = (minutes < 10) ? "0" + minutes : minutes;
                    seconds = (seconds < 10) ? "0" + seconds : seconds;

                    r1 = hours + ":" + minutes + ":" + seconds;
                  } 

                }
  
            }

          if(i !== 2) {

            if(individual_log.inOutStatus === 'Check-In') {
                if((individual_log.time > "12:00:00" && individual_log.time <= "13:00:00") || individual_log.time < "17:00:00") {
                checkIn1 = individual_log.time;
                if(checkIn1 > "13:00:00" || (checkIn1 > "13:00:00" && checkIn1 <= "17:00:00")) {
                  bs1 = "13:00:00"
                  e1 = new Date(d + " " + checkIn1);
                  s1 = new Date(d + " " + bs1);
                  dif1 = Math.abs(e1 - s1);

                  var seconds = Math.floor((dif1 / 1000) % 60),
                  minutes = Math.floor((dif1 / (1000 * 60)) % 60),
                  hours = Math.floor((dif1 / (1000 * 60 * 60)) % 24);

                  hours = (hours < 10) ? "0" + hours : hours;
                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                  r2 = hours + ":" + minutes + ":" + seconds;
                } 

              }
            }
            i++;
          }



      
          if(individual_log.inOutStatus === 'Check-Out') {
                  var temp_time = "00:00:00";
                  if(individual_log.time > '13:00:00' || (individual_log.time > '13:00:00' && individual_log.time <= '24:00:00')) {
                                checkOut1 = individual_log.time;
                                checkOut1 = checkOut1.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [checkOut1];

                      if(checkOut1.length > 1) { // If time format correct
                        checkOut1 = checkOut1.slice (1); // Remove full string match value

                              if(checkOut1[0] > 13 && checkOut1[0] <= 17) {
                                  temp_time = individual_log.time;

                                  bs2 = "18:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3_undertime = hours + ":" + minutes + ":" + seconds;
                              }
                              if(checkOut1[0] > 13 && checkOut1[0] <= 16) {
                                  temp_time = individual_log.time;

                                  bs2 = "17:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3 = hours + ":" + minutes + ":" + seconds;
                              }

                              if(checkOut1[0] > 13 && checkOut1[0] <= 15) {
                                  temp_time = individual_log.time;

                                  bs2 = "16:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3_undertime_kuyaDon = hours + ":" + minutes + ":" + seconds;
                              }
                          }
                   }
          }


          if(individual_log.inOutStatus === 'Check-Out') {
                var temp_time = "00:00:00";
                checkOut = individual_log.time;
                checkOut = checkOut.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [checkOut];
                if(checkOut.length > 1) { // If time format correct
                      checkOut = checkOut.slice (1); // Remove full string match value

                      if(checkOut[0] < 12) {
                          if(checkOut[0] > 8 && checkOut[0] <= 11) {
                              temp_time = individual_log.time;
                              bs3 = "12:00:00"
                              e3 = new Date(d + " " + temp_time);
                              s3 = new Date(d + " " + bs3);
                              dif3 = Math.abs(s3 - e3);

                              var seconds = Math.floor((dif3 / 1000) % 60),
                              minutes = Math.floor((dif3 / (1000 * 60)) % 60),
                              hours = Math.floor((dif3 / (1000 * 60 * 60)) % 24);

                              hours = (hours < 10) ? "0" + hours : hours;
                              minutes = (minutes < 10) ? "0" + minutes : minutes;
                              seconds = (seconds < 10) ? "0" + seconds : seconds;

                              r4 = hours + ":" + minutes + ":" + seconds;

                          }

                      }
                }
               
                                     
          }
    
          x_flexi = moment.duration(r1_flexi).add(moment.duration(r2)).add(moment.duration(r3_undertime)).add(moment.duration(r4))
              moment.utc(x_flexi.as('milliseconds')).format("HH:mm:ss")

          x_flexi_kuyaDon = moment.duration(r1_flexi_kuyaDon).add(moment.duration(r2)).add(moment.duration(r3_undertime_kuyaDon)).add(moment.duration(r4))
              moment.utc(x_flexi_kuyaDon.as('milliseconds')).format("HH:mm:ss")
           
          x = moment.duration(r1).add(moment.duration(r2)).add(moment.duration(r3)).add(moment.duration(r4))
              moment.utc(x.as('milliseconds')).format("HH:mm:ss")

          d7 = x;
          d7_flexi = x_flexi;  
          d7_flexi_kuyaDon = x_flexi_kuyaDon;     
        }
      });
      if(this.$route.params.pID == 15 || this.$route.params.pID == 37 || this.$route.params.pID == 13 || this.$route.params.pID == 1) {
         return r3_undertime;
      }
      if(this.$route.params.pID == 23) {
        return x_flexi_kuyaDon;
      }
      return x;
    },
    checkUnderTime8(date1) {
      var dt = new Date(date1), d = moment().format('L'), i = 0, j = -1, x = "", x_flexi = "", x_flexi_kuyaDon = "";
      let checkIn = "00:00:00", checkIn1 = "00:00:00", checkOut = "00:00:00", checkOut1 = "00:00:00", dif = "", dif1 = "", dif2 = "", dif3 = "", s = "", e = "", s1 = "", s2 = "", s3 = "", e1 = "", e2 = "", e3 = "", bs = "", bs1 = "", bs2 = "", bs3 = "", r1 = "00:00:00", r1_flexi = "00:00:00", r1_flexi_kuyaDon = "00:00:00", r2 = "00:00:00", r3 = "00:00:00", r4 = "00:00:00", r3_undertime = "00:00:00", r3_undertime_kuyaDon = "00:00:00";


      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }

      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 
      
      this.individual_logs.forEach(individual_log => {
        if(individual_log.date === date1) {
      
            if(individual_log.inOutStatus === 'Check-In') {
              if(this.$route.params.pID == 15) {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "9:00:00" || checkIn > "09:00:00") {
                        bs = "9:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var seconds = Math.floor((dif / 1000) % 60),
                        minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;
                        seconds = (seconds < 10) ? "0" + seconds : seconds;

                        r1_flexi = hours + ":" + minutes + ":" + seconds;
                      } 

                    }
              }

              if(this.$route.params.pID == 23) {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "7:00:00" || checkIn > "07:00:00") {
                        bs = "7:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var seconds = Math.floor((dif / 1000) % 60),
                        minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;
                        seconds = (seconds < 10) ? "0" + seconds : seconds;

                        r1_flexi_kuyaDon = hours + ":" + minutes + ":" + seconds;
                      } 

                    }
              }
                if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                  checkIn = individual_log.time;  
                  if(checkIn > "8:00:00" || checkIn > "08:00:00") {
                    bs = "8:00:00"
                    e = new Date(d + " " + checkIn);
                    s = new Date(d + " " + bs);
                    dif = Math.abs(e - s);

                    
                  var seconds = Math.floor((dif / 1000) % 60),
                    minutes = Math.floor((dif / (1000 * 60)) % 60),
                    hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                    hours = (hours < 10) ? "0" + hours : hours;
                    minutes = (minutes < 10) ? "0" + minutes : minutes;
                    seconds = (seconds < 10) ? "0" + seconds : seconds;

                    r1 = hours + ":" + minutes + ":" + seconds;
                  } 

                }
  
            }

          if(i !== 2) {

            if(individual_log.inOutStatus === 'Check-In') {
                if((individual_log.time > "12:00:00" && individual_log.time <= "13:00:00") || individual_log.time < "17:00:00") {
                checkIn1 = individual_log.time;
                if(checkIn1 > "13:00:00" || (checkIn1 > "13:00:00" && checkIn1 <= "17:00:00")) {
                  bs1 = "13:00:00"
                  e1 = new Date(d + " " + checkIn1);
                  s1 = new Date(d + " " + bs1);
                  dif1 = Math.abs(e1 - s1);

                  var seconds = Math.floor((dif1 / 1000) % 60),
                  minutes = Math.floor((dif1 / (1000 * 60)) % 60),
                  hours = Math.floor((dif1 / (1000 * 60 * 60)) % 24);

                  hours = (hours < 10) ? "0" + hours : hours;
                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                  r2 = hours + ":" + minutes + ":" + seconds;
                } 

              }
            }
            i++;
          }



      
          if(individual_log.inOutStatus === 'Check-Out') {
                  var temp_time = "00:00:00";
                  if(individual_log.time > '13:00:00' || (individual_log.time > '13:00:00' && individual_log.time <= '24:00:00')) {
                                checkOut1 = individual_log.time;
                                checkOut1 = checkOut1.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [checkOut1];

                      if(checkOut1.length > 1) { // If time format correct
                        checkOut1 = checkOut1.slice (1); // Remove full string match value

                              if(checkOut1[0] > 13 && checkOut1[0] <= 17) {
                                  temp_time = individual_log.time;

                                  bs2 = "18:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3_undertime = hours + ":" + minutes + ":" + seconds;
                              }
                              if(checkOut1[0] > 13 && checkOut1[0] <= 16) {
                                  temp_time = individual_log.time;

                                  bs2 = "17:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3 = hours + ":" + minutes + ":" + seconds;
                              }

                              if(checkOut1[0] > 13 && checkOut1[0] <= 15) {
                                  temp_time = individual_log.time;

                                  bs2 = "16:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3_undertime_kuyaDon = hours + ":" + minutes + ":" + seconds;
                              }
                          }
                   }
          }


          if(individual_log.inOutStatus === 'Check-Out') {
                var temp_time = "00:00:00";
                checkOut = individual_log.time;
                checkOut = checkOut.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [checkOut];
                if(checkOut.length > 1) { // If time format correct
                      checkOut = checkOut.slice (1); // Remove full string match value

                      if(checkOut[0] < 12) {
                          if(checkOut[0] > 8 && checkOut[0] <= 11) {
                              temp_time = individual_log.time;
                              bs3 = "12:00:00"
                              e3 = new Date(d + " " + temp_time);
                              s3 = new Date(d + " " + bs3);
                              dif3 = Math.abs(s3 - e3);

                              var seconds = Math.floor((dif3 / 1000) % 60),
                              minutes = Math.floor((dif3 / (1000 * 60)) % 60),
                              hours = Math.floor((dif3 / (1000 * 60 * 60)) % 24);

                              hours = (hours < 10) ? "0" + hours : hours;
                              minutes = (minutes < 10) ? "0" + minutes : minutes;
                              seconds = (seconds < 10) ? "0" + seconds : seconds;

                              r4 = hours + ":" + minutes + ":" + seconds;

                          }

                      }
                }
               
                                     
          }
    
          x_flexi = moment.duration(r1_flexi).add(moment.duration(r2)).add(moment.duration(r3_undertime)).add(moment.duration(r4))
              moment.utc(x_flexi.as('milliseconds')).format("HH:mm:ss")

          x_flexi_kuyaDon = moment.duration(r1_flexi_kuyaDon).add(moment.duration(r2)).add(moment.duration(r3_undertime_kuyaDon)).add(moment.duration(r4))
              moment.utc(x_flexi_kuyaDon.as('milliseconds')).format("HH:mm:ss")
           
          x = moment.duration(r1).add(moment.duration(r2)).add(moment.duration(r3)).add(moment.duration(r4))
              moment.utc(x.as('milliseconds')).format("HH:mm:ss")

          d8 = x;
          d8_flexi = x_flexi;  
          d8_flexi_kuyaDon = x_flexi_kuyaDon;     
        }
      });
      if(this.$route.params.pID == 15 || this.$route.params.pID == 37 || this.$route.params.pID == 13 || this.$route.params.pID == 1) {
         return r3_undertime;
      }
      if(this.$route.params.pID == 23) {
        return x_flexi_kuyaDon;
      }
      return x;
    },
    checkUnderTime9(date1) {
      var dt = new Date(date1), d = moment().format('L'), i = 0, j = -1, x = "", x_flexi = "", x_flexi_kuyaDon = "";
      let checkIn = "00:00:00", checkIn1 = "00:00:00", checkOut = "00:00:00", checkOut1 = "00:00:00", dif = "", dif1 = "", dif2 = "", dif3 = "", s = "", e = "", s1 = "", s2 = "", s3 = "", e1 = "", e2 = "", e3 = "", bs = "", bs1 = "", bs2 = "", bs3 = "", r1 = "00:00:00", r1_flexi = "00:00:00", r1_flexi_kuyaDon = "00:00:00", r2 = "00:00:00", r3 = "00:00:00", r4 = "00:00:00", r3_undertime = "00:00:00", r3_undertime_kuyaDon = "00:00:00";


      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }

      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 
      
      this.individual_logs.forEach(individual_log => {
        if(individual_log.date === date1) {
      
            if(individual_log.inOutStatus === 'Check-In') {
              if(this.$route.params.pID == 15) {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "9:00:00" || checkIn > "09:00:00") {
                        bs = "9:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var seconds = Math.floor((dif / 1000) % 60),
                        minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;
                        seconds = (seconds < 10) ? "0" + seconds : seconds;

                        r1_flexi = hours + ":" + minutes + ":" + seconds;
                      } 

                    }
              }

              if(this.$route.params.pID == 23) {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "7:00:00" || checkIn > "07:00:00") {
                        bs = "7:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var seconds = Math.floor((dif / 1000) % 60),
                        minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;
                        seconds = (seconds < 10) ? "0" + seconds : seconds;

                        r1_flexi_kuyaDon = hours + ":" + minutes + ":" + seconds;
                      } 

                    }
              }
                if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                  checkIn = individual_log.time;  
                  if(checkIn > "8:00:00" || checkIn > "08:00:00") {
                    bs = "8:00:00"
                    e = new Date(d + " " + checkIn);
                    s = new Date(d + " " + bs);
                    dif = Math.abs(e - s);

                    
                  var seconds = Math.floor((dif / 1000) % 60),
                    minutes = Math.floor((dif / (1000 * 60)) % 60),
                    hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                    hours = (hours < 10) ? "0" + hours : hours;
                    minutes = (minutes < 10) ? "0" + minutes : minutes;
                    seconds = (seconds < 10) ? "0" + seconds : seconds;

                    r1 = hours + ":" + minutes + ":" + seconds;
                  } 

                }
  
            }

          if(i !== 2) {

            if(individual_log.inOutStatus === 'Check-In') {
                if((individual_log.time > "12:00:00" && individual_log.time <= "13:00:00") || individual_log.time < "17:00:00") {
                checkIn1 = individual_log.time;
                if(checkIn1 > "13:00:00" || (checkIn1 > "13:00:00" && checkIn1 <= "17:00:00")) {
                  bs1 = "13:00:00"
                  e1 = new Date(d + " " + checkIn1);
                  s1 = new Date(d + " " + bs1);
                  dif1 = Math.abs(e1 - s1);

                  var seconds = Math.floor((dif1 / 1000) % 60),
                  minutes = Math.floor((dif1 / (1000 * 60)) % 60),
                  hours = Math.floor((dif1 / (1000 * 60 * 60)) % 24);

                  hours = (hours < 10) ? "0" + hours : hours;
                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                  r2 = hours + ":" + minutes + ":" + seconds;
                } 

              }
            }
            i++;
          }



      
          if(individual_log.inOutStatus === 'Check-Out') {
                  var temp_time = "00:00:00";
                  if(individual_log.time > '13:00:00' || (individual_log.time > '13:00:00' && individual_log.time <= '24:00:00')) {
                                checkOut1 = individual_log.time;
                                checkOut1 = checkOut1.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [checkOut1];

                      if(checkOut1.length > 1) { // If time format correct
                        checkOut1 = checkOut1.slice (1); // Remove full string match value

                              if(checkOut1[0] > 13 && checkOut1[0] <= 17) {
                                  temp_time = individual_log.time;

                                  bs2 = "18:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3_undertime = hours + ":" + minutes + ":" + seconds;
                              }
                              if(checkOut1[0] > 13 && checkOut1[0] <= 16) {
                                  temp_time = individual_log.time;

                                  bs2 = "17:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3 = hours + ":" + minutes + ":" + seconds;
                              }

                              if(checkOut1[0] > 13 && checkOut1[0] <= 15) {
                                  temp_time = individual_log.time;

                                  bs2 = "16:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3_undertime_kuyaDon = hours + ":" + minutes + ":" + seconds;
                              }
                          }
                   }
          }


          if(individual_log.inOutStatus === 'Check-Out') {
                var temp_time = "00:00:00";
                checkOut = individual_log.time;
                checkOut = checkOut.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [checkOut];
                if(checkOut.length > 1) { // If time format correct
                      checkOut = checkOut.slice (1); // Remove full string match value

                      if(checkOut[0] < 12) {
                          if(checkOut[0] > 8 && checkOut[0] <= 11) {
                              temp_time = individual_log.time;
                              bs3 = "12:00:00"
                              e3 = new Date(d + " " + temp_time);
                              s3 = new Date(d + " " + bs3);
                              dif3 = Math.abs(s3 - e3);

                              var seconds = Math.floor((dif3 / 1000) % 60),
                              minutes = Math.floor((dif3 / (1000 * 60)) % 60),
                              hours = Math.floor((dif3 / (1000 * 60 * 60)) % 24);

                              hours = (hours < 10) ? "0" + hours : hours;
                              minutes = (minutes < 10) ? "0" + minutes : minutes;
                              seconds = (seconds < 10) ? "0" + seconds : seconds;

                              r4 = hours + ":" + minutes + ":" + seconds;

                          }

                      }
                }
               
                                     
          }
    
          x_flexi = moment.duration(r1_flexi).add(moment.duration(r2)).add(moment.duration(r3_undertime)).add(moment.duration(r4))
              moment.utc(x_flexi.as('milliseconds')).format("HH:mm:ss")

          x_flexi_kuyaDon = moment.duration(r1_flexi_kuyaDon).add(moment.duration(r2)).add(moment.duration(r3_undertime_kuyaDon)).add(moment.duration(r4))
              moment.utc(x_flexi_kuyaDon.as('milliseconds')).format("HH:mm:ss")
           
          x = moment.duration(r1).add(moment.duration(r2)).add(moment.duration(r3)).add(moment.duration(r4))
              moment.utc(x.as('milliseconds')).format("HH:mm:ss")

          d9 = x;
          d9_flexi = x_flexi;  
          d9_flexi_kuyaDon = x_flexi_kuyaDon;     
        }
      });
      if(this.$route.params.pID == 15 || this.$route.params.pID == 37 || this.$route.params.pID == 13 || this.$route.params.pID == 1) {
         return r3_undertime;
      }
      if(this.$route.params.pID == 23) {
        return x_flexi_kuyaDon;
      }
      return x;
    },
    checkUnderTime10(date1) {
      var dt = new Date(date1), d = moment().format('L'), i = 0, j = -1, x = "", x_flexi = "", x_flexi_kuyaDon = "";
      let checkIn = "00:00:00", checkIn1 = "00:00:00", checkOut = "00:00:00", checkOut1 = "00:00:00", dif = "", dif1 = "", dif2 = "", dif3 = "", s = "", e = "", s1 = "", s2 = "", s3 = "", e1 = "", e2 = "", e3 = "", bs = "", bs1 = "", bs2 = "", bs3 = "", r1 = "00:00:00", r1_flexi = "00:00:00", r1_flexi_kuyaDon = "00:00:00", r2 = "00:00:00", r3 = "00:00:00", r4 = "00:00:00", r3_undertime = "00:00:00", r3_undertime_kuyaDon = "00:00:00";


      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }

      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 
      
      this.individual_logs.forEach(individual_log => {
        if(individual_log.date === date1) {
      
            if(individual_log.inOutStatus === 'Check-In') {
              if(this.$route.params.pID == 15) {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "9:00:00" || checkIn > "09:00:00") {
                        bs = "9:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var seconds = Math.floor((dif / 1000) % 60),
                        minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;
                        seconds = (seconds < 10) ? "0" + seconds : seconds;

                        r1_flexi = hours + ":" + minutes + ":" + seconds;
                      } 

                    }
              }

              if(this.$route.params.pID == 23) {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "7:00:00" || checkIn > "07:00:00") {
                        bs = "7:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var seconds = Math.floor((dif / 1000) % 60),
                        minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;
                        seconds = (seconds < 10) ? "0" + seconds : seconds;

                        r1_flexi_kuyaDon = hours + ":" + minutes + ":" + seconds;
                      } 

                    }
              }
                if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                  checkIn = individual_log.time;  
                  if(checkIn > "8:00:00" || checkIn > "08:00:00") {
                    bs = "8:00:00"
                    e = new Date(d + " " + checkIn);
                    s = new Date(d + " " + bs);
                    dif = Math.abs(e - s);

                    
                  var seconds = Math.floor((dif / 1000) % 60),
                    minutes = Math.floor((dif / (1000 * 60)) % 60),
                    hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                    hours = (hours < 10) ? "0" + hours : hours;
                    minutes = (minutes < 10) ? "0" + minutes : minutes;
                    seconds = (seconds < 10) ? "0" + seconds : seconds;

                    r1 = hours + ":" + minutes + ":" + seconds;
                  } 

                }
  
            }

          if(i !== 2) {

            if(individual_log.inOutStatus === 'Check-In') {
                if((individual_log.time > "12:00:00" && individual_log.time <= "13:00:00") || individual_log.time < "17:00:00") {
                checkIn1 = individual_log.time;
                if(checkIn1 > "13:00:00" || (checkIn1 > "13:00:00" && checkIn1 <= "17:00:00")) {
                  bs1 = "13:00:00"
                  e1 = new Date(d + " " + checkIn1);
                  s1 = new Date(d + " " + bs1);
                  dif1 = Math.abs(e1 - s1);

                  var seconds = Math.floor((dif1 / 1000) % 60),
                  minutes = Math.floor((dif1 / (1000 * 60)) % 60),
                  hours = Math.floor((dif1 / (1000 * 60 * 60)) % 24);

                  hours = (hours < 10) ? "0" + hours : hours;
                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                  r2 = hours + ":" + minutes + ":" + seconds;
                } 

              }
            }
            i++;
          }



      
          if(individual_log.inOutStatus === 'Check-Out') {
                  var temp_time = "00:00:00";
                  if(individual_log.time > '13:00:00' || (individual_log.time > '13:00:00' && individual_log.time <= '24:00:00')) {
                                checkOut1 = individual_log.time;
                                checkOut1 = checkOut1.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [checkOut1];

                      if(checkOut1.length > 1) { // If time format correct
                        checkOut1 = checkOut1.slice (1); // Remove full string match value

                              if(checkOut1[0] > 13 && checkOut1[0] <= 17) {
                                  temp_time = individual_log.time;

                                  bs2 = "18:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3_undertime = hours + ":" + minutes + ":" + seconds;
                              }
                              if(checkOut1[0] > 13 && checkOut1[0] <= 16) {
                                  temp_time = individual_log.time;

                                  bs2 = "17:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3 = hours + ":" + minutes + ":" + seconds;
                              }

                              if(checkOut1[0] > 13 && checkOut1[0] <= 15) {
                                  temp_time = individual_log.time;

                                  bs2 = "16:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3_undertime_kuyaDon = hours + ":" + minutes + ":" + seconds;
                              }
                          }
                   }
          }


          if(individual_log.inOutStatus === 'Check-Out') {
                var temp_time = "00:00:00";
                checkOut = individual_log.time;
                checkOut = checkOut.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [checkOut];
                if(checkOut.length > 1) { // If time format correct
                      checkOut = checkOut.slice (1); // Remove full string match value

                      if(checkOut[0] < 12) {
                          if(checkOut[0] > 8 && checkOut[0] <= 11) {
                              temp_time = individual_log.time;
                              bs3 = "12:00:00"
                              e3 = new Date(d + " " + temp_time);
                              s3 = new Date(d + " " + bs3);
                              dif3 = Math.abs(s3 - e3);

                              var seconds = Math.floor((dif3 / 1000) % 60),
                              minutes = Math.floor((dif3 / (1000 * 60)) % 60),
                              hours = Math.floor((dif3 / (1000 * 60 * 60)) % 24);

                              hours = (hours < 10) ? "0" + hours : hours;
                              minutes = (minutes < 10) ? "0" + minutes : minutes;
                              seconds = (seconds < 10) ? "0" + seconds : seconds;

                              r4 = hours + ":" + minutes + ":" + seconds;

                          }

                      }
                }
               
                                     
          }
    
          x_flexi = moment.duration(r1_flexi).add(moment.duration(r2)).add(moment.duration(r3_undertime)).add(moment.duration(r4))
              moment.utc(x_flexi.as('milliseconds')).format("HH:mm:ss")

          x_flexi_kuyaDon = moment.duration(r1_flexi_kuyaDon).add(moment.duration(r2)).add(moment.duration(r3_undertime_kuyaDon)).add(moment.duration(r4))
              moment.utc(x_flexi_kuyaDon.as('milliseconds')).format("HH:mm:ss")
           
          x = moment.duration(r1).add(moment.duration(r2)).add(moment.duration(r3)).add(moment.duration(r4))
              moment.utc(x.as('milliseconds')).format("HH:mm:ss")

          d10 = x;
          d10_flexi = x_flexi;  
          d10_flexi_kuyaDon = x_flexi_kuyaDon;     
        }
      });
      if(this.$route.params.pID == 15 || this.$route.params.pID == 37 || this.$route.params.pID == 13 || this.$route.params.pID == 1) {
         return r3_undertime;
      }
      if(this.$route.params.pID == 23) {
        return x_flexi_kuyaDon;
      }
      return x;
    },
    checkUnderTime11(date1) {
      var dt = new Date(date1), d = moment().format('L'), i = 0, j = -1, x = "", x_flexi = "", x_flexi_kuyaDon = "";
      let checkIn = "00:00:00", checkIn1 = "00:00:00", checkOut = "00:00:00", checkOut1 = "00:00:00", dif = "", dif1 = "", dif2 = "", dif3 = "", s = "", e = "", s1 = "", s2 = "", s3 = "", e1 = "", e2 = "", e3 = "", bs = "", bs1 = "", bs2 = "", bs3 = "", r1 = "00:00:00", r1_flexi = "00:00:00", r1_flexi_kuyaDon = "00:00:00", r2 = "00:00:00", r3 = "00:00:00", r4 = "00:00:00", r3_undertime = "00:00:00", r3_undertime_kuyaDon = "00:00:00";


      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }

      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 
      
      this.individual_logs.forEach(individual_log => {
        if(individual_log.date === date1) {
      
            if(individual_log.inOutStatus === 'Check-In') {
              if(this.$route.params.pID == 15) {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "9:00:00" || checkIn > "09:00:00") {
                        bs = "9:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var seconds = Math.floor((dif / 1000) % 60),
                        minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;
                        seconds = (seconds < 10) ? "0" + seconds : seconds;

                        r1_flexi = hours + ":" + minutes + ":" + seconds;
                      } 

                    }
              }

              if(this.$route.params.pID == 23) {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "7:00:00" || checkIn > "07:00:00") {
                        bs = "7:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var seconds = Math.floor((dif / 1000) % 60),
                        minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;
                        seconds = (seconds < 10) ? "0" + seconds : seconds;

                        r1_flexi_kuyaDon = hours + ":" + minutes + ":" + seconds;
                      } 

                    }
              }
                if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                  checkIn = individual_log.time;  
                  if(checkIn > "8:00:00" || checkIn > "08:00:00") {
                    bs = "8:00:00"
                    e = new Date(d + " " + checkIn);
                    s = new Date(d + " " + bs);
                    dif = Math.abs(e - s);

                    
                  var seconds = Math.floor((dif / 1000) % 60),
                    minutes = Math.floor((dif / (1000 * 60)) % 60),
                    hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                    hours = (hours < 10) ? "0" + hours : hours;
                    minutes = (minutes < 10) ? "0" + minutes : minutes;
                    seconds = (seconds < 10) ? "0" + seconds : seconds;

                    r1 = hours + ":" + minutes + ":" + seconds;
                  } 

                }
  
            }

          if(i !== 2) {

            if(individual_log.inOutStatus === 'Check-In') {
                if((individual_log.time > "12:00:00" && individual_log.time <= "13:00:00") || individual_log.time < "17:00:00") {
                checkIn1 = individual_log.time;
                if(checkIn1 > "13:00:00" || (checkIn1 > "13:00:00" && checkIn1 <= "17:00:00")) {
                  bs1 = "13:00:00"
                  e1 = new Date(d + " " + checkIn1);
                  s1 = new Date(d + " " + bs1);
                  dif1 = Math.abs(e1 - s1);

                  var seconds = Math.floor((dif1 / 1000) % 60),
                  minutes = Math.floor((dif1 / (1000 * 60)) % 60),
                  hours = Math.floor((dif1 / (1000 * 60 * 60)) % 24);

                  hours = (hours < 10) ? "0" + hours : hours;
                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                  r2 = hours + ":" + minutes + ":" + seconds;
                } 

              }
            }
            i++;
          }



      
          if(individual_log.inOutStatus === 'Check-Out') {
                  var temp_time = "00:00:00";
                  if(individual_log.time > '13:00:00' || (individual_log.time > '13:00:00' && individual_log.time <= '24:00:00')) {
                                checkOut1 = individual_log.time;
                                checkOut1 = checkOut1.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [checkOut1];

                      if(checkOut1.length > 1) { // If time format correct
                        checkOut1 = checkOut1.slice (1); // Remove full string match value

                              if(checkOut1[0] > 13 && checkOut1[0] <= 17) {
                                  temp_time = individual_log.time;

                                  bs2 = "18:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3_undertime = hours + ":" + minutes + ":" + seconds;
                              }
                              if(checkOut1[0] > 13 && checkOut1[0] <= 16) {
                                  temp_time = individual_log.time;

                                  bs2 = "17:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3 = hours + ":" + minutes + ":" + seconds;
                              }

                              if(checkOut1[0] > 13 && checkOut1[0] <= 15) {
                                  temp_time = individual_log.time;

                                  bs2 = "16:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3_undertime_kuyaDon = hours + ":" + minutes + ":" + seconds;
                              }
                          }
                   }
          }


          if(individual_log.inOutStatus === 'Check-Out') {
                var temp_time = "00:00:00";
                checkOut = individual_log.time;
                checkOut = checkOut.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [checkOut];
                if(checkOut.length > 1) { // If time format correct
                      checkOut = checkOut.slice (1); // Remove full string match value

                      if(checkOut[0] < 12) {
                          if(checkOut[0] > 8 && checkOut[0] <= 11) {
                              temp_time = individual_log.time;
                              bs3 = "12:00:00"
                              e3 = new Date(d + " " + temp_time);
                              s3 = new Date(d + " " + bs3);
                              dif3 = Math.abs(s3 - e3);

                              var seconds = Math.floor((dif3 / 1000) % 60),
                              minutes = Math.floor((dif3 / (1000 * 60)) % 60),
                              hours = Math.floor((dif3 / (1000 * 60 * 60)) % 24);

                              hours = (hours < 10) ? "0" + hours : hours;
                              minutes = (minutes < 10) ? "0" + minutes : minutes;
                              seconds = (seconds < 10) ? "0" + seconds : seconds;

                              r4 = hours + ":" + minutes + ":" + seconds;

                          }

                      }
                }
               
                                     
          }
    
          x_flexi = moment.duration(r1_flexi).add(moment.duration(r2)).add(moment.duration(r3_undertime)).add(moment.duration(r4))
              moment.utc(x_flexi.as('milliseconds')).format("HH:mm:ss")

          x_flexi_kuyaDon = moment.duration(r1_flexi_kuyaDon).add(moment.duration(r2)).add(moment.duration(r3_undertime_kuyaDon)).add(moment.duration(r4))
              moment.utc(x_flexi_kuyaDon.as('milliseconds')).format("HH:mm:ss")
           
          x = moment.duration(r1).add(moment.duration(r2)).add(moment.duration(r3)).add(moment.duration(r4))
              moment.utc(x.as('milliseconds')).format("HH:mm:ss")

          d11 = x;
          d11_flexi = x_flexi;  
          d11_flexi_kuyaDon = x_flexi_kuyaDon;     
        }
      });
      if(this.$route.params.pID == 15 || this.$route.params.pID == 37 || this.$route.params.pID == 13 || this.$route.params.pID == 1) {
         return r3_undertime;
      }
      if(this.$route.params.pID == 23) {
        return x_flexi_kuyaDon;
      }
      return x;
    },
    checkUnderTime12(date1) {
      var dt = new Date(date1), d = moment().format('L'), i = 0, j = -1, x = "", x_flexi = "", x_flexi_kuyaDon = "";
      let checkIn = "00:00:00", checkIn1 = "00:00:00", checkOut = "00:00:00", checkOut1 = "00:00:00", dif = "", dif1 = "", dif2 = "", dif3 = "", s = "", e = "", s1 = "", s2 = "", s3 = "", e1 = "", e2 = "", e3 = "", bs = "", bs1 = "", bs2 = "", bs3 = "", r1 = "00:00:00", r1_flexi = "00:00:00", r1_flexi_kuyaDon = "00:00:00", r2 = "00:00:00", r3 = "00:00:00", r4 = "00:00:00", r3_undertime = "00:00:00", r3_undertime_kuyaDon = "00:00:00";


      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }

      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 
      
      this.individual_logs.forEach(individual_log => {
        if(individual_log.date === date1) {
      
            if(individual_log.inOutStatus === 'Check-In') {
              if(this.$route.params.pID == 15) {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "9:00:00" || checkIn > "09:00:00") {
                        bs = "9:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var seconds = Math.floor((dif / 1000) % 60),
                        minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;
                        seconds = (seconds < 10) ? "0" + seconds : seconds;

                        r1_flexi = hours + ":" + minutes + ":" + seconds;
                      } 

                    }
              }

              if(this.$route.params.pID == 23) {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "7:00:00" || checkIn > "07:00:00") {
                        bs = "7:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var seconds = Math.floor((dif / 1000) % 60),
                        minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;
                        seconds = (seconds < 10) ? "0" + seconds : seconds;

                        r1_flexi_kuyaDon = hours + ":" + minutes + ":" + seconds;
                      } 

                    }
              }
                if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                  checkIn = individual_log.time;  
                  if(checkIn > "8:00:00" || checkIn > "08:00:00") {
                    bs = "8:00:00"
                    e = new Date(d + " " + checkIn);
                    s = new Date(d + " " + bs);
                    dif = Math.abs(e - s);

                    
                  var seconds = Math.floor((dif / 1000) % 60),
                    minutes = Math.floor((dif / (1000 * 60)) % 60),
                    hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                    hours = (hours < 10) ? "0" + hours : hours;
                    minutes = (minutes < 10) ? "0" + minutes : minutes;
                    seconds = (seconds < 10) ? "0" + seconds : seconds;

                    r1 = hours + ":" + minutes + ":" + seconds;
                  } 

                }
  
            }

          if(i !== 2) {

            if(individual_log.inOutStatus === 'Check-In') {
                if((individual_log.time > "12:00:00" && individual_log.time <= "13:00:00") || individual_log.time < "17:00:00") {
                checkIn1 = individual_log.time;
                if(checkIn1 > "13:00:00" || (checkIn1 > "13:00:00" && checkIn1 <= "17:00:00")) {
                  bs1 = "13:00:00"
                  e1 = new Date(d + " " + checkIn1);
                  s1 = new Date(d + " " + bs1);
                  dif1 = Math.abs(e1 - s1);

                  var seconds = Math.floor((dif1 / 1000) % 60),
                  minutes = Math.floor((dif1 / (1000 * 60)) % 60),
                  hours = Math.floor((dif1 / (1000 * 60 * 60)) % 24);

                  hours = (hours < 10) ? "0" + hours : hours;
                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                  r2 = hours + ":" + minutes + ":" + seconds;
                } 

              }
            }
            i++;
          }



      
          if(individual_log.inOutStatus === 'Check-Out') {
                  var temp_time = "00:00:00";
                  if(individual_log.time > '13:00:00' || (individual_log.time > '13:00:00' && individual_log.time <= '24:00:00')) {
                                checkOut1 = individual_log.time;
                                checkOut1 = checkOut1.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [checkOut1];

                      if(checkOut1.length > 1) { // If time format correct
                        checkOut1 = checkOut1.slice (1); // Remove full string match value

                              if(checkOut1[0] > 13 && checkOut1[0] <= 17) {
                                  temp_time = individual_log.time;

                                  bs2 = "18:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3_undertime = hours + ":" + minutes + ":" + seconds;
                              }
                              if(checkOut1[0] > 13 && checkOut1[0] <= 16) {
                                  temp_time = individual_log.time;

                                  bs2 = "17:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3 = hours + ":" + minutes + ":" + seconds;
                              }

                              if(checkOut1[0] > 13 && checkOut1[0] <= 15) {
                                  temp_time = individual_log.time;

                                  bs2 = "16:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3_undertime_kuyaDon = hours + ":" + minutes + ":" + seconds;
                              }
                          }
                   }
          }


          if(individual_log.inOutStatus === 'Check-Out') {
                var temp_time = "00:00:00";
                checkOut = individual_log.time;
                checkOut = checkOut.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [checkOut];
                if(checkOut.length > 1) { // If time format correct
                      checkOut = checkOut.slice (1); // Remove full string match value

                      if(checkOut[0] < 12) {
                          if(checkOut[0] > 8 && checkOut[0] <= 11) {
                              temp_time = individual_log.time;
                              bs3 = "12:00:00"
                              e3 = new Date(d + " " + temp_time);
                              s3 = new Date(d + " " + bs3);
                              dif3 = Math.abs(s3 - e3);

                              var seconds = Math.floor((dif3 / 1000) % 60),
                              minutes = Math.floor((dif3 / (1000 * 60)) % 60),
                              hours = Math.floor((dif3 / (1000 * 60 * 60)) % 24);

                              hours = (hours < 10) ? "0" + hours : hours;
                              minutes = (minutes < 10) ? "0" + minutes : minutes;
                              seconds = (seconds < 10) ? "0" + seconds : seconds;

                              r4 = hours + ":" + minutes + ":" + seconds;

                          }

                      }
                }
               
                                     
          }
    
          x_flexi = moment.duration(r1_flexi).add(moment.duration(r2)).add(moment.duration(r3_undertime)).add(moment.duration(r4))
              moment.utc(x_flexi.as('milliseconds')).format("HH:mm:ss")

          x_flexi_kuyaDon = moment.duration(r1_flexi_kuyaDon).add(moment.duration(r2)).add(moment.duration(r3_undertime_kuyaDon)).add(moment.duration(r4))
              moment.utc(x_flexi_kuyaDon.as('milliseconds')).format("HH:mm:ss")
           
          x = moment.duration(r1).add(moment.duration(r2)).add(moment.duration(r3)).add(moment.duration(r4))
              moment.utc(x.as('milliseconds')).format("HH:mm:ss")

          d12 = x;
          d12_flexi = x_flexi;  
          d12_flexi_kuyaDon = x_flexi_kuyaDon;     
        }
      });
      if(this.$route.params.pID == 15 || this.$route.params.pID == 37 || this.$route.params.pID == 13 || this.$route.params.pID == 1) {
         return r3_undertime;
      }
      if(this.$route.params.pID == 23) {
        return x_flexi_kuyaDon;
      }
      return x;
    },
    checkUnderTime13(date1) {
      var dt = new Date(date1), d = moment().format('L'), i = 0, j = -1, x = "", x_flexi = "", x_flexi_kuyaDon = "";
      let checkIn = "00:00:00", checkIn1 = "00:00:00", checkOut = "00:00:00", checkOut1 = "00:00:00", dif = "", dif1 = "", dif2 = "", dif3 = "", s = "", e = "", s1 = "", s2 = "", s3 = "", e1 = "", e2 = "", e3 = "", bs = "", bs1 = "", bs2 = "", bs3 = "", r1 = "00:00:00", r1_flexi = "00:00:00", r1_flexi_kuyaDon = "00:00:00", r2 = "00:00:00", r3 = "00:00:00", r4 = "00:00:00", r3_undertime = "00:00:00", r3_undertime_kuyaDon = "00:00:00";


      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }

      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 
      
      this.individual_logs.forEach(individual_log => {
        if(individual_log.date === date1) {
      
            if(individual_log.inOutStatus === 'Check-In') {
              if(this.$route.params.pID == 15) {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "9:00:00" || checkIn > "09:00:00") {
                        bs = "9:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var seconds = Math.floor((dif / 1000) % 60),
                        minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;
                        seconds = (seconds < 10) ? "0" + seconds : seconds;

                        r1_flexi = hours + ":" + minutes + ":" + seconds;
                      } 

                    }
              }

              if(this.$route.params.pID == 23) {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "7:00:00" || checkIn > "07:00:00") {
                        bs = "7:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var seconds = Math.floor((dif / 1000) % 60),
                        minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;
                        seconds = (seconds < 10) ? "0" + seconds : seconds;

                        r1_flexi_kuyaDon = hours + ":" + minutes + ":" + seconds;
                      } 

                    }
              }
                if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                  checkIn = individual_log.time;  
                  if(checkIn > "8:00:00" || checkIn > "08:00:00") {
                    bs = "8:00:00"
                    e = new Date(d + " " + checkIn);
                    s = new Date(d + " " + bs);
                    dif = Math.abs(e - s);

                    
                  var seconds = Math.floor((dif / 1000) % 60),
                    minutes = Math.floor((dif / (1000 * 60)) % 60),
                    hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                    hours = (hours < 10) ? "0" + hours : hours;
                    minutes = (minutes < 10) ? "0" + minutes : minutes;
                    seconds = (seconds < 10) ? "0" + seconds : seconds;

                    r1 = hours + ":" + minutes + ":" + seconds;
                  } 

                }
  
            }

          if(i !== 2) {

            if(individual_log.inOutStatus === 'Check-In') {
                if((individual_log.time > "12:00:00" && individual_log.time <= "13:00:00") || individual_log.time < "17:00:00") {
                checkIn1 = individual_log.time;
                if(checkIn1 > "13:00:00" || (checkIn1 > "13:00:00" && checkIn1 <= "17:00:00")) {
                  bs1 = "13:00:00"
                  e1 = new Date(d + " " + checkIn1);
                  s1 = new Date(d + " " + bs1);
                  dif1 = Math.abs(e1 - s1);

                  var seconds = Math.floor((dif1 / 1000) % 60),
                  minutes = Math.floor((dif1 / (1000 * 60)) % 60),
                  hours = Math.floor((dif1 / (1000 * 60 * 60)) % 24);

                  hours = (hours < 10) ? "0" + hours : hours;
                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                  r2 = hours + ":" + minutes + ":" + seconds;
                } 

              }
            }
            i++;
          }



      
          if(individual_log.inOutStatus === 'Check-Out') {
                  var temp_time = "00:00:00";
                  if(individual_log.time > '13:00:00' || (individual_log.time > '13:00:00' && individual_log.time <= '24:00:00')) {
                                checkOut1 = individual_log.time;
                                checkOut1 = checkOut1.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [checkOut1];

                      if(checkOut1.length > 1) { // If time format correct
                        checkOut1 = checkOut1.slice (1); // Remove full string match value

                              if(checkOut1[0] > 13 && checkOut1[0] <= 17) {
                                  temp_time = individual_log.time;

                                  bs2 = "18:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3_undertime = hours + ":" + minutes + ":" + seconds;
                              }
                              if(checkOut1[0] > 13 && checkOut1[0] <= 16) {
                                  temp_time = individual_log.time;

                                  bs2 = "17:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3 = hours + ":" + minutes + ":" + seconds;
                              }

                              if(checkOut1[0] > 13 && checkOut1[0] <= 15) {
                                  temp_time = individual_log.time;

                                  bs2 = "16:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3_undertime_kuyaDon = hours + ":" + minutes + ":" + seconds;
                              }
                          }
                   }
          }


          if(individual_log.inOutStatus === 'Check-Out') {
                var temp_time = "00:00:00";
                checkOut = individual_log.time;
                checkOut = checkOut.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [checkOut];
                if(checkOut.length > 1) { // If time format correct
                      checkOut = checkOut.slice (1); // Remove full string match value

                      if(checkOut[0] < 12) {
                          if(checkOut[0] > 8 && checkOut[0] <= 11) {
                              temp_time = individual_log.time;
                              bs3 = "12:00:00"
                              e3 = new Date(d + " " + temp_time);
                              s3 = new Date(d + " " + bs3);
                              dif3 = Math.abs(s3 - e3);

                              var seconds = Math.floor((dif3 / 1000) % 60),
                              minutes = Math.floor((dif3 / (1000 * 60)) % 60),
                              hours = Math.floor((dif3 / (1000 * 60 * 60)) % 24);

                              hours = (hours < 10) ? "0" + hours : hours;
                              minutes = (minutes < 10) ? "0" + minutes : minutes;
                              seconds = (seconds < 10) ? "0" + seconds : seconds;

                              r4 = hours + ":" + minutes + ":" + seconds;

                          }

                      }
                }
               
                                     
          }
    
          x_flexi = moment.duration(r1_flexi).add(moment.duration(r2)).add(moment.duration(r3_undertime)).add(moment.duration(r4))
              moment.utc(x_flexi.as('milliseconds')).format("HH:mm:ss")

          x_flexi_kuyaDon = moment.duration(r1_flexi_kuyaDon).add(moment.duration(r2)).add(moment.duration(r3_undertime_kuyaDon)).add(moment.duration(r4))
              moment.utc(x_flexi_kuyaDon.as('milliseconds')).format("HH:mm:ss")
           
          x = moment.duration(r1).add(moment.duration(r2)).add(moment.duration(r3)).add(moment.duration(r4))
              moment.utc(x.as('milliseconds')).format("HH:mm:ss")

          d13 = x;
          d13_flexi = x_flexi;  
          d13_flexi_kuyaDon = x_flexi_kuyaDon;     
        }
      });
      if(this.$route.params.pID == 15 || this.$route.params.pID == 37 || this.$route.params.pID == 13 || this.$route.params.pID == 1) {
         return r3_undertime;
      }
      if(this.$route.params.pID == 23) {
        return x_flexi_kuyaDon;
      }
      return x;
    },
    checkUnderTime14(date1) {
      var dt = new Date(date1), d = moment().format('L'), i = 0, j = -1, x = "", x_flexi = "", x_flexi_kuyaDon = "";
      let checkIn = "00:00:00", checkIn1 = "00:00:00", checkOut = "00:00:00", checkOut1 = "00:00:00", dif = "", dif1 = "", dif2 = "", dif3 = "", s = "", e = "", s1 = "", s2 = "", s3 = "", e1 = "", e2 = "", e3 = "", bs = "", bs1 = "", bs2 = "", bs3 = "", r1 = "00:00:00", r1_flexi = "00:00:00", r1_flexi_kuyaDon = "00:00:00", r2 = "00:00:00", r3 = "00:00:00", r4 = "00:00:00", r3_undertime = "00:00:00", r3_undertime_kuyaDon = "00:00:00";


      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }

      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 
      
      this.individual_logs.forEach(individual_log => {
        if(individual_log.date === date1) {
      
            if(individual_log.inOutStatus === 'Check-In') {
              if(this.$route.params.pID == 15) {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "9:00:00" || checkIn > "09:00:00") {
                        bs = "9:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var seconds = Math.floor((dif / 1000) % 60),
                        minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;
                        seconds = (seconds < 10) ? "0" + seconds : seconds;

                        r1_flexi = hours + ":" + minutes + ":" + seconds;
                      } 

                    }
              }

              if(this.$route.params.pID == 23) {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "7:00:00" || checkIn > "07:00:00") {
                        bs = "7:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var seconds = Math.floor((dif / 1000) % 60),
                        minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;
                        seconds = (seconds < 10) ? "0" + seconds : seconds;

                        r1_flexi_kuyaDon = hours + ":" + minutes + ":" + seconds;
                      } 

                    }
              }
                if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                  checkIn = individual_log.time;  
                  if(checkIn > "8:00:00" || checkIn > "08:00:00") {
                    bs = "8:00:00"
                    e = new Date(d + " " + checkIn);
                    s = new Date(d + " " + bs);
                    dif = Math.abs(e - s);

                    
                  var seconds = Math.floor((dif / 1000) % 60),
                    minutes = Math.floor((dif / (1000 * 60)) % 60),
                    hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                    hours = (hours < 10) ? "0" + hours : hours;
                    minutes = (minutes < 10) ? "0" + minutes : minutes;
                    seconds = (seconds < 10) ? "0" + seconds : seconds;

                    r1 = hours + ":" + minutes + ":" + seconds;
                  } 

                }
  
            }

          if(i !== 2) {

            if(individual_log.inOutStatus === 'Check-In') {
                if((individual_log.time > "12:00:00" && individual_log.time <= "13:00:00") || individual_log.time < "17:00:00") {
                checkIn1 = individual_log.time;
                if(checkIn1 > "13:00:00" || (checkIn1 > "13:00:00" && checkIn1 <= "17:00:00")) {
                  bs1 = "13:00:00"
                  e1 = new Date(d + " " + checkIn1);
                  s1 = new Date(d + " " + bs1);
                  dif1 = Math.abs(e1 - s1);

                  var seconds = Math.floor((dif1 / 1000) % 60),
                  minutes = Math.floor((dif1 / (1000 * 60)) % 60),
                  hours = Math.floor((dif1 / (1000 * 60 * 60)) % 24);

                  hours = (hours < 10) ? "0" + hours : hours;
                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                  r2 = hours + ":" + minutes + ":" + seconds;
                } 

              }
            }
            i++;
          }



      
          if(individual_log.inOutStatus === 'Check-Out') {
                  var temp_time = "00:00:00";
                  if(individual_log.time > '13:00:00' || (individual_log.time > '13:00:00' && individual_log.time <= '24:00:00')) {
                                checkOut1 = individual_log.time;
                                checkOut1 = checkOut1.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [checkOut1];

                      if(checkOut1.length > 1) { // If time format correct
                        checkOut1 = checkOut1.slice (1); // Remove full string match value

                              if(checkOut1[0] > 13 && checkOut1[0] <= 17) {
                                  temp_time = individual_log.time;

                                  bs2 = "18:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3_undertime = hours + ":" + minutes + ":" + seconds;
                              }
                              if(checkOut1[0] > 13 && checkOut1[0] <= 16) {
                                  temp_time = individual_log.time;

                                  bs2 = "17:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3 = hours + ":" + minutes + ":" + seconds;
                              }

                              if(checkOut1[0] > 13 && checkOut1[0] <= 15) {
                                  temp_time = individual_log.time;

                                  bs2 = "16:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3_undertime_kuyaDon = hours + ":" + minutes + ":" + seconds;
                              }
                          }
                   }
          }


          if(individual_log.inOutStatus === 'Check-Out') {
                var temp_time = "00:00:00";
                checkOut = individual_log.time;
                checkOut = checkOut.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [checkOut];
                if(checkOut.length > 1) { // If time format correct
                      checkOut = checkOut.slice (1); // Remove full string match value

                      if(checkOut[0] < 12) {
                          if(checkOut[0] > 8 && checkOut[0] <= 11) {
                              temp_time = individual_log.time;
                              bs3 = "12:00:00"
                              e3 = new Date(d + " " + temp_time);
                              s3 = new Date(d + " " + bs3);
                              dif3 = Math.abs(s3 - e3);

                              var seconds = Math.floor((dif3 / 1000) % 60),
                              minutes = Math.floor((dif3 / (1000 * 60)) % 60),
                              hours = Math.floor((dif3 / (1000 * 60 * 60)) % 24);

                              hours = (hours < 10) ? "0" + hours : hours;
                              minutes = (minutes < 10) ? "0" + minutes : minutes;
                              seconds = (seconds < 10) ? "0" + seconds : seconds;

                              r4 = hours + ":" + minutes + ":" + seconds;

                          }

                      }
                }
               
                                     
          }
    
          x_flexi = moment.duration(r1_flexi).add(moment.duration(r2)).add(moment.duration(r3_undertime)).add(moment.duration(r4))
              moment.utc(x_flexi.as('milliseconds')).format("HH:mm:ss")

          x_flexi_kuyaDon = moment.duration(r1_flexi_kuyaDon).add(moment.duration(r2)).add(moment.duration(r3_undertime_kuyaDon)).add(moment.duration(r4))
              moment.utc(x_flexi_kuyaDon.as('milliseconds')).format("HH:mm:ss")
           
          x = moment.duration(r1).add(moment.duration(r2)).add(moment.duration(r3)).add(moment.duration(r4))
              moment.utc(x.as('milliseconds')).format("HH:mm:ss")

          d14 = x;
          d14_flexi = x_flexi;  
          d14_flexi_kuyaDon = x_flexi_kuyaDon;     
        }
      });
      if(this.$route.params.pID == 15 || this.$route.params.pID == 37 || this.$route.params.pID == 13 || this.$route.params.pID == 1) {
         return r3_undertime;
      }
      if(this.$route.params.pID == 23) {
        return x_flexi_kuyaDon;
      }
      return x;
    },
    checkUnderTime15(date1) {
      var dt = new Date(date1), d = moment().format('L'), i = 0, j = -1, x = "", x_flexi = "", x_flexi_kuyaDon = "";
      let checkIn = "00:00:00", checkIn1 = "00:00:00", checkOut = "00:00:00", checkOut1 = "00:00:00", dif = "", dif1 = "", dif2 = "", dif3 = "", s = "", e = "", s1 = "", s2 = "", s3 = "", e1 = "", e2 = "", e3 = "", bs = "", bs1 = "", bs2 = "", bs3 = "", r1 = "00:00:00", r1_flexi = "00:00:00", r1_flexi_kuyaDon = "00:00:00", r2 = "00:00:00", r3 = "00:00:00", r4 = "00:00:00", r3_undertime = "00:00:00", r3_undertime_kuyaDon = "00:00:00";


      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }

      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 
      
      this.individual_logs.forEach(individual_log => {
        if(individual_log.date === date1) {
      
            if(individual_log.inOutStatus === 'Check-In') {
              if(this.$route.params.pID == 15) {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "9:00:00" || checkIn > "09:00:00") {
                        bs = "9:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var seconds = Math.floor((dif / 1000) % 60),
                        minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;
                        seconds = (seconds < 10) ? "0" + seconds : seconds;

                        r1_flexi = hours + ":" + minutes + ":" + seconds;
                      } 

                    }
              }

              if(this.$route.params.pID == 23) {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "7:00:00" || checkIn > "07:00:00") {
                        bs = "7:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var seconds = Math.floor((dif / 1000) % 60),
                        minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;
                        seconds = (seconds < 10) ? "0" + seconds : seconds;

                        r1_flexi_kuyaDon = hours + ":" + minutes + ":" + seconds;
                      } 

                    }
              }
                if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                  checkIn = individual_log.time;  
                  if(checkIn > "8:00:00" || checkIn > "08:00:00") {
                    bs = "8:00:00"
                    e = new Date(d + " " + checkIn);
                    s = new Date(d + " " + bs);
                    dif = Math.abs(e - s);

                    
                  var seconds = Math.floor((dif / 1000) % 60),
                    minutes = Math.floor((dif / (1000 * 60)) % 60),
                    hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                    hours = (hours < 10) ? "0" + hours : hours;
                    minutes = (minutes < 10) ? "0" + minutes : minutes;
                    seconds = (seconds < 10) ? "0" + seconds : seconds;

                    r1 = hours + ":" + minutes + ":" + seconds;
                  } 

                }
  
            }

          if(i !== 2) {

            if(individual_log.inOutStatus === 'Check-In') {
                if((individual_log.time > "12:00:00" && individual_log.time <= "13:00:00") || individual_log.time < "17:00:00") {
                checkIn1 = individual_log.time;
                if(checkIn1 > "13:00:00" || (checkIn1 > "13:00:00" && checkIn1 <= "17:00:00")) {
                  bs1 = "13:00:00"
                  e1 = new Date(d + " " + checkIn1);
                  s1 = new Date(d + " " + bs1);
                  dif1 = Math.abs(e1 - s1);

                  var seconds = Math.floor((dif1 / 1000) % 60),
                  minutes = Math.floor((dif1 / (1000 * 60)) % 60),
                  hours = Math.floor((dif1 / (1000 * 60 * 60)) % 24);

                  hours = (hours < 10) ? "0" + hours : hours;
                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                  r2 = hours + ":" + minutes + ":" + seconds;
                } 

              }
            }
            i++;
          }



      
          if(individual_log.inOutStatus === 'Check-Out') {
                  var temp_time = "00:00:00";
                  if(individual_log.time > '13:00:00' || (individual_log.time > '13:00:00' && individual_log.time <= '24:00:00')) {
                                checkOut1 = individual_log.time;
                                checkOut1 = checkOut1.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [checkOut1];

                      if(checkOut1.length > 1) { // If time format correct
                        checkOut1 = checkOut1.slice (1); // Remove full string match value

                              if(checkOut1[0] > 13 && checkOut1[0] <= 17) {
                                  temp_time = individual_log.time;

                                  bs2 = "18:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3_undertime = hours + ":" + minutes + ":" + seconds;
                              }
                              if(checkOut1[0] > 13 && checkOut1[0] <= 16) {
                                  temp_time = individual_log.time;

                                  bs2 = "17:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3 = hours + ":" + minutes + ":" + seconds;
                              }

                              if(checkOut1[0] > 13 && checkOut1[0] <= 15) {
                                  temp_time = individual_log.time;

                                  bs2 = "16:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3_undertime_kuyaDon = hours + ":" + minutes + ":" + seconds;
                              }
                          }
                   }
          }


          if(individual_log.inOutStatus === 'Check-Out') {
                var temp_time = "00:00:00";
                checkOut = individual_log.time;
                checkOut = checkOut.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [checkOut];
                if(checkOut.length > 1) { // If time format correct
                      checkOut = checkOut.slice (1); // Remove full string match value

                      if(checkOut[0] < 12) {
                          if(checkOut[0] > 8 && checkOut[0] <= 11) {
                              temp_time = individual_log.time;
                              bs3 = "12:00:00"
                              e3 = new Date(d + " " + temp_time);
                              s3 = new Date(d + " " + bs3);
                              dif3 = Math.abs(s3 - e3);

                              var seconds = Math.floor((dif3 / 1000) % 60),
                              minutes = Math.floor((dif3 / (1000 * 60)) % 60),
                              hours = Math.floor((dif3 / (1000 * 60 * 60)) % 24);

                              hours = (hours < 10) ? "0" + hours : hours;
                              minutes = (minutes < 10) ? "0" + minutes : minutes;
                              seconds = (seconds < 10) ? "0" + seconds : seconds;

                              r4 = hours + ":" + minutes + ":" + seconds;

                          }

                      }
                }
               
                                     
          }
    
          x_flexi = moment.duration(r1_flexi).add(moment.duration(r2)).add(moment.duration(r3_undertime)).add(moment.duration(r4))
              moment.utc(x_flexi.as('milliseconds')).format("HH:mm:ss")

          x_flexi_kuyaDon = moment.duration(r1_flexi_kuyaDon).add(moment.duration(r2)).add(moment.duration(r3_undertime_kuyaDon)).add(moment.duration(r4))
              moment.utc(x_flexi_kuyaDon.as('milliseconds')).format("HH:mm:ss")
           
          x = moment.duration(r1).add(moment.duration(r2)).add(moment.duration(r3)).add(moment.duration(r4))
              moment.utc(x.as('milliseconds')).format("HH:mm:ss")

          d15 = x;
          d15_flexi = x_flexi;  
          d15_flexi_kuyaDon = x_flexi_kuyaDon;     
        }
      });
      if(this.$route.params.pID == 15 || this.$route.params.pID == 37 || this.$route.params.pID == 13 || this.$route.params.pID == 1) {
         return r3_undertime;
      }
      if(this.$route.params.pID == 23) {
        return x_flexi_kuyaDon;
      }
      return x;
    },
    checkUnderTime16(date1) {
      var dt = new Date(date1), d = moment().format('L'), i = 0, j = -1, x = "", x_flexi = "", x_flexi_kuyaDon = "";
      let checkIn = "00:00:00", checkIn1 = "00:00:00", checkOut = "00:00:00", checkOut1 = "00:00:00", dif = "", dif1 = "", dif2 = "", dif3 = "", s = "", e = "", s1 = "", s2 = "", s3 = "", e1 = "", e2 = "", e3 = "", bs = "", bs1 = "", bs2 = "", bs3 = "", r1 = "00:00:00", r1_flexi = "00:00:00", r1_flexi_kuyaDon = "00:00:00", r2 = "00:00:00", r3 = "00:00:00", r4 = "00:00:00", r3_undertime = "00:00:00", r3_undertime_kuyaDon = "00:00:00";


      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }

      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 
      
      this.individual_logs.forEach(individual_log => {
        if(individual_log.date === date1) {
      
            if(individual_log.inOutStatus === 'Check-In') {
              if(this.$route.params.pID == 15) {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "9:00:00" || checkIn > "09:00:00") {
                        bs = "9:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var seconds = Math.floor((dif / 1000) % 60),
                        minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;
                        seconds = (seconds < 10) ? "0" + seconds : seconds;

                        r1_flexi = hours + ":" + minutes + ":" + seconds;
                      } 

                    }
              }

              if(this.$route.params.pID == 23) {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "7:00:00" || checkIn > "07:00:00") {
                        bs = "7:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var seconds = Math.floor((dif / 1000) % 60),
                        minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;
                        seconds = (seconds < 10) ? "0" + seconds : seconds;

                        r1_flexi_kuyaDon = hours + ":" + minutes + ":" + seconds;
                      } 

                    }
              }
                if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                  checkIn = individual_log.time;  
                  if(checkIn > "8:00:00" || checkIn > "08:00:00") {
                    bs = "8:00:00"
                    e = new Date(d + " " + checkIn);
                    s = new Date(d + " " + bs);
                    dif = Math.abs(e - s);

                    
                  var seconds = Math.floor((dif / 1000) % 60),
                    minutes = Math.floor((dif / (1000 * 60)) % 60),
                    hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                    hours = (hours < 10) ? "0" + hours : hours;
                    minutes = (minutes < 10) ? "0" + minutes : minutes;
                    seconds = (seconds < 10) ? "0" + seconds : seconds;

                    r1 = hours + ":" + minutes + ":" + seconds;
                  } 

                }
  
            }

          if(i !== 2) {

            if(individual_log.inOutStatus === 'Check-In') {
                if((individual_log.time > "12:00:00" && individual_log.time <= "13:00:00") || individual_log.time < "17:00:00") {
                checkIn1 = individual_log.time;
                if(checkIn1 > "13:00:00" || (checkIn1 > "13:00:00" && checkIn1 <= "17:00:00")) {
                  bs1 = "13:00:00"
                  e1 = new Date(d + " " + checkIn1);
                  s1 = new Date(d + " " + bs1);
                  dif1 = Math.abs(e1 - s1);

                  var seconds = Math.floor((dif1 / 1000) % 60),
                  minutes = Math.floor((dif1 / (1000 * 60)) % 60),
                  hours = Math.floor((dif1 / (1000 * 60 * 60)) % 24);

                  hours = (hours < 10) ? "0" + hours : hours;
                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                  r2 = hours + ":" + minutes + ":" + seconds;
                } 

              }
            }
            i++;
          }



      
          if(individual_log.inOutStatus === 'Check-Out') {
                  var temp_time = "00:00:00";
                  if(individual_log.time > '13:00:00' || (individual_log.time > '13:00:00' && individual_log.time <= '24:00:00')) {
                                checkOut1 = individual_log.time;
                                checkOut1 = checkOut1.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [checkOut1];

                      if(checkOut1.length > 1) { // If time format correct
                        checkOut1 = checkOut1.slice (1); // Remove full string match value

                              if(checkOut1[0] > 13 && checkOut1[0] <= 17) {
                                  temp_time = individual_log.time;

                                  bs2 = "18:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3_undertime = hours + ":" + minutes + ":" + seconds;
                              }
                              if(checkOut1[0] > 13 && checkOut1[0] <= 16) {
                                  temp_time = individual_log.time;

                                  bs2 = "17:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3 = hours + ":" + minutes + ":" + seconds;
                              }

                              if(checkOut1[0] > 13 && checkOut1[0] <= 15) {
                                  temp_time = individual_log.time;

                                  bs2 = "16:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3_undertime_kuyaDon = hours + ":" + minutes + ":" + seconds;
                              }
                          }
                   }
          }


          if(individual_log.inOutStatus === 'Check-Out') {
                var temp_time = "00:00:00";
                checkOut = individual_log.time;
                checkOut = checkOut.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [checkOut];
                if(checkOut.length > 1) { // If time format correct
                      checkOut = checkOut.slice (1); // Remove full string match value

                      if(checkOut[0] < 12) {
                          if(checkOut[0] > 8 && checkOut[0] <= 11) {
                              temp_time = individual_log.time;
                              bs3 = "12:00:00"
                              e3 = new Date(d + " " + temp_time);
                              s3 = new Date(d + " " + bs3);
                              dif3 = Math.abs(s3 - e3);

                              var seconds = Math.floor((dif3 / 1000) % 60),
                              minutes = Math.floor((dif3 / (1000 * 60)) % 60),
                              hours = Math.floor((dif3 / (1000 * 60 * 60)) % 24);

                              hours = (hours < 10) ? "0" + hours : hours;
                              minutes = (minutes < 10) ? "0" + minutes : minutes;
                              seconds = (seconds < 10) ? "0" + seconds : seconds;

                              r4 = hours + ":" + minutes + ":" + seconds;

                          }

                      }
                }
               
                                     
          }
    
          x_flexi = moment.duration(r1_flexi).add(moment.duration(r2)).add(moment.duration(r3_undertime)).add(moment.duration(r4))
              moment.utc(x_flexi.as('milliseconds')).format("HH:mm:ss")

          x_flexi_kuyaDon = moment.duration(r1_flexi_kuyaDon).add(moment.duration(r2)).add(moment.duration(r3_undertime_kuyaDon)).add(moment.duration(r4))
              moment.utc(x_flexi_kuyaDon.as('milliseconds')).format("HH:mm:ss")
           
          x = moment.duration(r1).add(moment.duration(r2)).add(moment.duration(r3)).add(moment.duration(r4))
              moment.utc(x.as('milliseconds')).format("HH:mm:ss")

          d16 = x;
          d16_flexi = x_flexi;  
          d16_flexi_kuyaDon = x_flexi_kuyaDon;     
        }
      });
      if(this.$route.params.pID == 15 || this.$route.params.pID == 37 || this.$route.params.pID == 13 || this.$route.params.pID == 1) {
         return r3_undertime;
      }
      if(this.$route.params.pID == 23) {
        return x_flexi_kuyaDon;
      }
      return x;
    },
    checkUnderTime17(date1) {
      var dt = new Date(date1), d = moment().format('L'), i = 0, j = -1, x = "", x_flexi = "", x_flexi_kuyaDon = "";
      let checkIn = "00:00:00", checkIn1 = "00:00:00", checkOut = "00:00:00", checkOut1 = "00:00:00", dif = "", dif1 = "", dif2 = "", dif3 = "", s = "", e = "", s1 = "", s2 = "", s3 = "", e1 = "", e2 = "", e3 = "", bs = "", bs1 = "", bs2 = "", bs3 = "", r1 = "00:00:00", r1_flexi = "00:00:00", r1_flexi_kuyaDon = "00:00:00", r2 = "00:00:00", r3 = "00:00:00", r4 = "00:00:00", r3_undertime = "00:00:00", r3_undertime_kuyaDon = "00:00:00";


      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }

      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 
      
      this.individual_logs.forEach(individual_log => {
        if(individual_log.date === date1) {
      
            if(individual_log.inOutStatus === 'Check-In') {
              if(this.$route.params.pID == 15) {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "9:00:00" || checkIn > "09:00:00") {
                        bs = "9:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var seconds = Math.floor((dif / 1000) % 60),
                        minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;
                        seconds = (seconds < 10) ? "0" + seconds : seconds;

                        r1_flexi = hours + ":" + minutes + ":" + seconds;
                      } 

                    }
              }

              if(this.$route.params.pID == 23) {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "7:00:00" || checkIn > "07:00:00") {
                        bs = "7:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var seconds = Math.floor((dif / 1000) % 60),
                        minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;
                        seconds = (seconds < 10) ? "0" + seconds : seconds;

                        r1_flexi_kuyaDon = hours + ":" + minutes + ":" + seconds;
                      } 

                    }
              }
                if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                  checkIn = individual_log.time;  
                  if(checkIn > "8:00:00" || checkIn > "08:00:00") {
                    bs = "8:00:00"
                    e = new Date(d + " " + checkIn);
                    s = new Date(d + " " + bs);
                    dif = Math.abs(e - s);

                    
                  var seconds = Math.floor((dif / 1000) % 60),
                    minutes = Math.floor((dif / (1000 * 60)) % 60),
                    hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                    hours = (hours < 10) ? "0" + hours : hours;
                    minutes = (minutes < 10) ? "0" + minutes : minutes;
                    seconds = (seconds < 10) ? "0" + seconds : seconds;

                    r1 = hours + ":" + minutes + ":" + seconds;
                  } 

                }
  
            }

          if(i !== 2) {

            if(individual_log.inOutStatus === 'Check-In') {
                if((individual_log.time > "12:00:00" && individual_log.time <= "13:00:00") || individual_log.time < "17:00:00") {
                checkIn1 = individual_log.time;
                if(checkIn1 > "13:00:00" || (checkIn1 > "13:00:00" && checkIn1 <= "17:00:00")) {
                  bs1 = "13:00:00"
                  e1 = new Date(d + " " + checkIn1);
                  s1 = new Date(d + " " + bs1);
                  dif1 = Math.abs(e1 - s1);

                  var seconds = Math.floor((dif1 / 1000) % 60),
                  minutes = Math.floor((dif1 / (1000 * 60)) % 60),
                  hours = Math.floor((dif1 / (1000 * 60 * 60)) % 24);

                  hours = (hours < 10) ? "0" + hours : hours;
                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                  r2 = hours + ":" + minutes + ":" + seconds;
                } 

              }
            }
            i++;
          }



      
          if(individual_log.inOutStatus === 'Check-Out') {
                  var temp_time = "00:00:00";
                  if(individual_log.time > '13:00:00' || (individual_log.time > '13:00:00' && individual_log.time <= '24:00:00')) {
                                checkOut1 = individual_log.time;
                                checkOut1 = checkOut1.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [checkOut1];

                      if(checkOut1.length > 1) { // If time format correct
                        checkOut1 = checkOut1.slice (1); // Remove full string match value

                              if(checkOut1[0] > 13 && checkOut1[0] <= 17) {
                                  temp_time = individual_log.time;

                                  bs2 = "18:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3_undertime = hours + ":" + minutes + ":" + seconds;
                              }
                              if(checkOut1[0] > 13 && checkOut1[0] <= 16) {
                                  temp_time = individual_log.time;

                                  bs2 = "17:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3 = hours + ":" + minutes + ":" + seconds;
                              }

                              if(checkOut1[0] > 13 && checkOut1[0] <= 15) {
                                  temp_time = individual_log.time;

                                  bs2 = "16:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3_undertime_kuyaDon = hours + ":" + minutes + ":" + seconds;
                              }
                          }
                   }
          }


          if(individual_log.inOutStatus === 'Check-Out') {
                var temp_time = "00:00:00";
                checkOut = individual_log.time;
                checkOut = checkOut.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [checkOut];
                if(checkOut.length > 1) { // If time format correct
                      checkOut = checkOut.slice (1); // Remove full string match value

                      if(checkOut[0] < 12) {
                          if(checkOut[0] > 8 && checkOut[0] <= 11) {
                              temp_time = individual_log.time;
                              bs3 = "12:00:00"
                              e3 = new Date(d + " " + temp_time);
                              s3 = new Date(d + " " + bs3);
                              dif3 = Math.abs(s3 - e3);

                              var seconds = Math.floor((dif3 / 1000) % 60),
                              minutes = Math.floor((dif3 / (1000 * 60)) % 60),
                              hours = Math.floor((dif3 / (1000 * 60 * 60)) % 24);

                              hours = (hours < 10) ? "0" + hours : hours;
                              minutes = (minutes < 10) ? "0" + minutes : minutes;
                              seconds = (seconds < 10) ? "0" + seconds : seconds;

                              r4 = hours + ":" + minutes + ":" + seconds;

                          }

                      }
                }
               
                                     
          }
    
          x_flexi = moment.duration(r1_flexi).add(moment.duration(r2)).add(moment.duration(r3_undertime)).add(moment.duration(r4))
              moment.utc(x_flexi.as('milliseconds')).format("HH:mm:ss")

          x_flexi_kuyaDon = moment.duration(r1_flexi_kuyaDon).add(moment.duration(r2)).add(moment.duration(r3_undertime_kuyaDon)).add(moment.duration(r4))
              moment.utc(x_flexi_kuyaDon.as('milliseconds')).format("HH:mm:ss")
           
          x = moment.duration(r1).add(moment.duration(r2)).add(moment.duration(r3)).add(moment.duration(r4))
              moment.utc(x.as('milliseconds')).format("HH:mm:ss")

          d17 = x;
          d17_flexi = x_flexi;  
          d17_flexi_kuyaDon = x_flexi_kuyaDon;     
        }
      });
      if(this.$route.params.pID == 15 || this.$route.params.pID == 37 || this.$route.params.pID == 13 || this.$route.params.pID == 1) {
         return r3_undertime;
      }
      if(this.$route.params.pID == 23) {
        return x_flexi_kuyaDon;
      }
      return x;
    },
    checkUnderTime18(date1) {
      var dt = new Date(date1), d = moment().format('L'), i = 0, j = -1, x = "", x_flexi = "", x_flexi_kuyaDon = "";
      let checkIn = "00:00:00", checkIn1 = "00:00:00", checkOut = "00:00:00", checkOut1 = "00:00:00", dif = "", dif1 = "", dif2 = "", dif3 = "", s = "", e = "", s1 = "", s2 = "", s3 = "", e1 = "", e2 = "", e3 = "", bs = "", bs1 = "", bs2 = "", bs3 = "", r1 = "00:00:00", r1_flexi = "00:00:00", r1_flexi_kuyaDon = "00:00:00", r2 = "00:00:00", r3 = "00:00:00", r4 = "00:00:00", r3_undertime = "00:00:00", r3_undertime_kuyaDon = "00:00:00";


      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }

      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 
      
      this.individual_logs.forEach(individual_log => {
        if(individual_log.date === date1) {
      
            if(individual_log.inOutStatus === 'Check-In') {
              if(this.$route.params.pID == 15) {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "9:00:00" || checkIn > "09:00:00") {
                        bs = "9:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var seconds = Math.floor((dif / 1000) % 60),
                        minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;
                        seconds = (seconds < 10) ? "0" + seconds : seconds;

                        r1_flexi = hours + ":" + minutes + ":" + seconds;
                      } 

                    }
              }

              if(this.$route.params.pID == 23) {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "7:00:00" || checkIn > "07:00:00") {
                        bs = "7:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var seconds = Math.floor((dif / 1000) % 60),
                        minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;
                        seconds = (seconds < 10) ? "0" + seconds : seconds;

                        r1_flexi_kuyaDon = hours + ":" + minutes + ":" + seconds;
                      } 

                    }
              }
                if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                  checkIn = individual_log.time;  
                  if(checkIn > "8:00:00" || checkIn > "08:00:00") {
                    bs = "8:00:00"
                    e = new Date(d + " " + checkIn);
                    s = new Date(d + " " + bs);
                    dif = Math.abs(e - s);

                    
                  var seconds = Math.floor((dif / 1000) % 60),
                    minutes = Math.floor((dif / (1000 * 60)) % 60),
                    hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                    hours = (hours < 10) ? "0" + hours : hours;
                    minutes = (minutes < 10) ? "0" + minutes : minutes;
                    seconds = (seconds < 10) ? "0" + seconds : seconds;

                    r1 = hours + ":" + minutes + ":" + seconds;
                  } 

                }
  
            }

          if(i !== 2) {

            if(individual_log.inOutStatus === 'Check-In') {
                if((individual_log.time > "12:00:00" && individual_log.time <= "13:00:00") || individual_log.time < "17:00:00") {
                checkIn1 = individual_log.time;
                if(checkIn1 > "13:00:00" || (checkIn1 > "13:00:00" && checkIn1 <= "17:00:00")) {
                  bs1 = "13:00:00"
                  e1 = new Date(d + " " + checkIn1);
                  s1 = new Date(d + " " + bs1);
                  dif1 = Math.abs(e1 - s1);

                  var seconds = Math.floor((dif1 / 1000) % 60),
                  minutes = Math.floor((dif1 / (1000 * 60)) % 60),
                  hours = Math.floor((dif1 / (1000 * 60 * 60)) % 24);

                  hours = (hours < 10) ? "0" + hours : hours;
                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                  r2 = hours + ":" + minutes + ":" + seconds;
                } 

              }
            }
            i++;
          }



      
          if(individual_log.inOutStatus === 'Check-Out') {
                  var temp_time = "00:00:00";
                  if(individual_log.time > '13:00:00' || (individual_log.time > '13:00:00' && individual_log.time <= '24:00:00')) {
                                checkOut1 = individual_log.time;
                                checkOut1 = checkOut1.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [checkOut1];

                      if(checkOut1.length > 1) { // If time format correct
                        checkOut1 = checkOut1.slice (1); // Remove full string match value

                              if(checkOut1[0] > 13 && checkOut1[0] <= 17) {
                                  temp_time = individual_log.time;

                                  bs2 = "18:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3_undertime = hours + ":" + minutes + ":" + seconds;
                              }
                              if(checkOut1[0] > 13 && checkOut1[0] <= 16) {
                                  temp_time = individual_log.time;

                                  bs2 = "17:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3 = hours + ":" + minutes + ":" + seconds;
                              }

                              if(checkOut1[0] > 13 && checkOut1[0] <= 15) {
                                  temp_time = individual_log.time;

                                  bs2 = "16:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3_undertime_kuyaDon = hours + ":" + minutes + ":" + seconds;
                              }
                          }
                   }
          }


          if(individual_log.inOutStatus === 'Check-Out') {
                var temp_time = "00:00:00";
                checkOut = individual_log.time;
                checkOut = checkOut.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [checkOut];
                if(checkOut.length > 1) { // If time format correct
                      checkOut = checkOut.slice (1); // Remove full string match value

                      if(checkOut[0] < 12) {
                          if(checkOut[0] > 8 && checkOut[0] <= 11) {
                              temp_time = individual_log.time;
                              bs3 = "12:00:00"
                              e3 = new Date(d + " " + temp_time);
                              s3 = new Date(d + " " + bs3);
                              dif3 = Math.abs(s3 - e3);

                              var seconds = Math.floor((dif3 / 1000) % 60),
                              minutes = Math.floor((dif3 / (1000 * 60)) % 60),
                              hours = Math.floor((dif3 / (1000 * 60 * 60)) % 24);

                              hours = (hours < 10) ? "0" + hours : hours;
                              minutes = (minutes < 10) ? "0" + minutes : minutes;
                              seconds = (seconds < 10) ? "0" + seconds : seconds;

                              r4 = hours + ":" + minutes + ":" + seconds;

                          }

                      }
                }
               
                                     
          }
    
          x_flexi = moment.duration(r1_flexi).add(moment.duration(r2)).add(moment.duration(r3_undertime)).add(moment.duration(r4))
              moment.utc(x_flexi.as('milliseconds')).format("HH:mm:ss")

          x_flexi_kuyaDon = moment.duration(r1_flexi_kuyaDon).add(moment.duration(r2)).add(moment.duration(r3_undertime_kuyaDon)).add(moment.duration(r4))
              moment.utc(x_flexi_kuyaDon.as('milliseconds')).format("HH:mm:ss")
           
          x = moment.duration(r1).add(moment.duration(r2)).add(moment.duration(r3)).add(moment.duration(r4))
              moment.utc(x.as('milliseconds')).format("HH:mm:ss")

          d18 = x;
          d18_flexi = x_flexi;  
          d18_flexi_kuyaDon = x_flexi_kuyaDon;     
        }
      });
      if(this.$route.params.pID == 15 || this.$route.params.pID == 37 || this.$route.params.pID == 13 || this.$route.params.pID == 1) {
         return r3_undertime;
      }
      if(this.$route.params.pID == 23) {
        return x_flexi_kuyaDon;
      }
      return x;
    },
    checkUnderTime19(date1) {
      var dt = new Date(date1), d = moment().format('L'), i = 0, j = -1, x = "", x_flexi = "", x_flexi_kuyaDon = "";
      let checkIn = "00:00:00", checkIn1 = "00:00:00", checkOut = "00:00:00", checkOut1 = "00:00:00", dif = "", dif1 = "", dif2 = "", dif3 = "", s = "", e = "", s1 = "", s2 = "", s3 = "", e1 = "", e2 = "", e3 = "", bs = "", bs1 = "", bs2 = "", bs3 = "", r1 = "00:00:00", r1_flexi = "00:00:00", r1_flexi_kuyaDon = "00:00:00", r2 = "00:00:00", r3 = "00:00:00", r4 = "00:00:00", r3_undertime = "00:00:00", r3_undertime_kuyaDon = "00:00:00";


      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }

      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 
      
      this.individual_logs.forEach(individual_log => {
        if(individual_log.date === date1) {
      
            if(individual_log.inOutStatus === 'Check-In') {
              if(this.$route.params.pID == 15) {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "9:00:00" || checkIn > "09:00:00") {
                        bs = "9:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var seconds = Math.floor((dif / 1000) % 60),
                        minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;
                        seconds = (seconds < 10) ? "0" + seconds : seconds;

                        r1_flexi = hours + ":" + minutes + ":" + seconds;
                      } 

                    }
              }

              if(this.$route.params.pID == 23) {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "7:00:00" || checkIn > "07:00:00") {
                        bs = "7:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var seconds = Math.floor((dif / 1000) % 60),
                        minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;
                        seconds = (seconds < 10) ? "0" + seconds : seconds;

                        r1_flexi_kuyaDon = hours + ":" + minutes + ":" + seconds;
                      } 

                    }
              }
                if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                  checkIn = individual_log.time;  
                  if(checkIn > "8:00:00" || checkIn > "08:00:00") {
                    bs = "8:00:00"
                    e = new Date(d + " " + checkIn);
                    s = new Date(d + " " + bs);
                    dif = Math.abs(e - s);

                    
                  var seconds = Math.floor((dif / 1000) % 60),
                    minutes = Math.floor((dif / (1000 * 60)) % 60),
                    hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                    hours = (hours < 10) ? "0" + hours : hours;
                    minutes = (minutes < 10) ? "0" + minutes : minutes;
                    seconds = (seconds < 10) ? "0" + seconds : seconds;

                    r1 = hours + ":" + minutes + ":" + seconds;
                  } 

                }
  
            }

          if(i !== 2) {

            if(individual_log.inOutStatus === 'Check-In') {
                if((individual_log.time > "12:00:00" && individual_log.time <= "13:00:00") || individual_log.time < "17:00:00") {
                checkIn1 = individual_log.time;
                if(checkIn1 > "13:00:00" || (checkIn1 > "13:00:00" && checkIn1 <= "17:00:00")) {
                  bs1 = "13:00:00"
                  e1 = new Date(d + " " + checkIn1);
                  s1 = new Date(d + " " + bs1);
                  dif1 = Math.abs(e1 - s1);

                  var seconds = Math.floor((dif1 / 1000) % 60),
                  minutes = Math.floor((dif1 / (1000 * 60)) % 60),
                  hours = Math.floor((dif1 / (1000 * 60 * 60)) % 24);

                  hours = (hours < 10) ? "0" + hours : hours;
                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                  r2 = hours + ":" + minutes + ":" + seconds;
                } 

              }
            }
            i++;
          }



      
          if(individual_log.inOutStatus === 'Check-Out') {
                  var temp_time = "00:00:00";
                  if(individual_log.time > '13:00:00' || (individual_log.time > '13:00:00' && individual_log.time <= '24:00:00')) {
                                checkOut1 = individual_log.time;
                                checkOut1 = checkOut1.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [checkOut1];

                      if(checkOut1.length > 1) { // If time format correct
                        checkOut1 = checkOut1.slice (1); // Remove full string match value

                              if(checkOut1[0] > 13 && checkOut1[0] <= 17) {
                                  temp_time = individual_log.time;

                                  bs2 = "18:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3_undertime = hours + ":" + minutes + ":" + seconds;
                              }
                              if(checkOut1[0] > 13 && checkOut1[0] <= 16) {
                                  temp_time = individual_log.time;

                                  bs2 = "17:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3 = hours + ":" + minutes + ":" + seconds;
                              }

                              if(checkOut1[0] > 13 && checkOut1[0] <= 15) {
                                  temp_time = individual_log.time;

                                  bs2 = "16:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3_undertime_kuyaDon = hours + ":" + minutes + ":" + seconds;
                              }
                          }
                   }
          }


          if(individual_log.inOutStatus === 'Check-Out') {
                var temp_time = "00:00:00";
                checkOut = individual_log.time;
                checkOut = checkOut.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [checkOut];
                if(checkOut.length > 1) { // If time format correct
                      checkOut = checkOut.slice (1); // Remove full string match value

                      if(checkOut[0] < 12) {
                          if(checkOut[0] > 8 && checkOut[0] <= 11) {
                              temp_time = individual_log.time;
                              bs3 = "12:00:00"
                              e3 = new Date(d + " " + temp_time);
                              s3 = new Date(d + " " + bs3);
                              dif3 = Math.abs(s3 - e3);

                              var seconds = Math.floor((dif3 / 1000) % 60),
                              minutes = Math.floor((dif3 / (1000 * 60)) % 60),
                              hours = Math.floor((dif3 / (1000 * 60 * 60)) % 24);

                              hours = (hours < 10) ? "0" + hours : hours;
                              minutes = (minutes < 10) ? "0" + minutes : minutes;
                              seconds = (seconds < 10) ? "0" + seconds : seconds;

                              r4 = hours + ":" + minutes + ":" + seconds;

                          }

                      }
                }
               
                                     
          }
    
          x_flexi = moment.duration(r1_flexi).add(moment.duration(r2)).add(moment.duration(r3_undertime)).add(moment.duration(r4))
              moment.utc(x_flexi.as('milliseconds')).format("HH:mm:ss")

          x_flexi_kuyaDon = moment.duration(r1_flexi_kuyaDon).add(moment.duration(r2)).add(moment.duration(r3_undertime_kuyaDon)).add(moment.duration(r4))
              moment.utc(x_flexi_kuyaDon.as('milliseconds')).format("HH:mm:ss")
           
          x = moment.duration(r1).add(moment.duration(r2)).add(moment.duration(r3)).add(moment.duration(r4))
              moment.utc(x.as('milliseconds')).format("HH:mm:ss")

          d19 = x;
          d19_flexi = x_flexi;  
          d19_flexi_kuyaDon = x_flexi_kuyaDon;     
        }
      });
      if(this.$route.params.pID == 15 || this.$route.params.pID == 37 || this.$route.params.pID == 13 || this.$route.params.pID == 1) {
         return r3_undertime;
      }
      if(this.$route.params.pID == 23) {
        return x_flexi_kuyaDon;
      }
      return x;
    },
    checkUnderTime20(date1) {
      var dt = new Date(date1), d = moment().format('L'), i = 0, j = -1, x = "", x_flexi = "", x_flexi_kuyaDon = "";
      let checkIn = "00:00:00", checkIn1 = "00:00:00", checkOut = "00:00:00", checkOut1 = "00:00:00", dif = "", dif1 = "", dif2 = "", dif3 = "", s = "", e = "", s1 = "", s2 = "", s3 = "", e1 = "", e2 = "", e3 = "", bs = "", bs1 = "", bs2 = "", bs3 = "", r1 = "00:00:00", r1_flexi = "00:00:00", r1_flexi_kuyaDon = "00:00:00", r2 = "00:00:00", r3 = "00:00:00", r4 = "00:00:00", r3_undertime = "00:00:00", r3_undertime_kuyaDon = "00:00:00";


      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }

      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 
      
      this.individual_logs.forEach(individual_log => {
        if(individual_log.date === date1) {
      
            if(individual_log.inOutStatus === 'Check-In') {
              if(this.$route.params.pID == 15) {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "9:00:00" || checkIn > "09:00:00") {
                        bs = "9:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var seconds = Math.floor((dif / 1000) % 60),
                        minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;
                        seconds = (seconds < 10) ? "0" + seconds : seconds;

                        r1_flexi = hours + ":" + minutes + ":" + seconds;
                      } 

                    }
              }

              if(this.$route.params.pID == 23) {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "7:00:00" || checkIn > "07:00:00") {
                        bs = "7:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var seconds = Math.floor((dif / 1000) % 60),
                        minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;
                        seconds = (seconds < 10) ? "0" + seconds : seconds;

                        r1_flexi_kuyaDon = hours + ":" + minutes + ":" + seconds;
                      } 

                    }
              }
                if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                  checkIn = individual_log.time;  
                  if(checkIn > "8:00:00" || checkIn > "08:00:00") {
                    bs = "8:00:00"
                    e = new Date(d + " " + checkIn);
                    s = new Date(d + " " + bs);
                    dif = Math.abs(e - s);

                    
                  var seconds = Math.floor((dif / 1000) % 60),
                    minutes = Math.floor((dif / (1000 * 60)) % 60),
                    hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                    hours = (hours < 10) ? "0" + hours : hours;
                    minutes = (minutes < 10) ? "0" + minutes : minutes;
                    seconds = (seconds < 10) ? "0" + seconds : seconds;

                    r1 = hours + ":" + minutes + ":" + seconds;
                  } 

                }
  
            }

          if(i !== 2) {

            if(individual_log.inOutStatus === 'Check-In') {
                if((individual_log.time > "12:00:00" && individual_log.time <= "13:00:00") || individual_log.time < "17:00:00") {
                checkIn1 = individual_log.time;
                if(checkIn1 > "13:00:00" || (checkIn1 > "13:00:00" && checkIn1 <= "17:00:00")) {
                  bs1 = "13:00:00"
                  e1 = new Date(d + " " + checkIn1);
                  s1 = new Date(d + " " + bs1);
                  dif1 = Math.abs(e1 - s1);

                  var seconds = Math.floor((dif1 / 1000) % 60),
                  minutes = Math.floor((dif1 / (1000 * 60)) % 60),
                  hours = Math.floor((dif1 / (1000 * 60 * 60)) % 24);

                  hours = (hours < 10) ? "0" + hours : hours;
                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                  r2 = hours + ":" + minutes + ":" + seconds;
                } 

              }
            }
            i++;
          }



      
          if(individual_log.inOutStatus === 'Check-Out') {
                  var temp_time = "00:00:00";
                  if(individual_log.time > '13:00:00' || (individual_log.time > '13:00:00' && individual_log.time <= '24:00:00')) {
                                checkOut1 = individual_log.time;
                                checkOut1 = checkOut1.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [checkOut1];

                      if(checkOut1.length > 1) { // If time format correct
                        checkOut1 = checkOut1.slice (1); // Remove full string match value

                              if(checkOut1[0] > 13 && checkOut1[0] <= 17) {
                                  temp_time = individual_log.time;

                                  bs2 = "18:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3_undertime = hours + ":" + minutes + ":" + seconds;
                              }
                              if(checkOut1[0] > 13 && checkOut1[0] <= 16) {
                                  temp_time = individual_log.time;

                                  bs2 = "17:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3 = hours + ":" + minutes + ":" + seconds;
                              }

                              if(checkOut1[0] > 13 && checkOut1[0] <= 15) {
                                  temp_time = individual_log.time;

                                  bs2 = "16:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3_undertime_kuyaDon = hours + ":" + minutes + ":" + seconds;
                              }
                          }
                   }
          }


          if(individual_log.inOutStatus === 'Check-Out') {
                var temp_time = "00:00:00";
                checkOut = individual_log.time;
                checkOut = checkOut.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [checkOut];
                if(checkOut.length > 1) { // If time format correct
                      checkOut = checkOut.slice (1); // Remove full string match value

                      if(checkOut[0] < 12) {
                          if(checkOut[0] > 8 && checkOut[0] <= 11) {
                              temp_time = individual_log.time;
                              bs3 = "12:00:00"
                              e3 = new Date(d + " " + temp_time);
                              s3 = new Date(d + " " + bs3);
                              dif3 = Math.abs(s3 - e3);

                              var seconds = Math.floor((dif3 / 1000) % 60),
                              minutes = Math.floor((dif3 / (1000 * 60)) % 60),
                              hours = Math.floor((dif3 / (1000 * 60 * 60)) % 24);

                              hours = (hours < 10) ? "0" + hours : hours;
                              minutes = (minutes < 10) ? "0" + minutes : minutes;
                              seconds = (seconds < 10) ? "0" + seconds : seconds;

                              r4 = hours + ":" + minutes + ":" + seconds;

                          }

                      }
                }
               
                                     
          }
    
          x_flexi = moment.duration(r1_flexi).add(moment.duration(r2)).add(moment.duration(r3_undertime)).add(moment.duration(r4))
              moment.utc(x_flexi.as('milliseconds')).format("HH:mm:ss")

          x_flexi_kuyaDon = moment.duration(r1_flexi_kuyaDon).add(moment.duration(r2)).add(moment.duration(r3_undertime_kuyaDon)).add(moment.duration(r4))
              moment.utc(x_flexi_kuyaDon.as('milliseconds')).format("HH:mm:ss")
           
          x = moment.duration(r1).add(moment.duration(r2)).add(moment.duration(r3)).add(moment.duration(r4))
              moment.utc(x.as('milliseconds')).format("HH:mm:ss")

          d20 = x;
          d20_flexi = x_flexi;  
          d20_flexi_kuyaDon = x_flexi_kuyaDon;     
        }
      });
      if(this.$route.params.pID == 15 || this.$route.params.pID == 37 || this.$route.params.pID == 13 || this.$route.params.pID == 1) {
         return r3_undertime;
      }
      if(this.$route.params.pID == 23) {
        return x_flexi_kuyaDon;
      }
      return x;
    },
    checkUnderTime21(date1) {
      var dt = new Date(date1), d = moment().format('L'), i = 0, j = -1, x = "", x_flexi = "", x_flexi_kuyaDon = "";
      let checkIn = "00:00:00", checkIn1 = "00:00:00", checkOut = "00:00:00", checkOut1 = "00:00:00", dif = "", dif1 = "", dif2 = "", dif3 = "", s = "", e = "", s1 = "", s2 = "", s3 = "", e1 = "", e2 = "", e3 = "", bs = "", bs1 = "", bs2 = "", bs3 = "", r1 = "00:00:00", r1_flexi = "00:00:00", r1_flexi_kuyaDon = "00:00:00", r2 = "00:00:00", r3 = "00:00:00", r4 = "00:00:00", r3_undertime = "00:00:00", r3_undertime_kuyaDon = "00:00:00";


      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }

      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 
      
      this.individual_logs.forEach(individual_log => {
        if(individual_log.date === date1) {
      
            if(individual_log.inOutStatus === 'Check-In') {
              if(this.$route.params.pID == 15) {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "9:00:00" || checkIn > "09:00:00") {
                        bs = "9:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var seconds = Math.floor((dif / 1000) % 60),
                        minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;
                        seconds = (seconds < 10) ? "0" + seconds : seconds;

                        r1_flexi = hours + ":" + minutes + ":" + seconds;
                      } 

                    }
              }

              if(this.$route.params.pID == 23) {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "7:00:00" || checkIn > "07:00:00") {
                        bs = "7:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var seconds = Math.floor((dif / 1000) % 60),
                        minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;
                        seconds = (seconds < 10) ? "0" + seconds : seconds;

                        r1_flexi_kuyaDon = hours + ":" + minutes + ":" + seconds;
                      } 

                    }
              }
                if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                  checkIn = individual_log.time;  
                  if(checkIn > "8:00:00" || checkIn > "08:00:00") {
                    bs = "8:00:00"
                    e = new Date(d + " " + checkIn);
                    s = new Date(d + " " + bs);
                    dif = Math.abs(e - s);

                    
                  var seconds = Math.floor((dif / 1000) % 60),
                    minutes = Math.floor((dif / (1000 * 60)) % 60),
                    hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                    hours = (hours < 10) ? "0" + hours : hours;
                    minutes = (minutes < 10) ? "0" + minutes : minutes;
                    seconds = (seconds < 10) ? "0" + seconds : seconds;

                    r1 = hours + ":" + minutes + ":" + seconds;
                  } 

                }
  
            }

          if(i !== 2) {

            if(individual_log.inOutStatus === 'Check-In') {
                if((individual_log.time > "12:00:00" && individual_log.time <= "13:00:00") || individual_log.time < "17:00:00") {
                checkIn1 = individual_log.time;
                if(checkIn1 > "13:00:00" || (checkIn1 > "13:00:00" && checkIn1 <= "17:00:00")) {
                  bs1 = "13:00:00"
                  e1 = new Date(d + " " + checkIn1);
                  s1 = new Date(d + " " + bs1);
                  dif1 = Math.abs(e1 - s1);

                  var seconds = Math.floor((dif1 / 1000) % 60),
                  minutes = Math.floor((dif1 / (1000 * 60)) % 60),
                  hours = Math.floor((dif1 / (1000 * 60 * 60)) % 24);

                  hours = (hours < 10) ? "0" + hours : hours;
                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                  r2 = hours + ":" + minutes + ":" + seconds;
                } 

              }
            }
            i++;
          }



      
          if(individual_log.inOutStatus === 'Check-Out') {
                  var temp_time = "00:00:00";
                  if(individual_log.time > '13:00:00' || (individual_log.time > '13:00:00' && individual_log.time <= '24:00:00')) {
                                checkOut1 = individual_log.time;
                                checkOut1 = checkOut1.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [checkOut1];

                      if(checkOut1.length > 1) { // If time format correct
                        checkOut1 = checkOut1.slice (1); // Remove full string match value

                              if(checkOut1[0] > 13 && checkOut1[0] <= 17) {
                                  temp_time = individual_log.time;

                                  bs2 = "18:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3_undertime = hours + ":" + minutes + ":" + seconds;
                              }
                              if(checkOut1[0] > 13 && checkOut1[0] <= 16) {
                                  temp_time = individual_log.time;

                                  bs2 = "17:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3 = hours + ":" + minutes + ":" + seconds;
                              }

                              if(checkOut1[0] > 13 && checkOut1[0] <= 15) {
                                  temp_time = individual_log.time;

                                  bs2 = "16:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3_undertime_kuyaDon = hours + ":" + minutes + ":" + seconds;
                              }
                          }
                   }
          }


          if(individual_log.inOutStatus === 'Check-Out') {
                var temp_time = "00:00:00";
                checkOut = individual_log.time;
                checkOut = checkOut.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [checkOut];
                if(checkOut.length > 1) { // If time format correct
                      checkOut = checkOut.slice (1); // Remove full string match value

                      if(checkOut[0] < 12) {
                          if(checkOut[0] > 8 && checkOut[0] <= 11) {
                              temp_time = individual_log.time;
                              bs3 = "12:00:00"
                              e3 = new Date(d + " " + temp_time);
                              s3 = new Date(d + " " + bs3);
                              dif3 = Math.abs(s3 - e3);

                              var seconds = Math.floor((dif3 / 1000) % 60),
                              minutes = Math.floor((dif3 / (1000 * 60)) % 60),
                              hours = Math.floor((dif3 / (1000 * 60 * 60)) % 24);

                              hours = (hours < 10) ? "0" + hours : hours;
                              minutes = (minutes < 10) ? "0" + minutes : minutes;
                              seconds = (seconds < 10) ? "0" + seconds : seconds;

                              r4 = hours + ":" + minutes + ":" + seconds;

                          }

                      }
                }
               
                                     
          }
    
          x_flexi = moment.duration(r1_flexi).add(moment.duration(r2)).add(moment.duration(r3_undertime)).add(moment.duration(r4))
              moment.utc(x_flexi.as('milliseconds')).format("HH:mm:ss")

          x_flexi_kuyaDon = moment.duration(r1_flexi_kuyaDon).add(moment.duration(r2)).add(moment.duration(r3_undertime_kuyaDon)).add(moment.duration(r4))
              moment.utc(x_flexi_kuyaDon.as('milliseconds')).format("HH:mm:ss")
           
          x = moment.duration(r1).add(moment.duration(r2)).add(moment.duration(r3)).add(moment.duration(r4))
              moment.utc(x.as('milliseconds')).format("HH:mm:ss")

          d21 = x;
          d21_flexi = x_flexi;  
          d21_flexi_kuyaDon = x_flexi_kuyaDon;     
        }
      });
      if(this.$route.params.pID == 15 || this.$route.params.pID == 37 || this.$route.params.pID == 13 || this.$route.params.pID == 1) {
         return r3_undertime;
      }
      if(this.$route.params.pID == 23) {
        return x_flexi_kuyaDon;
      }
      return x;
    },
    checkUnderTime22(date1) {
      var dt = new Date(date1), d = moment().format('L'), i = 0, j = -1, x = "", x_flexi = "", x_flexi_kuyaDon = "";
      let checkIn = "00:00:00", checkIn1 = "00:00:00", checkOut = "00:00:00", checkOut1 = "00:00:00", dif = "", dif1 = "", dif2 = "", dif3 = "", s = "", e = "", s1 = "", s2 = "", s3 = "", e1 = "", e2 = "", e3 = "", bs = "", bs1 = "", bs2 = "", bs3 = "", r1 = "00:00:00", r1_flexi = "00:00:00", r1_flexi_kuyaDon = "00:00:00", r2 = "00:00:00", r3 = "00:00:00", r4 = "00:00:00", r3_undertime = "00:00:00", r3_undertime_kuyaDon = "00:00:00";


      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }

      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 
      
      this.individual_logs.forEach(individual_log => {
        if(individual_log.date === date1) {
      
            if(individual_log.inOutStatus === 'Check-In') {
              if(this.$route.params.pID == 15) {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "9:00:00" || checkIn > "09:00:00") {
                        bs = "9:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var seconds = Math.floor((dif / 1000) % 60),
                        minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;
                        seconds = (seconds < 10) ? "0" + seconds : seconds;

                        r1_flexi = hours + ":" + minutes + ":" + seconds;
                      } 

                    }
              }

              if(this.$route.params.pID == 23) {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "7:00:00" || checkIn > "07:00:00") {
                        bs = "7:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var seconds = Math.floor((dif / 1000) % 60),
                        minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;
                        seconds = (seconds < 10) ? "0" + seconds : seconds;

                        r1_flexi_kuyaDon = hours + ":" + minutes + ":" + seconds;
                      } 

                    }
              }
                if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                  checkIn = individual_log.time;  
                  if(checkIn > "8:00:00" || checkIn > "08:00:00") {
                    bs = "8:00:00"
                    e = new Date(d + " " + checkIn);
                    s = new Date(d + " " + bs);
                    dif = Math.abs(e - s);

                    
                  var seconds = Math.floor((dif / 1000) % 60),
                    minutes = Math.floor((dif / (1000 * 60)) % 60),
                    hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                    hours = (hours < 10) ? "0" + hours : hours;
                    minutes = (minutes < 10) ? "0" + minutes : minutes;
                    seconds = (seconds < 10) ? "0" + seconds : seconds;

                    r1 = hours + ":" + minutes + ":" + seconds;
                  } 

                }
  
            }

          if(i !== 2) {

            if(individual_log.inOutStatus === 'Check-In') {
                if((individual_log.time > "12:00:00" && individual_log.time <= "13:00:00") || individual_log.time < "17:00:00") {
                checkIn1 = individual_log.time;
                if(checkIn1 > "13:00:00" || (checkIn1 > "13:00:00" && checkIn1 <= "17:00:00")) {
                  bs1 = "13:00:00"
                  e1 = new Date(d + " " + checkIn1);
                  s1 = new Date(d + " " + bs1);
                  dif1 = Math.abs(e1 - s1);

                  var seconds = Math.floor((dif1 / 1000) % 60),
                  minutes = Math.floor((dif1 / (1000 * 60)) % 60),
                  hours = Math.floor((dif1 / (1000 * 60 * 60)) % 24);

                  hours = (hours < 10) ? "0" + hours : hours;
                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                  r2 = hours + ":" + minutes + ":" + seconds;
                } 

              }
            }
            i++;
          }



      
          if(individual_log.inOutStatus === 'Check-Out') {
                  var temp_time = "00:00:00";
                  if(individual_log.time > '13:00:00' || (individual_log.time > '13:00:00' && individual_log.time <= '24:00:00')) {
                                checkOut1 = individual_log.time;
                                checkOut1 = checkOut1.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [checkOut1];

                      if(checkOut1.length > 1) { // If time format correct
                        checkOut1 = checkOut1.slice (1); // Remove full string match value

                              if(checkOut1[0] > 13 && checkOut1[0] <= 17) {
                                  temp_time = individual_log.time;

                                  bs2 = "18:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3_undertime = hours + ":" + minutes + ":" + seconds;
                              }
                              if(checkOut1[0] > 13 && checkOut1[0] <= 16) {
                                  temp_time = individual_log.time;

                                  bs2 = "17:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3 = hours + ":" + minutes + ":" + seconds;
                              }

                              if(checkOut1[0] > 13 && checkOut1[0] <= 15) {
                                  temp_time = individual_log.time;

                                  bs2 = "16:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3_undertime_kuyaDon = hours + ":" + minutes + ":" + seconds;
                              }
                          }
                   }
          }


          if(individual_log.inOutStatus === 'Check-Out') {
                var temp_time = "00:00:00";
                checkOut = individual_log.time;
                checkOut = checkOut.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [checkOut];
                if(checkOut.length > 1) { // If time format correct
                      checkOut = checkOut.slice (1); // Remove full string match value

                      if(checkOut[0] < 12) {
                          if(checkOut[0] > 8 && checkOut[0] <= 11) {
                              temp_time = individual_log.time;
                              bs3 = "12:00:00"
                              e3 = new Date(d + " " + temp_time);
                              s3 = new Date(d + " " + bs3);
                              dif3 = Math.abs(s3 - e3);

                              var seconds = Math.floor((dif3 / 1000) % 60),
                              minutes = Math.floor((dif3 / (1000 * 60)) % 60),
                              hours = Math.floor((dif3 / (1000 * 60 * 60)) % 24);

                              hours = (hours < 10) ? "0" + hours : hours;
                              minutes = (minutes < 10) ? "0" + minutes : minutes;
                              seconds = (seconds < 10) ? "0" + seconds : seconds;

                              r4 = hours + ":" + minutes + ":" + seconds;

                          }

                      }
                }
               
                                     
          }
    
          x_flexi = moment.duration(r1_flexi).add(moment.duration(r2)).add(moment.duration(r3_undertime)).add(moment.duration(r4))
              moment.utc(x_flexi.as('milliseconds')).format("HH:mm:ss")

          x_flexi_kuyaDon = moment.duration(r1_flexi_kuyaDon).add(moment.duration(r2)).add(moment.duration(r3_undertime_kuyaDon)).add(moment.duration(r4))
              moment.utc(x_flexi_kuyaDon.as('milliseconds')).format("HH:mm:ss")
           
          x = moment.duration(r1).add(moment.duration(r2)).add(moment.duration(r3)).add(moment.duration(r4))
              moment.utc(x.as('milliseconds')).format("HH:mm:ss")

          d22 = x;
          d22_flexi = x_flexi;  
          d22_flexi_kuyaDon = x_flexi_kuyaDon;     
        }
      });
      if(this.$route.params.pID == 15 || this.$route.params.pID == 37 || this.$route.params.pID == 13 || this.$route.params.pID == 1) {
         return r3_undertime;
      }
      if(this.$route.params.pID == 23) {
        return x_flexi_kuyaDon;
      }
      return x;
    },
    checkUnderTime23(date1) {
      var dt = new Date(date1), d = moment().format('L'), i = 0, j = -1, x = "", x_flexi = "", x_flexi_kuyaDon = "";
      let checkIn = "00:00:00", checkIn1 = "00:00:00", checkOut = "00:00:00", checkOut1 = "00:00:00", dif = "", dif1 = "", dif2 = "", dif3 = "", s = "", e = "", s1 = "", s2 = "", s3 = "", e1 = "", e2 = "", e3 = "", bs = "", bs1 = "", bs2 = "", bs3 = "", r1 = "00:00:00", r1_flexi = "00:00:00", r1_flexi_kuyaDon = "00:00:00", r2 = "00:00:00", r3 = "00:00:00", r4 = "00:00:00", r3_undertime = "00:00:00", r3_undertime_kuyaDon = "00:00:00";


      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }

      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 
      
      this.individual_logs.forEach(individual_log => {
        if(individual_log.date === date1) {
      
            if(individual_log.inOutStatus === 'Check-In') {
              if(this.$route.params.pID == 15) {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "9:00:00" || checkIn > "09:00:00") {
                        bs = "9:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var seconds = Math.floor((dif / 1000) % 60),
                        minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;
                        seconds = (seconds < 10) ? "0" + seconds : seconds;

                        r1_flexi = hours + ":" + minutes + ":" + seconds;
                      } 

                    }
              }

              if(this.$route.params.pID == 23) {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "7:00:00" || checkIn > "07:00:00") {
                        bs = "7:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var seconds = Math.floor((dif / 1000) % 60),
                        minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;
                        seconds = (seconds < 10) ? "0" + seconds : seconds;

                        r1_flexi_kuyaDon = hours + ":" + minutes + ":" + seconds;
                      } 

                    }
              }
                if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                  checkIn = individual_log.time;  
                  if(checkIn > "8:00:00" || checkIn > "08:00:00") {
                    bs = "8:00:00"
                    e = new Date(d + " " + checkIn);
                    s = new Date(d + " " + bs);
                    dif = Math.abs(e - s);

                    
                  var seconds = Math.floor((dif / 1000) % 60),
                    minutes = Math.floor((dif / (1000 * 60)) % 60),
                    hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                    hours = (hours < 10) ? "0" + hours : hours;
                    minutes = (minutes < 10) ? "0" + minutes : minutes;
                    seconds = (seconds < 10) ? "0" + seconds : seconds;

                    r1 = hours + ":" + minutes + ":" + seconds;
                  } 

                }
  
            }

          if(i !== 2) {

            if(individual_log.inOutStatus === 'Check-In') {
                if((individual_log.time > "12:00:00" && individual_log.time <= "13:00:00") || individual_log.time < "17:00:00") {
                checkIn1 = individual_log.time;
                if(checkIn1 > "13:00:00" || (checkIn1 > "13:00:00" && checkIn1 <= "17:00:00")) {
                  bs1 = "13:00:00"
                  e1 = new Date(d + " " + checkIn1);
                  s1 = new Date(d + " " + bs1);
                  dif1 = Math.abs(e1 - s1);

                  var seconds = Math.floor((dif1 / 1000) % 60),
                  minutes = Math.floor((dif1 / (1000 * 60)) % 60),
                  hours = Math.floor((dif1 / (1000 * 60 * 60)) % 24);

                  hours = (hours < 10) ? "0" + hours : hours;
                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                  r2 = hours + ":" + minutes + ":" + seconds;
                } 

              }
            }
            i++;
          }



      
          if(individual_log.inOutStatus === 'Check-Out') {
                  var temp_time = "00:00:00";
                  if(individual_log.time > '13:00:00' || (individual_log.time > '13:00:00' && individual_log.time <= '24:00:00')) {
                                checkOut1 = individual_log.time;
                                checkOut1 = checkOut1.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [checkOut1];

                      if(checkOut1.length > 1) { // If time format correct
                        checkOut1 = checkOut1.slice (1); // Remove full string match value

                              if(checkOut1[0] > 13 && checkOut1[0] <= 17) {
                                  temp_time = individual_log.time;

                                  bs2 = "18:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3_undertime = hours + ":" + minutes + ":" + seconds;
                              }
                              if(checkOut1[0] > 13 && checkOut1[0] <= 16) {
                                  temp_time = individual_log.time;

                                  bs2 = "17:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3 = hours + ":" + minutes + ":" + seconds;
                              }

                              if(checkOut1[0] > 13 && checkOut1[0] <= 15) {
                                  temp_time = individual_log.time;

                                  bs2 = "16:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3_undertime_kuyaDon = hours + ":" + minutes + ":" + seconds;
                              }
                          }
                   }
          }


          if(individual_log.inOutStatus === 'Check-Out') {
                var temp_time = "00:00:00";
                checkOut = individual_log.time;
                checkOut = checkOut.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [checkOut];
                if(checkOut.length > 1) { // If time format correct
                      checkOut = checkOut.slice (1); // Remove full string match value

                      if(checkOut[0] < 12) {
                          if(checkOut[0] > 8 && checkOut[0] <= 11) {
                              temp_time = individual_log.time;
                              bs3 = "12:00:00"
                              e3 = new Date(d + " " + temp_time);
                              s3 = new Date(d + " " + bs3);
                              dif3 = Math.abs(s3 - e3);

                              var seconds = Math.floor((dif3 / 1000) % 60),
                              minutes = Math.floor((dif3 / (1000 * 60)) % 60),
                              hours = Math.floor((dif3 / (1000 * 60 * 60)) % 24);

                              hours = (hours < 10) ? "0" + hours : hours;
                              minutes = (minutes < 10) ? "0" + minutes : minutes;
                              seconds = (seconds < 10) ? "0" + seconds : seconds;

                              r4 = hours + ":" + minutes + ":" + seconds;

                          }

                      }
                }
               
                                     
          }
    
          x_flexi = moment.duration(r1_flexi).add(moment.duration(r2)).add(moment.duration(r3_undertime)).add(moment.duration(r4))
              moment.utc(x_flexi.as('milliseconds')).format("HH:mm:ss")

          x_flexi_kuyaDon = moment.duration(r1_flexi_kuyaDon).add(moment.duration(r2)).add(moment.duration(r3_undertime_kuyaDon)).add(moment.duration(r4))
              moment.utc(x_flexi_kuyaDon.as('milliseconds')).format("HH:mm:ss")
           
          x = moment.duration(r1).add(moment.duration(r2)).add(moment.duration(r3)).add(moment.duration(r4))
              moment.utc(x.as('milliseconds')).format("HH:mm:ss")

          d23 = x;
          d23_flexi = x_flexi;  
          d23_flexi_kuyaDon = x_flexi_kuyaDon;     
        }
      });
      if(this.$route.params.pID == 15 || this.$route.params.pID == 37 || this.$route.params.pID == 13 || this.$route.params.pID == 1) {
         return r3_undertime;
      }
      if(this.$route.params.pID == 23) {
        return x_flexi_kuyaDon;
      }
      return x;
    },
    checkUnderTime24(date1) {
      var dt = new Date(date1), d = moment().format('L'), i = 0, j = -1, x = "", x_flexi = "", x_flexi_kuyaDon = "";
      let checkIn = "00:00:00", checkIn1 = "00:00:00", checkOut = "00:00:00", checkOut1 = "00:00:00", dif = "", dif1 = "", dif2 = "", dif3 = "", s = "", e = "", s1 = "", s2 = "", s3 = "", e1 = "", e2 = "", e3 = "", bs = "", bs1 = "", bs2 = "", bs3 = "", r1 = "00:00:00", r1_flexi = "00:00:00", r1_flexi_kuyaDon = "00:00:00", r2 = "00:00:00", r3 = "00:00:00", r4 = "00:00:00", r3_undertime = "00:00:00", r3_undertime_kuyaDon = "00:00:00";


      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }

      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 
      
      this.individual_logs.forEach(individual_log => {
        if(individual_log.date === date1) {
      
            if(individual_log.inOutStatus === 'Check-In') {
              if(this.$route.params.pID == 15) {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "9:00:00" || checkIn > "09:00:00") {
                        bs = "9:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var seconds = Math.floor((dif / 1000) % 60),
                        minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;
                        seconds = (seconds < 10) ? "0" + seconds : seconds;

                        r1_flexi = hours + ":" + minutes + ":" + seconds;
                      } 

                    }
              }

              if(this.$route.params.pID == 23) {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "7:00:00" || checkIn > "07:00:00") {
                        bs = "7:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var seconds = Math.floor((dif / 1000) % 60),
                        minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;
                        seconds = (seconds < 10) ? "0" + seconds : seconds;

                        r1_flexi_kuyaDon = hours + ":" + minutes + ":" + seconds;
                      } 

                    }
              }
                if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                  checkIn = individual_log.time;  
                  if(checkIn > "8:00:00" || checkIn > "08:00:00") {
                    bs = "8:00:00"
                    e = new Date(d + " " + checkIn);
                    s = new Date(d + " " + bs);
                    dif = Math.abs(e - s);

                    
                  var seconds = Math.floor((dif / 1000) % 60),
                    minutes = Math.floor((dif / (1000 * 60)) % 60),
                    hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                    hours = (hours < 10) ? "0" + hours : hours;
                    minutes = (minutes < 10) ? "0" + minutes : minutes;
                    seconds = (seconds < 10) ? "0" + seconds : seconds;

                    r1 = hours + ":" + minutes + ":" + seconds;
                  } 

                }
  
            }

          if(i !== 2) {

            if(individual_log.inOutStatus === 'Check-In') {
                if((individual_log.time > "12:00:00" && individual_log.time <= "13:00:00") || individual_log.time < "17:00:00") {
                checkIn1 = individual_log.time;
                if(checkIn1 > "13:00:00" || (checkIn1 > "13:00:00" && checkIn1 <= "17:00:00")) {
                  bs1 = "13:00:00"
                  e1 = new Date(d + " " + checkIn1);
                  s1 = new Date(d + " " + bs1);
                  dif1 = Math.abs(e1 - s1);

                  var seconds = Math.floor((dif1 / 1000) % 60),
                  minutes = Math.floor((dif1 / (1000 * 60)) % 60),
                  hours = Math.floor((dif1 / (1000 * 60 * 60)) % 24);

                  hours = (hours < 10) ? "0" + hours : hours;
                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                  r2 = hours + ":" + minutes + ":" + seconds;
                } 

              }
            }
            i++;
          }



      
          if(individual_log.inOutStatus === 'Check-Out') {
                  var temp_time = "00:00:00";
                  if(individual_log.time > '13:00:00' || (individual_log.time > '13:00:00' && individual_log.time <= '24:00:00')) {
                                checkOut1 = individual_log.time;
                                checkOut1 = checkOut1.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [checkOut1];

                      if(checkOut1.length > 1) { // If time format correct
                        checkOut1 = checkOut1.slice (1); // Remove full string match value

                              if(checkOut1[0] > 13 && checkOut1[0] <= 17) {
                                  temp_time = individual_log.time;

                                  bs2 = "18:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3_undertime = hours + ":" + minutes + ":" + seconds;
                              }
                              if(checkOut1[0] > 13 && checkOut1[0] <= 16) {
                                  temp_time = individual_log.time;

                                  bs2 = "17:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3 = hours + ":" + minutes + ":" + seconds;
                              }

                              if(checkOut1[0] > 13 && checkOut1[0] <= 15) {
                                  temp_time = individual_log.time;

                                  bs2 = "16:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3_undertime_kuyaDon = hours + ":" + minutes + ":" + seconds;
                              }
                          }
                   }
          }


          if(individual_log.inOutStatus === 'Check-Out') {
                var temp_time = "00:00:00";
                checkOut = individual_log.time;
                checkOut = checkOut.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [checkOut];
                if(checkOut.length > 1) { // If time format correct
                      checkOut = checkOut.slice (1); // Remove full string match value

                      if(checkOut[0] < 12) {
                          if(checkOut[0] > 8 && checkOut[0] <= 11) {
                              temp_time = individual_log.time;
                              bs3 = "12:00:00"
                              e3 = new Date(d + " " + temp_time);
                              s3 = new Date(d + " " + bs3);
                              dif3 = Math.abs(s3 - e3);

                              var seconds = Math.floor((dif3 / 1000) % 60),
                              minutes = Math.floor((dif3 / (1000 * 60)) % 60),
                              hours = Math.floor((dif3 / (1000 * 60 * 60)) % 24);

                              hours = (hours < 10) ? "0" + hours : hours;
                              minutes = (minutes < 10) ? "0" + minutes : minutes;
                              seconds = (seconds < 10) ? "0" + seconds : seconds;

                              r4 = hours + ":" + minutes + ":" + seconds;

                          }

                      }
                }
               
                                     
          }
    
          x_flexi = moment.duration(r1_flexi).add(moment.duration(r2)).add(moment.duration(r3_undertime)).add(moment.duration(r4))
              moment.utc(x_flexi.as('milliseconds')).format("HH:mm:ss")

          x_flexi_kuyaDon = moment.duration(r1_flexi_kuyaDon).add(moment.duration(r2)).add(moment.duration(r3_undertime_kuyaDon)).add(moment.duration(r4))
              moment.utc(x_flexi_kuyaDon.as('milliseconds')).format("HH:mm:ss")
           
          x = moment.duration(r1).add(moment.duration(r2)).add(moment.duration(r3)).add(moment.duration(r4))
              moment.utc(x.as('milliseconds')).format("HH:mm:ss")

          d24 = x;
          d24_flexi = x_flexi;  
          d24_flexi_kuyaDon = x_flexi_kuyaDon;     
        }
      });
      if(this.$route.params.pID == 15 || this.$route.params.pID == 37 || this.$route.params.pID == 13 || this.$route.params.pID == 1) {
         return r3_undertime;
      }
      if(this.$route.params.pID == 23) {
        return x_flexi_kuyaDon;
      }
      return x;
    },
    checkUnderTime25(date1) {
      var dt = new Date(date1), d = moment().format('L'), i = 0, j = -1, x = "", x_flexi = "", x_flexi_kuyaDon = "";
      let checkIn = "00:00:00", checkIn1 = "00:00:00", checkOut = "00:00:00", checkOut1 = "00:00:00", dif = "", dif1 = "", dif2 = "", dif3 = "", s = "", e = "", s1 = "", s2 = "", s3 = "", e1 = "", e2 = "", e3 = "", bs = "", bs1 = "", bs2 = "", bs3 = "", r1 = "00:00:00", r1_flexi = "00:00:00", r1_flexi_kuyaDon = "00:00:00", r2 = "00:00:00", r3 = "00:00:00", r4 = "00:00:00", r3_undertime = "00:00:00", r3_undertime_kuyaDon = "00:00:00";


      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }

      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 
      
      this.individual_logs.forEach(individual_log => {
        if(individual_log.date === date1) {
      
            if(individual_log.inOutStatus === 'Check-In') {
              if(this.$route.params.pID == 15) {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "9:00:00" || checkIn > "09:00:00") {
                        bs = "9:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var seconds = Math.floor((dif / 1000) % 60),
                        minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;
                        seconds = (seconds < 10) ? "0" + seconds : seconds;

                        r1_flexi = hours + ":" + minutes + ":" + seconds;
                      } 

                    }
              }

              if(this.$route.params.pID == 23) {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "7:00:00" || checkIn > "07:00:00") {
                        bs = "7:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var seconds = Math.floor((dif / 1000) % 60),
                        minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;
                        seconds = (seconds < 10) ? "0" + seconds : seconds;

                        r1_flexi_kuyaDon = hours + ":" + minutes + ":" + seconds;
                      } 

                    }
              }
                if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                  checkIn = individual_log.time;  
                  if(checkIn > "8:00:00" || checkIn > "08:00:00") {
                    bs = "8:00:00"
                    e = new Date(d + " " + checkIn);
                    s = new Date(d + " " + bs);
                    dif = Math.abs(e - s);

                    
                  var seconds = Math.floor((dif / 1000) % 60),
                    minutes = Math.floor((dif / (1000 * 60)) % 60),
                    hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                    hours = (hours < 10) ? "0" + hours : hours;
                    minutes = (minutes < 10) ? "0" + minutes : minutes;
                    seconds = (seconds < 10) ? "0" + seconds : seconds;

                    r1 = hours + ":" + minutes + ":" + seconds;
                  } 

                }
  
            }

          if(i !== 2) {

            if(individual_log.inOutStatus === 'Check-In') {
                if((individual_log.time > "12:00:00" && individual_log.time <= "13:00:00") || individual_log.time < "17:00:00") {
                checkIn1 = individual_log.time;
                if(checkIn1 > "13:00:00" || (checkIn1 > "13:00:00" && checkIn1 <= "17:00:00")) {
                  bs1 = "13:00:00"
                  e1 = new Date(d + " " + checkIn1);
                  s1 = new Date(d + " " + bs1);
                  dif1 = Math.abs(e1 - s1);

                  var seconds = Math.floor((dif1 / 1000) % 60),
                  minutes = Math.floor((dif1 / (1000 * 60)) % 60),
                  hours = Math.floor((dif1 / (1000 * 60 * 60)) % 24);

                  hours = (hours < 10) ? "0" + hours : hours;
                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                  r2 = hours + ":" + minutes + ":" + seconds;
                } 

              }
            }
            i++;
          }



      
          if(individual_log.inOutStatus === 'Check-Out') {
                  var temp_time = "00:00:00";
                  if(individual_log.time > '13:00:00' || (individual_log.time > '13:00:00' && individual_log.time <= '24:00:00')) {
                                checkOut1 = individual_log.time;
                                checkOut1 = checkOut1.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [checkOut1];

                      if(checkOut1.length > 1) { // If time format correct
                        checkOut1 = checkOut1.slice (1); // Remove full string match value

                              if(checkOut1[0] > 13 && checkOut1[0] <= 17) {
                                  temp_time = individual_log.time;

                                  bs2 = "18:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3_undertime = hours + ":" + minutes + ":" + seconds;
                              }
                              if(checkOut1[0] > 13 && checkOut1[0] <= 16) {
                                  temp_time = individual_log.time;

                                  bs2 = "17:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3 = hours + ":" + minutes + ":" + seconds;
                              }

                              if(checkOut1[0] > 13 && checkOut1[0] <= 15) {
                                  temp_time = individual_log.time;

                                  bs2 = "16:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3_undertime_kuyaDon = hours + ":" + minutes + ":" + seconds;
                              }
                          }
                   }
          }


          if(individual_log.inOutStatus === 'Check-Out') {
                var temp_time = "00:00:00";
                checkOut = individual_log.time;
                checkOut = checkOut.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [checkOut];
                if(checkOut.length > 1) { // If time format correct
                      checkOut = checkOut.slice (1); // Remove full string match value

                      if(checkOut[0] < 12) {
                          if(checkOut[0] > 8 && checkOut[0] <= 11) {
                              temp_time = individual_log.time;
                              bs3 = "12:00:00"
                              e3 = new Date(d + " " + temp_time);
                              s3 = new Date(d + " " + bs3);
                              dif3 = Math.abs(s3 - e3);

                              var seconds = Math.floor((dif3 / 1000) % 60),
                              minutes = Math.floor((dif3 / (1000 * 60)) % 60),
                              hours = Math.floor((dif3 / (1000 * 60 * 60)) % 24);

                              hours = (hours < 10) ? "0" + hours : hours;
                              minutes = (minutes < 10) ? "0" + minutes : minutes;
                              seconds = (seconds < 10) ? "0" + seconds : seconds;

                              r4 = hours + ":" + minutes + ":" + seconds;

                          }

                      }
                }
               
                                     
          }
    
          x_flexi = moment.duration(r1_flexi).add(moment.duration(r2)).add(moment.duration(r3_undertime)).add(moment.duration(r4))
              moment.utc(x_flexi.as('milliseconds')).format("HH:mm:ss")

          x_flexi_kuyaDon = moment.duration(r1_flexi_kuyaDon).add(moment.duration(r2)).add(moment.duration(r3_undertime_kuyaDon)).add(moment.duration(r4))
              moment.utc(x_flexi_kuyaDon.as('milliseconds')).format("HH:mm:ss")
           
          x = moment.duration(r1).add(moment.duration(r2)).add(moment.duration(r3)).add(moment.duration(r4))
              moment.utc(x.as('milliseconds')).format("HH:mm:ss")

          d25 = x;
          d25_flexi = x_flexi;  
          d25_flexi_kuyaDon = x_flexi_kuyaDon;     
        }
      });
      if(this.$route.params.pID == 15 || this.$route.params.pID == 37 || this.$route.params.pID == 13 || this.$route.params.pID == 1) {
         return r3_undertime;
      }
      if(this.$route.params.pID == 23) {
        return x_flexi_kuyaDon;
      }
      return x;
    },
    checkUnderTime26(date1) {
      var dt = new Date(date1), d = moment().format('L'), i = 0, j = -1, x = "", x_flexi = "", x_flexi_kuyaDon = "";
      let checkIn = "00:00:00", checkIn1 = "00:00:00", checkOut = "00:00:00", checkOut1 = "00:00:00", dif = "", dif1 = "", dif2 = "", dif3 = "", s = "", e = "", s1 = "", s2 = "", s3 = "", e1 = "", e2 = "", e3 = "", bs = "", bs1 = "", bs2 = "", bs3 = "", r1 = "00:00:00", r1_flexi = "00:00:00", r1_flexi_kuyaDon = "00:00:00", r2 = "00:00:00", r3 = "00:00:00", r4 = "00:00:00", r3_undertime = "00:00:00", r3_undertime_kuyaDon = "00:00:00";


      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }

      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 
      
      this.individual_logs.forEach(individual_log => {
        if(individual_log.date === date1) {
      
            if(individual_log.inOutStatus === 'Check-In') {
              if(this.$route.params.pID == 15) {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "9:00:00" || checkIn > "09:00:00") {
                        bs = "9:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var seconds = Math.floor((dif / 1000) % 60),
                        minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;
                        seconds = (seconds < 10) ? "0" + seconds : seconds;

                        r1_flexi = hours + ":" + minutes + ":" + seconds;
                      } 

                    }
              }

              if(this.$route.params.pID == 23) {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "7:00:00" || checkIn > "07:00:00") {
                        bs = "7:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var seconds = Math.floor((dif / 1000) % 60),
                        minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;
                        seconds = (seconds < 10) ? "0" + seconds : seconds;

                        r1_flexi_kuyaDon = hours + ":" + minutes + ":" + seconds;
                      } 

                    }
              }
                if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                  checkIn = individual_log.time;  
                  if(checkIn > "8:00:00" || checkIn > "08:00:00") {
                    bs = "8:00:00"
                    e = new Date(d + " " + checkIn);
                    s = new Date(d + " " + bs);
                    dif = Math.abs(e - s);

                    
                  var seconds = Math.floor((dif / 1000) % 60),
                    minutes = Math.floor((dif / (1000 * 60)) % 60),
                    hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                    hours = (hours < 10) ? "0" + hours : hours;
                    minutes = (minutes < 10) ? "0" + minutes : minutes;
                    seconds = (seconds < 10) ? "0" + seconds : seconds;

                    r1 = hours + ":" + minutes + ":" + seconds;
                  } 

                }
  
            }

          if(i !== 2) {

            if(individual_log.inOutStatus === 'Check-In') {
                if((individual_log.time > "12:00:00" && individual_log.time <= "13:00:00") || individual_log.time < "17:00:00") {
                checkIn1 = individual_log.time;
                if(checkIn1 > "13:00:00" || (checkIn1 > "13:00:00" && checkIn1 <= "17:00:00")) {
                  bs1 = "13:00:00"
                  e1 = new Date(d + " " + checkIn1);
                  s1 = new Date(d + " " + bs1);
                  dif1 = Math.abs(e1 - s1);

                  var seconds = Math.floor((dif1 / 1000) % 60),
                  minutes = Math.floor((dif1 / (1000 * 60)) % 60),
                  hours = Math.floor((dif1 / (1000 * 60 * 60)) % 24);

                  hours = (hours < 10) ? "0" + hours : hours;
                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                  r2 = hours + ":" + minutes + ":" + seconds;
                } 

              }
            }
            i++;
          }



      
          if(individual_log.inOutStatus === 'Check-Out') {
                  var temp_time = "00:00:00";
                  if(individual_log.time > '13:00:00' || (individual_log.time > '13:00:00' && individual_log.time <= '24:00:00')) {
                                checkOut1 = individual_log.time;
                                checkOut1 = checkOut1.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [checkOut1];

                      if(checkOut1.length > 1) { // If time format correct
                        checkOut1 = checkOut1.slice (1); // Remove full string match value

                              if(checkOut1[0] > 13 && checkOut1[0] <= 17) {
                                  temp_time = individual_log.time;

                                  bs2 = "18:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3_undertime = hours + ":" + minutes + ":" + seconds;
                              }
                              if(checkOut1[0] > 13 && checkOut1[0] <= 16) {
                                  temp_time = individual_log.time;

                                  bs2 = "17:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3 = hours + ":" + minutes + ":" + seconds;
                              }

                              if(checkOut1[0] > 13 && checkOut1[0] <= 15) {
                                  temp_time = individual_log.time;

                                  bs2 = "16:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3_undertime_kuyaDon = hours + ":" + minutes + ":" + seconds;
                              }
                          }
                   }
          }


          if(individual_log.inOutStatus === 'Check-Out') {
                var temp_time = "00:00:00";
                checkOut = individual_log.time;
                checkOut = checkOut.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [checkOut];
                if(checkOut.length > 1) { // If time format correct
                      checkOut = checkOut.slice (1); // Remove full string match value

                      if(checkOut[0] < 12) {
                          if(checkOut[0] > 8 && checkOut[0] <= 11) {
                              temp_time = individual_log.time;
                              bs3 = "12:00:00"
                              e3 = new Date(d + " " + temp_time);
                              s3 = new Date(d + " " + bs3);
                              dif3 = Math.abs(s3 - e3);

                              var seconds = Math.floor((dif3 / 1000) % 60),
                              minutes = Math.floor((dif3 / (1000 * 60)) % 60),
                              hours = Math.floor((dif3 / (1000 * 60 * 60)) % 24);

                              hours = (hours < 10) ? "0" + hours : hours;
                              minutes = (minutes < 10) ? "0" + minutes : minutes;
                              seconds = (seconds < 10) ? "0" + seconds : seconds;

                              r4 = hours + ":" + minutes + ":" + seconds;

                          }

                      }
                }
               
                                     
          }
    
          x_flexi = moment.duration(r1_flexi).add(moment.duration(r2)).add(moment.duration(r3_undertime)).add(moment.duration(r4))
              moment.utc(x_flexi.as('milliseconds')).format("HH:mm:ss")

          x_flexi_kuyaDon = moment.duration(r1_flexi_kuyaDon).add(moment.duration(r2)).add(moment.duration(r3_undertime_kuyaDon)).add(moment.duration(r4))
              moment.utc(x_flexi_kuyaDon.as('milliseconds')).format("HH:mm:ss")
           
          x = moment.duration(r1).add(moment.duration(r2)).add(moment.duration(r3)).add(moment.duration(r4))
              moment.utc(x.as('milliseconds')).format("HH:mm:ss")

          d26 = x;
          d26_flexi = x_flexi;  
          d26_flexi_kuyaDon = x_flexi_kuyaDon;     
        }
      });
      if(this.$route.params.pID == 15 || this.$route.params.pID == 37 || this.$route.params.pID == 13 || this.$route.params.pID == 1) {
         return r3_undertime;
      }
      if(this.$route.params.pID == 23) {
        return x_flexi_kuyaDon;
      }
      return x;
    },
    checkUnderTime27(date1) {
      var dt = new Date(date1), d = moment().format('L'), i = 0, j = -1, x = "", x_flexi = "", x_flexi_kuyaDon = "";
      let checkIn = "00:00:00", checkIn1 = "00:00:00", checkOut = "00:00:00", checkOut1 = "00:00:00", dif = "", dif1 = "", dif2 = "", dif3 = "", s = "", e = "", s1 = "", s2 = "", s3 = "", e1 = "", e2 = "", e3 = "", bs = "", bs1 = "", bs2 = "", bs3 = "", r1 = "00:00:00", r1_flexi = "00:00:00", r1_flexi_kuyaDon = "00:00:00", r2 = "00:00:00", r3 = "00:00:00", r4 = "00:00:00", r3_undertime = "00:00:00", r3_undertime_kuyaDon = "00:00:00";


      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }

      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 
      
      this.individual_logs.forEach(individual_log => {
        if(individual_log.date === date1) {
      
            if(individual_log.inOutStatus === 'Check-In') {
              if(this.$route.params.pID == 15) {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "9:00:00" || checkIn > "09:00:00") {
                        bs = "9:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var seconds = Math.floor((dif / 1000) % 60),
                        minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;
                        seconds = (seconds < 10) ? "0" + seconds : seconds;

                        r1_flexi = hours + ":" + minutes + ":" + seconds;
                      } 

                    }
              }

              if(this.$route.params.pID == 23) {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "7:00:00" || checkIn > "07:00:00") {
                        bs = "7:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var seconds = Math.floor((dif / 1000) % 60),
                        minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;
                        seconds = (seconds < 10) ? "0" + seconds : seconds;

                        r1_flexi_kuyaDon = hours + ":" + minutes + ":" + seconds;
                      } 

                    }
              }
                if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                  checkIn = individual_log.time;  
                  if(checkIn > "8:00:00" || checkIn > "08:00:00") {
                    bs = "8:00:00"
                    e = new Date(d + " " + checkIn);
                    s = new Date(d + " " + bs);
                    dif = Math.abs(e - s);

                    
                  var seconds = Math.floor((dif / 1000) % 60),
                    minutes = Math.floor((dif / (1000 * 60)) % 60),
                    hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                    hours = (hours < 10) ? "0" + hours : hours;
                    minutes = (minutes < 10) ? "0" + minutes : minutes;
                    seconds = (seconds < 10) ? "0" + seconds : seconds;

                    r1 = hours + ":" + minutes + ":" + seconds;
                  } 

                }
  
            }

          if(i !== 2) {

            if(individual_log.inOutStatus === 'Check-In') {
                if((individual_log.time > "12:00:00" && individual_log.time <= "13:00:00") || individual_log.time < "17:00:00") {
                checkIn1 = individual_log.time;
                if(checkIn1 > "13:00:00" || (checkIn1 > "13:00:00" && checkIn1 <= "17:00:00")) {
                  bs1 = "13:00:00"
                  e1 = new Date(d + " " + checkIn1);
                  s1 = new Date(d + " " + bs1);
                  dif1 = Math.abs(e1 - s1);

                  var seconds = Math.floor((dif1 / 1000) % 60),
                  minutes = Math.floor((dif1 / (1000 * 60)) % 60),
                  hours = Math.floor((dif1 / (1000 * 60 * 60)) % 24);

                  hours = (hours < 10) ? "0" + hours : hours;
                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                  r2 = hours + ":" + minutes + ":" + seconds;
                } 

              }
            }
            i++;
          }



      
          if(individual_log.inOutStatus === 'Check-Out') {
                  var temp_time = "00:00:00";
                  if(individual_log.time > '13:00:00' || (individual_log.time > '13:00:00' && individual_log.time <= '24:00:00')) {
                                checkOut1 = individual_log.time;
                                checkOut1 = checkOut1.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [checkOut1];

                      if(checkOut1.length > 1) { // If time format correct
                        checkOut1 = checkOut1.slice (1); // Remove full string match value

                              if(checkOut1[0] > 13 && checkOut1[0] <= 17) {
                                  temp_time = individual_log.time;

                                  bs2 = "18:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3_undertime = hours + ":" + minutes + ":" + seconds;
                              }
                              if(checkOut1[0] > 13 && checkOut1[0] <= 16) {
                                  temp_time = individual_log.time;

                                  bs2 = "17:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3 = hours + ":" + minutes + ":" + seconds;
                              }

                              if(checkOut1[0] > 13 && checkOut1[0] <= 15) {
                                  temp_time = individual_log.time;

                                  bs2 = "16:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3_undertime_kuyaDon = hours + ":" + minutes + ":" + seconds;
                              }
                          }
                   }
          }


          if(individual_log.inOutStatus === 'Check-Out') {
                var temp_time = "00:00:00";
                checkOut = individual_log.time;
                checkOut = checkOut.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [checkOut];
                if(checkOut.length > 1) { // If time format correct
                      checkOut = checkOut.slice (1); // Remove full string match value

                      if(checkOut[0] < 12) {
                          if(checkOut[0] > 8 && checkOut[0] <= 11) {
                              temp_time = individual_log.time;
                              bs3 = "12:00:00"
                              e3 = new Date(d + " " + temp_time);
                              s3 = new Date(d + " " + bs3);
                              dif3 = Math.abs(s3 - e3);

                              var seconds = Math.floor((dif3 / 1000) % 60),
                              minutes = Math.floor((dif3 / (1000 * 60)) % 60),
                              hours = Math.floor((dif3 / (1000 * 60 * 60)) % 24);

                              hours = (hours < 10) ? "0" + hours : hours;
                              minutes = (minutes < 10) ? "0" + minutes : minutes;
                              seconds = (seconds < 10) ? "0" + seconds : seconds;

                              r4 = hours + ":" + minutes + ":" + seconds;

                          }

                      }
                }
               
                                     
          }
    
          x_flexi = moment.duration(r1_flexi).add(moment.duration(r2)).add(moment.duration(r3_undertime)).add(moment.duration(r4))
              moment.utc(x_flexi.as('milliseconds')).format("HH:mm:ss")

          x_flexi_kuyaDon = moment.duration(r1_flexi_kuyaDon).add(moment.duration(r2)).add(moment.duration(r3_undertime_kuyaDon)).add(moment.duration(r4))
              moment.utc(x_flexi_kuyaDon.as('milliseconds')).format("HH:mm:ss")
           
          x = moment.duration(r1).add(moment.duration(r2)).add(moment.duration(r3)).add(moment.duration(r4))
              moment.utc(x.as('milliseconds')).format("HH:mm:ss")

          d27 = x;
          d27_flexi = x_flexi;  
          d27_flexi_kuyaDon = x_flexi_kuyaDon;     
        }
      });
      if(this.$route.params.pID == 15 || this.$route.params.pID == 37 || this.$route.params.pID == 13 || this.$route.params.pID == 1) {
         return r3_undertime;
      }
      if(this.$route.params.pID == 23) {
        return x_flexi_kuyaDon;
      }
      return x;
    },
    checkUnderTime28(date1) {
      var dt = new Date(date1), d = moment().format('L'), i = 0, j = -1, x = "", x_flexi = "", x_flexi_kuyaDon = "";
      let checkIn = "00:00:00", checkIn1 = "00:00:00", checkOut = "00:00:00", checkOut1 = "00:00:00", dif = "", dif1 = "", dif2 = "", dif3 = "", s = "", e = "", s1 = "", s2 = "", s3 = "", e1 = "", e2 = "", e3 = "", bs = "", bs1 = "", bs2 = "", bs3 = "", r1 = "00:00:00", r1_flexi = "00:00:00", r1_flexi_kuyaDon = "00:00:00", r2 = "00:00:00", r3 = "00:00:00", r4 = "00:00:00", r3_undertime = "00:00:00", r3_undertime_kuyaDon = "00:00:00";


      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }

      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 
      
      this.individual_logs.forEach(individual_log => {
        if(individual_log.date === date1) {
      
            if(individual_log.inOutStatus === 'Check-In') {
              if(this.$route.params.pID == 15) {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "9:00:00" || checkIn > "09:00:00") {
                        bs = "9:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var seconds = Math.floor((dif / 1000) % 60),
                        minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;
                        seconds = (seconds < 10) ? "0" + seconds : seconds;

                        r1_flexi = hours + ":" + minutes + ":" + seconds;
                      } 

                    }
              }

              if(this.$route.params.pID == 23) {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "7:00:00" || checkIn > "07:00:00") {
                        bs = "7:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var seconds = Math.floor((dif / 1000) % 60),
                        minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;
                        seconds = (seconds < 10) ? "0" + seconds : seconds;

                        r1_flexi_kuyaDon = hours + ":" + minutes + ":" + seconds;
                      } 

                    }
              }
                if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                  checkIn = individual_log.time;  
                  if(checkIn > "8:00:00" || checkIn > "08:00:00") {
                    bs = "8:00:00"
                    e = new Date(d + " " + checkIn);
                    s = new Date(d + " " + bs);
                    dif = Math.abs(e - s);

                    
                  var seconds = Math.floor((dif / 1000) % 60),
                    minutes = Math.floor((dif / (1000 * 60)) % 60),
                    hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                    hours = (hours < 10) ? "0" + hours : hours;
                    minutes = (minutes < 10) ? "0" + minutes : minutes;
                    seconds = (seconds < 10) ? "0" + seconds : seconds;

                    r1 = hours + ":" + minutes + ":" + seconds;
                  } 

                }
  
            }

          if(i !== 2) {

            if(individual_log.inOutStatus === 'Check-In') {
                if((individual_log.time > "12:00:00" && individual_log.time <= "13:00:00") || individual_log.time < "17:00:00") {
                checkIn1 = individual_log.time;
                if(checkIn1 > "13:00:00" || (checkIn1 > "13:00:00" && checkIn1 <= "17:00:00")) {
                  bs1 = "13:00:00"
                  e1 = new Date(d + " " + checkIn1);
                  s1 = new Date(d + " " + bs1);
                  dif1 = Math.abs(e1 - s1);

                  var seconds = Math.floor((dif1 / 1000) % 60),
                  minutes = Math.floor((dif1 / (1000 * 60)) % 60),
                  hours = Math.floor((dif1 / (1000 * 60 * 60)) % 24);

                  hours = (hours < 10) ? "0" + hours : hours;
                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                  r2 = hours + ":" + minutes + ":" + seconds;
                } 

              }
            }
            i++;
          }



      
          if(individual_log.inOutStatus === 'Check-Out') {
                  var temp_time = "00:00:00";
                  if(individual_log.time > '13:00:00' || (individual_log.time > '13:00:00' && individual_log.time <= '24:00:00')) {
                                checkOut1 = individual_log.time;
                                checkOut1 = checkOut1.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [checkOut1];

                      if(checkOut1.length > 1) { // If time format correct
                        checkOut1 = checkOut1.slice (1); // Remove full string match value

                              if(checkOut1[0] > 13 && checkOut1[0] <= 17) {
                                  temp_time = individual_log.time;

                                  bs2 = "18:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3_undertime = hours + ":" + minutes + ":" + seconds;
                              }
                              if(checkOut1[0] > 13 && checkOut1[0] <= 16) {
                                  temp_time = individual_log.time;

                                  bs2 = "17:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3 = hours + ":" + minutes + ":" + seconds;
                              }

                              if(checkOut1[0] > 13 && checkOut1[0] <= 15) {
                                  temp_time = individual_log.time;

                                  bs2 = "16:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3_undertime_kuyaDon = hours + ":" + minutes + ":" + seconds;
                              }
                          }
                   }
          }


          if(individual_log.inOutStatus === 'Check-Out') {
                var temp_time = "00:00:00";
                checkOut = individual_log.time;
                checkOut = checkOut.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [checkOut];
                if(checkOut.length > 1) { // If time format correct
                      checkOut = checkOut.slice (1); // Remove full string match value

                      if(checkOut[0] < 12) {
                          if(checkOut[0] > 8 && checkOut[0] <= 11) {
                              temp_time = individual_log.time;
                              bs3 = "12:00:00"
                              e3 = new Date(d + " " + temp_time);
                              s3 = new Date(d + " " + bs3);
                              dif3 = Math.abs(s3 - e3);

                              var seconds = Math.floor((dif3 / 1000) % 60),
                              minutes = Math.floor((dif3 / (1000 * 60)) % 60),
                              hours = Math.floor((dif3 / (1000 * 60 * 60)) % 24);

                              hours = (hours < 10) ? "0" + hours : hours;
                              minutes = (minutes < 10) ? "0" + minutes : minutes;
                              seconds = (seconds < 10) ? "0" + seconds : seconds;

                              r4 = hours + ":" + minutes + ":" + seconds;

                          }

                      }
                }
               
                                     
          }
    
          x_flexi = moment.duration(r1_flexi).add(moment.duration(r2)).add(moment.duration(r3_undertime)).add(moment.duration(r4))
              moment.utc(x_flexi.as('milliseconds')).format("HH:mm:ss")

          x_flexi_kuyaDon = moment.duration(r1_flexi_kuyaDon).add(moment.duration(r2)).add(moment.duration(r3_undertime_kuyaDon)).add(moment.duration(r4))
              moment.utc(x_flexi_kuyaDon.as('milliseconds')).format("HH:mm:ss")
           
          x = moment.duration(r1).add(moment.duration(r2)).add(moment.duration(r3)).add(moment.duration(r4))
              moment.utc(x.as('milliseconds')).format("HH:mm:ss")

          d28 = x;
          d28_flexi = x_flexi;  
          d28_flexi_kuyaDon = x_flexi_kuyaDon;     
        }
      });
      if(this.$route.params.pID == 15 || this.$route.params.pID == 37 || this.$route.params.pID == 13 || this.$route.params.pID == 1) {
         return r3_undertime;
      }
      if(this.$route.params.pID == 23) {
        return x_flexi_kuyaDon;
      }
      return x;
    },
    checkUnderTime29(date1) {
      var dt = new Date(date1), d = moment().format('L'), i = 0, j = -1, x = "", x_flexi = "", x_flexi_kuyaDon = "";
      let checkIn = "00:00:00", checkIn1 = "00:00:00", checkOut = "00:00:00", checkOut1 = "00:00:00", dif = "", dif1 = "", dif2 = "", dif3 = "", s = "", e = "", s1 = "", s2 = "", s3 = "", e1 = "", e2 = "", e3 = "", bs = "", bs1 = "", bs2 = "", bs3 = "", r1 = "00:00:00", r1_flexi = "00:00:00", r1_flexi_kuyaDon = "00:00:00", r2 = "00:00:00", r3 = "00:00:00", r4 = "00:00:00", r3_undertime = "00:00:00", r3_undertime_kuyaDon = "00:00:00";


      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }

      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 
      
      this.individual_logs.forEach(individual_log => {
        if(individual_log.date === date1) {
      
            if(individual_log.inOutStatus === 'Check-In') {
              if(this.$route.params.pID == 15) {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "9:00:00" || checkIn > "09:00:00") {
                        bs = "9:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var seconds = Math.floor((dif / 1000) % 60),
                        minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;
                        seconds = (seconds < 10) ? "0" + seconds : seconds;

                        r1_flexi = hours + ":" + minutes + ":" + seconds;
                      } 

                    }
              }

              if(this.$route.params.pID == 23) {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "7:00:00" || checkIn > "07:00:00") {
                        bs = "7:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var seconds = Math.floor((dif / 1000) % 60),
                        minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;
                        seconds = (seconds < 10) ? "0" + seconds : seconds;

                        r1_flexi_kuyaDon = hours + ":" + minutes + ":" + seconds;
                      } 

                    }
              }
                if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                  checkIn = individual_log.time;  
                  if(checkIn > "8:00:00" || checkIn > "08:00:00") {
                    bs = "8:00:00"
                    e = new Date(d + " " + checkIn);
                    s = new Date(d + " " + bs);
                    dif = Math.abs(e - s);

                    
                  var seconds = Math.floor((dif / 1000) % 60),
                    minutes = Math.floor((dif / (1000 * 60)) % 60),
                    hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                    hours = (hours < 10) ? "0" + hours : hours;
                    minutes = (minutes < 10) ? "0" + minutes : minutes;
                    seconds = (seconds < 10) ? "0" + seconds : seconds;

                    r1 = hours + ":" + minutes + ":" + seconds;
                  } 

                }
  
            }

          if(i !== 2) {

            if(individual_log.inOutStatus === 'Check-In') {
                if((individual_log.time > "12:00:00" && individual_log.time <= "13:00:00") || individual_log.time < "17:00:00") {
                checkIn1 = individual_log.time;
                if(checkIn1 > "13:00:00" || (checkIn1 > "13:00:00" && checkIn1 <= "17:00:00")) {
                  bs1 = "13:00:00"
                  e1 = new Date(d + " " + checkIn1);
                  s1 = new Date(d + " " + bs1);
                  dif1 = Math.abs(e1 - s1);

                  var seconds = Math.floor((dif1 / 1000) % 60),
                  minutes = Math.floor((dif1 / (1000 * 60)) % 60),
                  hours = Math.floor((dif1 / (1000 * 60 * 60)) % 24);

                  hours = (hours < 10) ? "0" + hours : hours;
                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                  r2 = hours + ":" + minutes + ":" + seconds;
                } 

              }
            }
            i++;
          }



      
          if(individual_log.inOutStatus === 'Check-Out') {
                  var temp_time = "00:00:00";
                  if(individual_log.time > '13:00:00' || (individual_log.time > '13:00:00' && individual_log.time <= '24:00:00')) {
                                checkOut1 = individual_log.time;
                                checkOut1 = checkOut1.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [checkOut1];

                      if(checkOut1.length > 1) { // If time format correct
                        checkOut1 = checkOut1.slice (1); // Remove full string match value

                              if(checkOut1[0] > 13 && checkOut1[0] <= 17) {
                                  temp_time = individual_log.time;

                                  bs2 = "18:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3_undertime = hours + ":" + minutes + ":" + seconds;
                              }
                              if(checkOut1[0] > 13 && checkOut1[0] <= 16) {
                                  temp_time = individual_log.time;

                                  bs2 = "17:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3 = hours + ":" + minutes + ":" + seconds;
                              }

                              if(checkOut1[0] > 13 && checkOut1[0] <= 15) {
                                  temp_time = individual_log.time;

                                  bs2 = "16:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3_undertime_kuyaDon = hours + ":" + minutes + ":" + seconds;
                              }
                          }
                   }
          }


          if(individual_log.inOutStatus === 'Check-Out') {
                var temp_time = "00:00:00";
                checkOut = individual_log.time;
                checkOut = checkOut.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [checkOut];
                if(checkOut.length > 1) { // If time format correct
                      checkOut = checkOut.slice (1); // Remove full string match value

                      if(checkOut[0] < 12) {
                          if(checkOut[0] > 8 && checkOut[0] <= 11) {
                              temp_time = individual_log.time;
                              bs3 = "12:00:00"
                              e3 = new Date(d + " " + temp_time);
                              s3 = new Date(d + " " + bs3);
                              dif3 = Math.abs(s3 - e3);

                              var seconds = Math.floor((dif3 / 1000) % 60),
                              minutes = Math.floor((dif3 / (1000 * 60)) % 60),
                              hours = Math.floor((dif3 / (1000 * 60 * 60)) % 24);

                              hours = (hours < 10) ? "0" + hours : hours;
                              minutes = (minutes < 10) ? "0" + minutes : minutes;
                              seconds = (seconds < 10) ? "0" + seconds : seconds;

                              r4 = hours + ":" + minutes + ":" + seconds;

                          }

                      }
                }
               
                                     
          }
    
          x_flexi = moment.duration(r1_flexi).add(moment.duration(r2)).add(moment.duration(r3_undertime)).add(moment.duration(r4))
              moment.utc(x_flexi.as('milliseconds')).format("HH:mm:ss")

          x_flexi_kuyaDon = moment.duration(r1_flexi_kuyaDon).add(moment.duration(r2)).add(moment.duration(r3_undertime_kuyaDon)).add(moment.duration(r4))
              moment.utc(x_flexi_kuyaDon.as('milliseconds')).format("HH:mm:ss")
           
          x = moment.duration(r1).add(moment.duration(r2)).add(moment.duration(r3)).add(moment.duration(r4))
              moment.utc(x.as('milliseconds')).format("HH:mm:ss")

          d29 = x;
          d29_flexi = x_flexi;  
          d29_flexi_kuyaDon = x_flexi_kuyaDon;     
        }
      });
      if(this.$route.params.pID == 15 || this.$route.params.pID == 37 || this.$route.params.pID == 13 || this.$route.params.pID == 1) {
         return r3_undertime;
      }
      if(this.$route.params.pID == 23) {
        return x_flexi_kuyaDon;
      }
      return x;
    },
    checkUnderTime30(date1) {
      var dt = new Date(date1), d = moment().format('L'), i = 0, j = -1, x = "", x_flexi = "", x_flexi_kuyaDon = "";
      let checkIn = "00:00:00", checkIn1 = "00:00:00", checkOut = "00:00:00", checkOut1 = "00:00:00", dif = "", dif1 = "", dif2 = "", dif3 = "", s = "", e = "", s1 = "", s2 = "", s3 = "", e1 = "", e2 = "", e3 = "", bs = "", bs1 = "", bs2 = "", bs3 = "", r1 = "00:00:00", r1_flexi = "00:00:00", r1_flexi_kuyaDon = "00:00:00", r2 = "00:00:00", r3 = "00:00:00", r4 = "00:00:00", r3_undertime = "00:00:00", r3_undertime_kuyaDon = "00:00:00";


      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }

      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 
      
      this.individual_logs.forEach(individual_log => {
        if(individual_log.date === date1) {
      
            if(individual_log.inOutStatus === 'Check-In') {
              if(this.$route.params.pID == 15) {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "9:00:00" || checkIn > "09:00:00") {
                        bs = "9:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var seconds = Math.floor((dif / 1000) % 60),
                        minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;
                        seconds = (seconds < 10) ? "0" + seconds : seconds;

                        r1_flexi = hours + ":" + minutes + ":" + seconds;
                      } 

                    }
              }

              if(this.$route.params.pID == 23) {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "7:00:00" || checkIn > "07:00:00") {
                        bs = "7:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var seconds = Math.floor((dif / 1000) % 60),
                        minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;
                        seconds = (seconds < 10) ? "0" + seconds : seconds;

                        r1_flexi_kuyaDon = hours + ":" + minutes + ":" + seconds;
                      } 

                    }
              }
                if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                  checkIn = individual_log.time;  
                  if(checkIn > "8:00:00" || checkIn > "08:00:00") {
                    bs = "8:00:00"
                    e = new Date(d + " " + checkIn);
                    s = new Date(d + " " + bs);
                    dif = Math.abs(e - s);

                    
                  var seconds = Math.floor((dif / 1000) % 60),
                    minutes = Math.floor((dif / (1000 * 60)) % 60),
                    hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                    hours = (hours < 10) ? "0" + hours : hours;
                    minutes = (minutes < 10) ? "0" + minutes : minutes;
                    seconds = (seconds < 10) ? "0" + seconds : seconds;

                    r1 = hours + ":" + minutes + ":" + seconds;
                  } 

                }
  
            }

          if(i !== 2) {

            if(individual_log.inOutStatus === 'Check-In') {
                if((individual_log.time > "12:00:00" && individual_log.time <= "13:00:00") || individual_log.time < "17:00:00") {
                checkIn1 = individual_log.time;
                if(checkIn1 > "13:00:00" || (checkIn1 > "13:00:00" && checkIn1 <= "17:00:00")) {
                  bs1 = "13:00:00"
                  e1 = new Date(d + " " + checkIn1);
                  s1 = new Date(d + " " + bs1);
                  dif1 = Math.abs(e1 - s1);

                  var seconds = Math.floor((dif1 / 1000) % 60),
                  minutes = Math.floor((dif1 / (1000 * 60)) % 60),
                  hours = Math.floor((dif1 / (1000 * 60 * 60)) % 24);

                  hours = (hours < 10) ? "0" + hours : hours;
                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                  r2 = hours + ":" + minutes + ":" + seconds;
                } 

              }
            }
            i++;
          }



      
          if(individual_log.inOutStatus === 'Check-Out') {
                  var temp_time = "00:00:00";
                  if(individual_log.time > '13:00:00' || (individual_log.time > '13:00:00' && individual_log.time <= '24:00:00')) {
                                checkOut1 = individual_log.time;
                                checkOut1 = checkOut1.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [checkOut1];

                      if(checkOut1.length > 1) { // If time format correct
                        checkOut1 = checkOut1.slice (1); // Remove full string match value

                              if(checkOut1[0] > 13 && checkOut1[0] <= 17) {
                                  temp_time = individual_log.time;

                                  bs2 = "18:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3_undertime = hours + ":" + minutes + ":" + seconds;
                              }
                              if(checkOut1[0] > 13 && checkOut1[0] <= 16) {
                                  temp_time = individual_log.time;

                                  bs2 = "17:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3 = hours + ":" + minutes + ":" + seconds;
                              }

                              if(checkOut1[0] > 13 && checkOut1[0] <= 15) {
                                  temp_time = individual_log.time;

                                  bs2 = "16:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3_undertime_kuyaDon = hours + ":" + minutes + ":" + seconds;
                              }
                          }
                   }
          }


          if(individual_log.inOutStatus === 'Check-Out') {
                var temp_time = "00:00:00";
                checkOut = individual_log.time;
                checkOut = checkOut.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [checkOut];
                if(checkOut.length > 1) { // If time format correct
                      checkOut = checkOut.slice (1); // Remove full string match value

                      if(checkOut[0] < 12) {
                          if(checkOut[0] > 8 && checkOut[0] <= 11) {
                              temp_time = individual_log.time;
                              bs3 = "12:00:00"
                              e3 = new Date(d + " " + temp_time);
                              s3 = new Date(d + " " + bs3);
                              dif3 = Math.abs(s3 - e3);

                              var seconds = Math.floor((dif3 / 1000) % 60),
                              minutes = Math.floor((dif3 / (1000 * 60)) % 60),
                              hours = Math.floor((dif3 / (1000 * 60 * 60)) % 24);

                              hours = (hours < 10) ? "0" + hours : hours;
                              minutes = (minutes < 10) ? "0" + minutes : minutes;
                              seconds = (seconds < 10) ? "0" + seconds : seconds;

                              r4 = hours + ":" + minutes + ":" + seconds;

                          }

                      }
                }
               
                                     
          }
    
          x_flexi = moment.duration(r1_flexi).add(moment.duration(r2)).add(moment.duration(r3_undertime)).add(moment.duration(r4))
              moment.utc(x_flexi.as('milliseconds')).format("HH:mm:ss")

          x_flexi_kuyaDon = moment.duration(r1_flexi_kuyaDon).add(moment.duration(r2)).add(moment.duration(r3_undertime_kuyaDon)).add(moment.duration(r4))
              moment.utc(x_flexi_kuyaDon.as('milliseconds')).format("HH:mm:ss")
           
          x = moment.duration(r1).add(moment.duration(r2)).add(moment.duration(r3)).add(moment.duration(r4))
              moment.utc(x.as('milliseconds')).format("HH:mm:ss")

          d30 = x;
          d30_flexi = x_flexi;  
          d30_flexi_kuyaDon = x_flexi_kuyaDon;     
        }
      });
      if(this.$route.params.pID == 15 || this.$route.params.pID == 37 || this.$route.params.pID == 13 || this.$route.params.pID == 1) {
         return r3_undertime;
      }
      if(this.$route.params.pID == 23) {
        return x_flexi_kuyaDon;
      }
      return x;
    },
    checkUnderTime31(date1) {
      var dt = new Date(date1), d = moment().format('L'), i = 0, j = -1, x = "", x_flexi = "", x_flexi_kuyaDon = "";
      let checkIn = "00:00:00", checkIn1 = "00:00:00", checkOut = "00:00:00", checkOut1 = "00:00:00", dif = "", dif1 = "", dif2 = "", dif3 = "", s = "", e = "", s1 = "", s2 = "", s3 = "", e1 = "", e2 = "", e3 = "", bs = "", bs1 = "", bs2 = "", bs3 = "", r1 = "00:00:00", r1_flexi = "00:00:00", r1_flexi_kuyaDon = "00:00:00", r2 = "00:00:00", r3 = "00:00:00", r4 = "00:00:00", r3_undertime = "00:00:00", r3_undertime_kuyaDon = "00:00:00";


      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }

      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 
      
      this.individual_logs.forEach(individual_log => {
        if(individual_log.date === date1) {
      
            if(individual_log.inOutStatus === 'Check-In') {
              if(this.$route.params.pID == 15) {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "9:00:00" || checkIn > "09:00:00") {
                        bs = "9:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var seconds = Math.floor((dif / 1000) % 60),
                        minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;
                        seconds = (seconds < 10) ? "0" + seconds : seconds;

                        r1_flexi = hours + ":" + minutes + ":" + seconds;
                      } 

                    }
              }

              if(this.$route.params.pID == 23) {
                    if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                      checkIn = individual_log.time;  
                      if(checkIn > "7:00:00" || checkIn > "07:00:00") {
                        bs = "7:00:00"
                        e = new Date(d + " " + checkIn);
                        s = new Date(d + " " + bs);
                        dif = Math.abs(e - s);

                        
                      var seconds = Math.floor((dif / 1000) % 60),
                        minutes = Math.floor((dif / (1000 * 60)) % 60),
                        hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                        hours = (hours < 10) ? "0" + hours : hours;
                        minutes = (minutes < 10) ? "0" + minutes : minutes;
                        seconds = (seconds < 10) ? "0" + seconds : seconds;

                        r1_flexi_kuyaDon = hours + ":" + minutes + ":" + seconds;
                      } 

                    }
              }
                if(individual_log.time > "5:00:00" || individual_log.time < "11:59:00") {
                  checkIn = individual_log.time;  
                  if(checkIn > "8:00:00" || checkIn > "08:00:00") {
                    bs = "8:00:00"
                    e = new Date(d + " " + checkIn);
                    s = new Date(d + " " + bs);
                    dif = Math.abs(e - s);

                    
                  var seconds = Math.floor((dif / 1000) % 60),
                    minutes = Math.floor((dif / (1000 * 60)) % 60),
                    hours = Math.floor((dif / (1000 * 60 * 60)) % 24);

                    hours = (hours < 10) ? "0" + hours : hours;
                    minutes = (minutes < 10) ? "0" + minutes : minutes;
                    seconds = (seconds < 10) ? "0" + seconds : seconds;

                    r1 = hours + ":" + minutes + ":" + seconds;
                  } 

                }
  
            }

          if(i !== 2) {

            if(individual_log.inOutStatus === 'Check-In') {
                if((individual_log.time > "12:00:00" && individual_log.time <= "13:00:00") || individual_log.time < "17:00:00") {
                checkIn1 = individual_log.time;
                if(checkIn1 > "13:00:00" || (checkIn1 > "13:00:00" && checkIn1 <= "17:00:00")) {
                  bs1 = "13:00:00"
                  e1 = new Date(d + " " + checkIn1);
                  s1 = new Date(d + " " + bs1);
                  dif1 = Math.abs(e1 - s1);

                  var seconds = Math.floor((dif1 / 1000) % 60),
                  minutes = Math.floor((dif1 / (1000 * 60)) % 60),
                  hours = Math.floor((dif1 / (1000 * 60 * 60)) % 24);

                  hours = (hours < 10) ? "0" + hours : hours;
                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                  r2 = hours + ":" + minutes + ":" + seconds;
                } 

              }
            }
            i++;
          }



      
          if(individual_log.inOutStatus === 'Check-Out') {
                  var temp_time = "00:00:00";
                  if(individual_log.time > '13:00:00' || (individual_log.time > '13:00:00' && individual_log.time <= '24:00:00')) {
                                checkOut1 = individual_log.time;
                                checkOut1 = checkOut1.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [checkOut1];

                      if(checkOut1.length > 1) { // If time format correct
                        checkOut1 = checkOut1.slice (1); // Remove full string match value

                              if(checkOut1[0] > 13 && checkOut1[0] <= 17) {
                                  temp_time = individual_log.time;

                                  bs2 = "18:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3_undertime = hours + ":" + minutes + ":" + seconds;
                              }
                              if(checkOut1[0] > 13 && checkOut1[0] <= 16) {
                                  temp_time = individual_log.time;

                                  bs2 = "17:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3 = hours + ":" + minutes + ":" + seconds;
                              }

                              if(checkOut1[0] > 13 && checkOut1[0] <= 15) {
                                  temp_time = individual_log.time;

                                  bs2 = "16:00:00"
                                  e2 = new Date(d + " " + temp_time);
                                  s2 = new Date(d + " " + bs2);
                                  dif2 = Math.abs(s2 - e2);

                                  var seconds = Math.floor((dif2 / 1000) % 60),
                                  minutes = Math.floor((dif2 / (1000 * 60)) % 60),
                                  hours = Math.floor((dif2 / (1000 * 60 * 60)) % 24);

                                  hours = (hours < 10) ? "0" + hours : hours;
                                  minutes = (minutes < 10) ? "0" + minutes : minutes;
                                  seconds = (seconds < 10) ? "0" + seconds : seconds;

                                  r3_undertime_kuyaDon = hours + ":" + minutes + ":" + seconds;
                              }
                          }
                   }
          }


          if(individual_log.inOutStatus === 'Check-Out') {
                var temp_time = "00:00:00";
                checkOut = individual_log.time;
                checkOut = checkOut.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [checkOut];
                if(checkOut.length > 1) { // If time format correct
                      checkOut = checkOut.slice (1); // Remove full string match value

                      if(checkOut[0] < 12) {
                          if(checkOut[0] > 8 && checkOut[0] <= 11) {
                              temp_time = individual_log.time;
                              bs3 = "12:00:00"
                              e3 = new Date(d + " " + temp_time);
                              s3 = new Date(d + " " + bs3);
                              dif3 = Math.abs(s3 - e3);

                              var seconds = Math.floor((dif3 / 1000) % 60),
                              minutes = Math.floor((dif3 / (1000 * 60)) % 60),
                              hours = Math.floor((dif3 / (1000 * 60 * 60)) % 24);

                              hours = (hours < 10) ? "0" + hours : hours;
                              minutes = (minutes < 10) ? "0" + minutes : minutes;
                              seconds = (seconds < 10) ? "0" + seconds : seconds;

                              r4 = hours + ":" + minutes + ":" + seconds;

                          }

                      }
                }
               
                                     
          }
    
          x_flexi = moment.duration(r1_flexi).add(moment.duration(r2)).add(moment.duration(r3_undertime)).add(moment.duration(r4))
              moment.utc(x_flexi.as('milliseconds')).format("HH:mm:ss")

          x_flexi_kuyaDon = moment.duration(r1_flexi_kuyaDon).add(moment.duration(r2)).add(moment.duration(r3_undertime_kuyaDon)).add(moment.duration(r4))
              moment.utc(x_flexi_kuyaDon.as('milliseconds')).format("HH:mm:ss")
           
          x = moment.duration(r1).add(moment.duration(r2)).add(moment.duration(r3)).add(moment.duration(r4))
              moment.utc(x.as('milliseconds')).format("HH:mm:ss")

          d31 = x;
          d31_flexi = x_flexi;  
          d31_flexi_kuyaDon = x_flexi_kuyaDon;     
        }
      });
      if(this.$route.params.pID == 15 || this.$route.params.pID == 37 || this.$route.params.pID == 13 || this.$route.params.pID == 1) {
         return r3_undertime;
      }
      if(this.$route.params.pID == 23) {
        return x_flexi_kuyaDon;
      }
      return x;
    },
    totalUndertime() {
             

      tt = moment.duration(d1).add(moment.duration(d2)).add(moment.duration(d3)).add(moment.duration(d4)).add(moment.duration(d5)).add(moment.duration(d6)).add(moment.duration(d7)).add(moment.duration(d8)).add(moment.duration(d9)).add(moment.duration(d10)).add(moment.duration(d11)).add(moment.duration(d12)).add(moment.duration(d13)).add(moment.duration(d14)).add(moment.duration(d15)).add(moment.duration(d16)).add(moment.duration(d17)).add(moment.duration(d18)).add(moment.duration(d19)).add(moment.duration(d20)).add(moment.duration(d21)).add(moment.duration(d22)).add(moment.duration(d23)).add(moment.duration(d24)).add(moment.duration(d25)).add(moment.duration(d26)).add(moment.duration(d27)).add(moment.duration(d28)).add(moment.duration(d29)).add(moment.duration(d30)).add(moment.duration(d31))
              moment.utc(tt.as('milliseconds')).format("HH:mm:ss") 
              
              
      tt_flexi = moment.duration(d1_flexi).add(moment.duration(d2_flexi)).add(moment.duration(d3_flexi)).add(moment.duration(d4_flexi)).add(moment.duration(d5_flexi)).add(moment.duration(d6_flexi)).add(moment.duration(d7_flexi)).add(moment.duration(d8_flexi)).add(moment.duration(d9_flexi)).add(moment.duration(d10_flexi)).add(moment.duration(d11_flexi)).add(moment.duration(d12_flexi)).add(moment.duration(d13_flexi)).add(moment.duration(d14_flexi)).add(moment.duration(d15_flexi)).add(moment.duration(d16_flexi)).add(moment.duration(d17_flexi)).add(moment.duration(d18_flexi)).add(moment.duration(d19_flexi)).add(moment.duration(d20_flexi)).add(moment.duration(d21_flexi)).add(moment.duration(d22_flexi)).add(moment.duration(d23_flexi)).add(moment.duration(d24_flexi)).add(moment.duration(d25_flexi)).add(moment.duration(d26_flexi)).add(moment.duration(d27_flexi)).add(moment.duration(d28_flexi)).add(moment.duration(d29_flexi)).add(moment.duration(d30_flexi)).add(moment.duration(d31_flexi))
              moment.utc(tt_flexi.as('milliseconds')).format("HH:mm:ss") 

      tt_flexi_kuyaDon = moment.duration(d1_flexi_kuyaDon).add(moment.duration(d2_flexi_kuyaDon)).add(moment.duration(d3_flexi_kuyaDon)).add(moment.duration(d4_flexi_kuyaDon)).add(moment.duration(d5_flexi_kuyaDon)).add(moment.duration(d6_flexi_kuyaDon)).add(moment.duration(d7_flexi_kuyaDon)).add(moment.duration(d8_flexi_kuyaDon)).add(moment.duration(d9_flexi_kuyaDon)).add(moment.duration(d10_flexi_kuyaDon)).add(moment.duration(d11_flexi_kuyaDon)).add(moment.duration(d12_flexi_kuyaDon)).add(moment.duration(d13_flexi_kuyaDon)).add(moment.duration(d14_flexi_kuyaDon)).add(moment.duration(d15_flexi_kuyaDon)).add(moment.duration(d16_flexi_kuyaDon)).add(moment.duration(d17_flexi_kuyaDon)).add(moment.duration(d18_flexi_kuyaDon)).add(moment.duration(d19_flexi_kuyaDon)).add(moment.duration(d20_flexi_kuyaDon)).add(moment.duration(d21_flexi_kuyaDon)).add(moment.duration(d22_flexi_kuyaDon)).add(moment.duration(d23_flexi_kuyaDon)).add(moment.duration(d24_flexi_kuyaDon)).add(moment.duration(d25_flexi_kuyaDon)).add(moment.duration(d26_flexi_kuyaDon)).add(moment.duration(d27_flexi_kuyaDon)).add(moment.duration(d28_flexi_kuyaDon)).add(moment.duration(d29_flexi_kuyaDon)).add(moment.duration(d30_flexi_kuyaDon)).add(moment.duration(d31_flexi_kuyaDon))
              moment.utc(tt_flexi_kuyaDon.as('milliseconds')).format("HH:mm:ss") 


      if(this.$route.params.pID == 15 || this.$route.params.pID == 37 || this.$route.params.pID == 13 || this.$route.params.pID == 1) {
   
          return tt_flexi;
      }

      if(this.$route.params.pID == 23) {
          return tt_flexi_kuyaDon;
      }

      return tt;
    },

    getCheckInArrival(date1) {

      var dt = new Date(date1);
      let t = "";
     
      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }


      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 

      this.individual_logs.forEach(individual_log => {
        if(individual_log.date === date1 && individual_log.inOutStatus === 'Check-In') {
          if(individual_log.time > "12:00:00" && individual_log.time < "17:00:00") {
            t = individual_log.time;
          }             
          
        }
      });
      return t;

    },

    getCheckOUT(date1) {

      var dt = new Date(date1);
      let t = "", temp_time = "";
     
      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }


      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 


      this.individual_logs.forEach(individual_log => {
        
        if(individual_log.date === date1 && individual_log.inOutStatus === 'Check-Out') {
              
                t = individual_log.time;
                t = t.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [t];
                if(t.length > 1) { // If time format correct
                      t = t.slice (1); // Remove full string match value
                      if(t[0] > 8 && t[0] <= 13) {
                        temp_time = individual_log.time;
                      }

                }
        }
      });
      return temp_time;
    },

    getCheckOUTHome(date1) {

      var dt = new Date(date1);
      let t = "";
     
      if(!this.from || !this.to) {
        return "";
      }

      if(date1 < this.from || date1 > this.to) {
        return "";
      }


      if(dt.getDay() == 6)
      {
      return "SATURDAY";
      } 

      if(dt.getDay() == 0)
      {
      return "SUNDAY";
      } 

      this.individual_logs.forEach(individual_log => {
        if(individual_log.date === date1 && individual_log.inOutStatus === 'Check-Out') {
          if(individual_log.time > '13:00:00' || (individual_log.time > '13:00:00' && individual_log.time <= '24:00:00')) {
            t = individual_log.time;
          }
        }
      });
      return t;
    },

    printForm() {
      // Pass the element id here
      this.$htmlToPaper('dtr');
    },

    tConvert (time) {
      // Check correct time format and split into components
      time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

      if (time.length > 1) { // If time format correct
        time = time.slice (1);  // Remove full string match value
        time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
      }
      return time.join (''); // return adjusted time or original string
    },
    searchLogs(from, to) {
          this.reset();
          this.reset_flex();
      axios
        .get(
          'api/dtr-individual-logs/' + this.getUser.user.personnel_id + '/' + from + '/' + to
        )
        .then((response) => {
          console.log("bio individual logs=>", response.data);
          this.individual_logs = response.data;
          // console.log("response", response);
 
        })
        .catch((error) => {
          alert(error);
        });

    },
     startClock:function(){
      var THIS = this;
      
      setInterval(function () {
        var a = new Date().getTime() / 1e3,
            b = new Date,
            c = b.getHours(),
            d = b.getMinutes(),
            e = b.getSeconds(),
            f = b.getMilliseconds(),
            g = f.toString().slice(0, -2),
            h = b.getDay(),
            i = 12 <= c ? 'PM' : 'AM',
            j = b.getMonth(),
            k = b.getDate(),
            l = b.getFullYear();
        THIS.$data.ampm = i;
          12 < c && (c %= 12), 
          0 == c && (c = 12), 
          9 >= e && (e = '0' + e), 
          9 >= d && (d = '0' + d), 
          THIS.$data.hours = c, 
          THIS.$data.minutes = `:${d}:`, 
          THIS.$data.sec = e, 
          THIS.$data.month = THIS.$data.monthss[j], 
          THIS.$data.date = k, 
          THIS.$data.year = l;
          THIS.$data.days[h].active = true;
    }, 100)
     },
     biometricCheckInOut() {

          this.loading = true;

          let formData = new FormData();
          formData.append('inOutStatus', this.inOutStatus);
          formData.append('personnelID', this.getUser.user.personnel_id);
          formData.append('firstName', this.getUser.user.first_name);
          formData.append('lastName', this.getUser.user.last_name);
          formData.append('date', moment().format('YYYY-MM-DD'));
          formData.append('time', moment().format('HH:mm:ss'));
        axios
        .post('api/biometric', formData)
        .then((response) => {
          toastr.success(response.data.message);    
          this.loading = false;
        })
        .catch(() => {
            this.loading = false;
            toastr.warning('Oops! Please connect in any network in CHED.');    
        });
     },
     convertToWords(digital) {
       let w =  digital.toString();
       let c = digital;
       if(w == "P0D") {
         c = "NO LATE";
       }
       return c;
     }
  },
  created: function() {
    this.startClock();
  }
}
</script>